import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_hom from './img/finding_a_house/im_hom.jpg';

export default class emigrant_life_finding_a_house extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【住居探し】</title>
            <meta name="description" content="
                カナダの日本人滞在者に多く見られるのはホームステイ、貸し部屋、
                リース物件等をエージェントやインターネットで 見つけてくるというスタイルです。
                それぞれの特徴は以下の通りです
            " />
          </Helmet>
            <h2>住居探し</h2>
            <div className="content-bg-blue">
                賃貸の場合
            </div>
            <div  class="float_right">
                <img src={im_hom} className="content-pic"/> 
            </div>
            <div className="content">
                カナダの日本人滞在者に多く見られるのはホームステイ、貸し部屋、<br/>
                リース物件等をエージェントやインターネットで 見つけてくるというスタイルです。<br/>
                それぞれの特徴は以下の通りです<br/>
            </div>
            <p>
                <h4 style={{color:"red"}}>ホームステイ</h4>
                一般家庭で専用ベッドルームと食事を提供してもらうスタイルです。生活用品は全て揃っているので、留学最初のステイに最も適しているでしょう。
                平均750ドル/月前後・３食付（バンクーバー）<br/>
                <br/>
                ホームステイのメリットは英語を学ぶ上では不可欠な、朝から夜まで英語漬けになれるという事です。ただしファミリーによっては英語を第一言語としないケースもあります。 ファミリーとの相性が良ければ帰国までずっとステイする留学生もいます。 デメリットといえば、少し自由が無いことです。例えば食事の時間、シャワーの時間（1回15分まで等）、ランドリーの回数などはその家のルールに必ず従いましょう。<br/>
                <br/>
                ホームステイでよくあるトラブルは、ホストファミリーとの人間関係および防犯上の問題などです。<br/>
                例） 契約通りの食事を与えてもらえない、ファミリーからのセクハラ、貴重品の盗難など<br/>
                <br/>
                人間関係や食事などは当事者同士の話し合いや斡旋した機関へ相談して対策を講じましょう。ただし、盗み、性的暴行や詐欺などは犯罪行為です。<br/>
                自分で解決しようとせずに警察や支援組織に通報などの処置を取りましょう。<br/>
            </p>
            <p>
                <h4 style={{color:"red"}}>貸し部屋</h4>
                一軒家やアパートのベッドルームを間借りするスタイルです。<br/>
                キッチンやバスルームなどはオーナー（貸主）と共同使用が一般的ですが、キッチンもエントランスも完全に分かれている物件もあります。<br/>
                <br/>
                家賃は400～600ドル/月前後が多く、電気・ガス・水道代などは通常家賃に含まれます。 貸し部屋のメリットは、ホームステイよりも自由度が高く、好きな料理を作ったり節約したい方に向いています。<br/>
                <br/>
                オーナーと住居の一部を共同使用している場合は、居住用借家法/Residential Tenancy Actの対象とならない為、トラブルが生じた際にテナント側が被るリスクがあります。例えば退去時に返ると聞いていたデポジットを返してもらえない、余分の家賃や不当な修繕費を請求されるといった問題はよく起こっています。約束事は必ず書面にして保管しておくようにしましょう。<br/>
            </p>
            <p>
                <h4 style={{color:"red"}}>アパート（ハウス）リース</h4>
                長期（1年～）リース契約により家やアパートを借りるスタイルです。 自分が借主となる為、一人暮らしをしたり自分の好きなルームメートを入れたり、最も自由度が高いと言えるでしょう。<br/>
                <br/>
                居住用借家法（Residential Tenancy Act）が適応される為、万が一問題が生じたとき、またオーナーから不当な請求を受けたときも法律に基づいて借家事業部（Residential Tenancy Branch）の介入により解決できるケースが多いです。<br/>
                <br/>
                なお、ホームステイや貸し部屋のように、オーナー（家主）とキッチンやバスルームなどを共有している場合は「居住用借家法（Residential Tenancy Act）」が適応されません。問題が生じた時には当人同士で（エージェントが介入している時はエージェントと）解決するか、長い期間と費用を覚悟でSmall Claim Courtに訴えるしかありません。<br/>
            </p>

            <div className="content-bg-border">
                入居プロセス            
            </div>
            <p>
                <h4 style={{color:"red"}}>ステップ 1   物件探し</h4>
                日本では賃貸紹介業者に訪問して部屋探しをするのが一般的ですが、ここカナダでは個人で貸主・借主を探す人達がほとんどです。そのためインターネットには毎日情報が出ていますので、まずは相場を調べる為にもいくつか賃貸情報のサイトを調べてみましょう。
            </p>
            <a href="http://www.jpcanada.com/">JP Canada</a><br/>
            <a href="http://www.v-shinpo.com/">バンクーバー新報</a><br/>
            <a href="http://www.oopsweb.com/">ウップスマガジン</a><br/>
            <a href="http://www.e-maple.net/classified.html?cat=WO">e-Maple　クラシファイド</a><br/>
            <p>
                気に入った物件が見つかったら、電話やEmailで問い合わせとアポイントを入れ、物件を見に行きましょう。見学には防犯のためにも一人で行くのは避けましょう。また、遅刻や連絡無しのキャンセルは相手に大変迷惑がかかるマナー違反ですから、気をつけましょう。 
            </p>
            <p>
                <h4 style={{color:"red"}}>ステップ2   賃貸契約</h4>
                入居する物件が決まったら、契約手続きです。家主とテナントは賃貸契約（Tenancy Agreement）と同時に現状点検レポートを作成します。この点検レポートとは、入居時の部屋のコンディション（既存の損傷やよごれ、水道電気等の機能）を両者が確認し、合意の上で記入するものです。これは退去時に破損やよごれについて争いになった際に重要な証拠となります。<br/>
                <br/>
                カナダには敷金、礼金という制度はありませんが、保証金（Security Deposit）制度があります。<br/>
                家主は最高で家賃の半月分まで保証金の支払いをテナントに求める事が出来ます。家主はテナントが住居に及ぼす破損や未払いの家賃が発生したときにそこから補填します。<br/>
                <br/>

                契約書も現状点検レポートも、必ず細部まで自分の目で確認をし、同意をしてからサインをしましょう。また、家賃や保証金など支払った代金には必ず領収書をもらい、保管しておきましょう。<br/>
            </p>
            <p>
                <h4 style={{color:"red"}}>ステップ3   入居後</h4>
                一軒家のベースメントスイート等はすでに電気・ケーブル等契約済みの物件が多いですが、たとえば新たなリース契約の場合は入居前に電気・ケーブルの契約を済ましておきましょう。<br/>
                <br/>
                <a href="http://www.bchydro.com/">BC Hydro（水道、電気、ガス）</a> 1-888-769-3766（電気） / 1-800-663-9911（ガス）<br/>
            <a href="http://www.telus.com/cgi-ebs/jsp/selectRegion.jsp?rd=http://www.telus.com/cgi-ebs/jsp/homepage.jsp%3f">電話、インターネット（Telus） </a>310-2255<br/>
            <a href="http://www.shaw.ca/">電話、インターネット（Shawケーブル）</a> 1-888-472-2222<br/>
            <a href="https://www.canadapost.ca/cpc/en/personal/receiving/manage-mail/mail-forwarding.page">カナダ郵便局（住所変更の届け） </a>1-866-607-6301<br/>
            その他、クレジット、銀行、健康保険（MSP）等。<br/>
            <br/>
            <b>緊急ダイヤル及びヘルプライン</b><br/>
            <table>
                <tr>
                    <td>
                        緊急用警察、消防、救急
                    </td>
                    <td>
                        911
                    </td>
                </tr>
                <tr>
                    <td>
                        上記非緊急時	
                    </td>
                    <td>
                        604-872-5151
                    </td>
                </tr>
                <tr>
                    <td>
                        日本総領事館	
                    </td>
                    <td>
                        604-684-5868
                    </td>
                </tr>
                <tr>
                    <td>
                        自動車保険ICBC
                    </td>
                    <td>
                        604-520-8222
                    </td>
                </tr>
                <tr>
                    <td>
                        DV、セクハラ、レイプ、暴力
                    </td>
                    <td>
                        604-436-1025 （日本語可）
                    </td>
                </tr>
                <tr>
                    <td>
                        ２４時間看護師ライン
                    </td>
                    <td>
                        604-215-4700 / 1-866-215-4700
                    </td>
                </tr>

            </table>
            <br/>
            <b>家賃の値上げ</b><br/>
            家主は１年に１回のみ家賃の値上げを実施する事が出来ます。実施の3ヶ月前までに書面にてテナントに通知し、値上げ率は借家法で認められた額を超えてはいけません。<br/>
            <br/>
            <b>賃貸解約</b><br/>
            明け渡しの１ヶ月前までに家主に書面で通知を手渡さなければなりません。（口頭では法的効力がありません）なお、リース契約の場合はリース途中で解約する事は通常認められませんので、支払いだけは続けなければならない可能性もあります。<br/>
            <br/>
            また、電話やケーブル会社へ解約の日付を連絡しましょう。<br/>
            明け渡し時には、家主と共に入居時の現状点検リストを元に行ないます。賃貸契約の終了後、家主は保証金の返却をしなければなりません。ただし、部屋に著しい破損やよごれがある場合は家主は保証金から修繕費を差し引く場合もあります。これにはテナントの同意書が必要です。<br/>
            </p>
            <div className="content-bg-border">
                住宅関係のトラブルと対策
            </div>
            <p>
                ホームステイでもシェアでも、住まいに関するトラブルは誰もが大なり小なり経験するでしょう。悪質な犯罪やトラブルに巻き込まれないよう、予備知識と注意が必要です。<br/>
                <br/>
                <b>居住用借家法（Residential Tenancy Act）とは？</b><br/>
                BC州の居住用借家法とは、家主（Landlord）と借主（Tenant）それぞれの責任と権利の尊重の為の法律です。悪質なトラブルや不可解な要求を受けたら、まずこの法律をよく調べましょう。 ほとんどのトラブルは自分の意識の持ち方、毅然とした態度と正しい知識があれば防げるものです。自分の身を守れるのは自分自身です。借家法をしっかり理解して、快適な生活を送れるようにしましょう。<br/>
                <br/>
                <a href="https://tenants.bc.ca/">http://www.rto.gov.bc.ca/</a> 604-255-0546 | 1-800-665-1185<br/>
            </p>

            <div className="content-bg-border">
                住宅購入の場合
            </div>
            <div className="content">
                カナダでは外国人に対しても不動産の購入に規制は無く、自由に売買ができます。また、カナダにおける不動産購入では、購入側の仲介手数料は通常無料です。物件情報は一般公開されていますから、各仲介業者により保有物件に偏りがあるということもありません。<br/>
                不動産購入は大きな投資になりますから、ご自分に合うエージェントを選びましょう。<br/>
            </div>
            <p>
                <h4 style={{color:"red"}}>ステップ1  物件選定</h4>
                自身のもつ予算、ライフスタイルなどの要素を事前にリストアップしましょう。
                <table>
                    <tr>
                        <td>
                            <b>エリア</b>
                        </td>
                        <td>
                            通勤、通学、育児、治安、交通、利便性、医療機関
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <b>スタイル</b>
                        </td>
                        <td>
                            一軒家、タウンハウス、マンション
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <b>予算</b>
                        </td>
                        <td>
                            自己資金％　モーゲージ（住宅抵当権ローン）%　
                        </td>
                    </tr>
                </table>
            </p>
            <p>
                <h4 style={{color:"red"}}>ステップ2   購入（オファー）</h4>
                    不動産仲介手数料は売り主側が負担し、買い手側にはかかりません。 不動産エージェントは地域別、物件タイプ別の適正価格や様々な情報のアドバイスができます。その後の購入（オファー）、値段の交渉、事務手続きもサポートできます。<br/>
                    <br/>

                    日本語で対応できるバンクーバーの不動産エージェント　<br/>
                    ポイントグレイ・プランニング社／<a href="http://www.jmurakami.com/">村上丈二さん</a>　<br/>
                    電話：604-240-3541<br/>
            </p>
            <p>
                <h4 style={{color:"red"}}>ステップ3   売買契約</h4>
                <table>
                    <tr>
                        <td>
                            <b>デポジット</b>
                        </td>
                        <td>
                            通常5千ドル～１万ドルを保証金として不動産仲介業者にいれる
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <b>決済日</b>
                        </td>
                        <td>
                            Completion Dateといい、お互いの弁護士・公証人により売買が成立
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <b>引渡し</b>
                        </td>
                        <td>
                            Possession Dateといい、通常決済の翌日となり、鍵が渡される
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <b>調整日</b>
                        </td>
                        <td>
                            Adjustment Dateといい、引渡しと同じ日なります。諸費用の基準日
                        </td>
                    </tr>
                </table>
                <br/>
                <b>書類の手続き</b><br/>
                売買契約後、それぞれ弁護士(Lawyer)または公証人(Notary Public)等に依頼し、書類の手続きを行います。依頼費用は通常８００～１０００ドル程度で、弁護士は通常公証人よりもやや高い相場です。<br/>
                <br/>
                    <b>不動産購入の主な費用</b>
                    <br/>
                    ●　Total     購入金額<br/>
                    ●　Deposit　    契約際のデポジット<br/>
                    ●　GST     物品サービス税<br/>
                    ●　PTT     不動産譲渡税<br/>
                    ●　Property tax    固定資産税<br/>
                    ●　Strata Fee    共同管理費（タウンハウス、コンドの場合）<br/>
                    ●　Notary    書類作成費<br/>
                    <br/>
                    *GST Goods and Services Taxの略<br/>
                    *PTT Property Transfer taxの略<br/>
            </p>
            <p>
                <h4 style={{color:"red"}}>ステップ4   入居手続</h4>
                購入代金を弁護士あるは公証人の口座に入金すると、決済日の翌日に鍵が渡されて入居することができます。<br/>
                そこで売り手から家のマニュアル、保険関連、管理会社、会議記録の書類をチェックしします。<br/>
                <br/>
                <a href="http://www.bchydro.com/">BC Hydro（水道、電気、ガス）</a> 1-888-769-3766（電気） / 1-800-663-9911（ガス）<br/>
                <a href="http://www.telus.com/cgi-ebs/jsp/selectRegion.jsp?rd=http://www.telus.com/cgi-ebs/jsp/homepage.jsp%3f">電話、インターネット（Telus） </a>310-2255<br/>
                <a href="http://www.shaw.ca/">電話、インターネット（Shawケーブル）</a> 1-888-472-2222<br/>
                <a href="https://www.canadapost.ca/cpc/en/personal/receiving/manage-mail/mail-forwarding.page">カナダ郵便局（住所変更の届け） </a>1-866-607-6301<br/>
            </p>
        </>
      );
    }
  }