import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import about_us0 from './img/about_us0.png';
import about_us1 from './img/about_us1.png';
import about_us2 from './img/about_us2.png';

export default class about_us extends Component {

  render() {
    return (
      <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>亚历山大移民事务所ABIC Immigration【关于我们】</title>
            <meta name="description" content=""
                
             />
          </Helmet>
          <span class="cn-part">
            <h2>
                关于我们
            </h2>
            <p>
                自2000年11月我们专业致力于加拿大各类移民，签证，商业和投资的咨询服务，2003年10月在加拿大BC省注册为法人公司。在加拿大移民咨询行业，我们有着将近20余年的宝贵经验和数以万计的成功案例。我们的目标是帮助愿意来加拿大生活和发展的朋友，在这个风景优美、资源丰富、多民族融和的国家建立一个崭新的家园！
            </p>
            <p>
                我事务所专属的持牌移民顾问和精通不同领域业务的合作律师，对加拿大移民法和商业法有着深刻的理解，将会协同我公司所有成员为您提供尽职尽责、诚实中肯的服务。如果您有来加拿大的打算，无论是长期居住或是短期访问，ABIC都将是最您可靠的咨询顾问。我们一向恪守“精益求精”的宗旨，定会选择一种最适合您的方式来帮助您实现自己的目标！
            </p>
            <p>
                移民申请不是一件简单的事务性作业，而是需要专业性和责任性很高的严谨态度，而这在很多方面需要从业人员耗费客户不一定看得见的费用和时间。我们的服务可以用“专业，诚信，尽责，保密”来概括，并做以下保证：
            </p>
            <p>
                1. 保证所提供加拿大移民信息的准确性和合法性；<br/>
                2. 保证对客户文件和信息高度保密和负责；<br/>
                3. 保证亲自服务到申请结束，避免申请途中产生的衔接不畅等问题。<br/>
            </p>
            <p>
                我们不愿轻易承诺“百分之百”的成功，但是如果有幸成为您的代理，我们将会给您最真实、最可靠的建议。
            </p>
            <p>
                您的成功，我们的成功！
            </p>
            <div className="content-bg-blue">
                事务所简介
            </div>
            <p>
                <table>
                        <tr className="colored">
                            <td width={"120px"}>
                                <b>
                                    公司名(英文)<br/>
                                    公司名(中文)
                                </b>
                            </td>
                            <td  width="auto">
                                Alexander Business Investment Consultants Inc.(ABIC)<br/>
                                亚历山大移民&商业投资事务所
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>
                                    公司地址
                                </b>	
                            </td>
                            <td>
                            United Kingdom Building Suite 1617, 409 Granville Street <br/>
Vancouver, BC CANADA V6C 1T2 CANADA
                            </td>
                        </tr>
                        <tr className="colored">
                            <td>
                                <b>
                                    创办<br/>
                                    法人注册
                                </b>
                            </td>
                            <td>
                                2000年11月<br/>
                                2003年10月
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>公司法人</b>
                            </td>
                            <td>
                                Terry Jin
                            </td>
                        </tr>
                        <tr className="colored">
                            <td>
                                <b>业务内容</b>
                            </td>
                            <td>
                                加拿大各类签证，移民项目<br/>
                                加拿大创业咨询和投资项目<br/>
                                护照项目<br/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>联系电话</b>
                            </td>
                            <td>
                                604-688-1956
                            </td>
                        </tr>
                        <tr className="colored">
                            <td>
                                <b>电子邮件</b>
                            </td>
                            <td>
                                <a href="mailto:abic@abicanada.com">abic@abicanada.com</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>移民协会登记</b><br/>
                                (加拿大政府认证)
                            </td>
                            <td>
                                MEI YU RCIC#R421895<br/>
                                CICC website www.college-ic.ca/
                            </td>
                        </tr>
                        <tr className="colored">
                            <td>
                                <b>
                                    企业理念
                                </b>
                            </td>
                            <td>
                                认真考虑并通过移民加拿后获得美好生活的申请人提供综合的移民，签证，定居，投资服务。
                            </td>
                        </tr>
                    </table>
                </p>
                <div class="about_us_pics">
                <img src={about_us0} className="content-pic"/> 
                <img src={about_us1} className="content-pic"/> 
                <img src={about_us2} className="content-pic"/> 
            </div>
        </span>
    </>
    );
  }
}