import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import img_cus_rev01 from './img/info/img_cus_rev01.jpg';
import img_cus_rev02 from './img/info/img_cus_rev02.jpg';
import img_cus_rev03 from './img/info/img_cus_rev03.jpg';
import img_cus_rev04 from './img/info/img_cus_rev04.jpg';
import img_cus_rev05 from './img/info/img_cus_rev05.jpg';
import img_cus_rev06 from './img/info/img_cus_rev06.jpg';

import img_cus_rev11 from './img/info/img_cus_rev11.jpg';
import img_cus_rev12 from './img/info/img_cus_rev12.jpg';


export default class customer_review_info extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【お客様の声】</title>
            <meta name="description" content="
            " />
          </Helmet>
            <h2>お客様の声</h2>

            <div className="content-bg-blue">Case 1 : 中川　武 様</div>
            <div class="float_center">
                <img src={img_cus_rev01} className="content-pic"/>
                <p>Grouss mountainでスノーボードインストラクターの経験も</p>
            </div> 
            <div className="content-bg-border">
                お名前
            </div>
            <p>
                中川　武        
            </p>
            <div className="content-bg-border">
                現在の職業
            </div>
            <p>
                IT企業       
            </p>
            <div className="content-bg-border">
                カナダ移住
            </div>
            <p>
                2011年        
            </p>
            <div className="content-bg-border">
                カナダ移住生活は如何でしょうか    
            </div>
            <p>
                カナダ移住生活は、世界のいろいろな人と英語を通して触れ合えることができることが素晴らしく、カナダならではと感じます。また、カナダで永住権をABICのおかげで取得することができたことや、カナダのスノーボードライセンスも取得できたことなど、充実感のある日々を過ごしています。
            </p>
            <div className="content-bg-border">
                ABICのサービスについてのご感想
            </div>
            <p>
                最初の無料相談から、迅速かつ丁寧に対応して頂き、とても好印象でした。いくつかの会社に無料相談をお願いしたのですが、丁寧さでABICが断トツで好印象でした。<br/>
                <br/>
                また、お願いした後も、不安があっても、質問するたびに、その不安を取り除いてくれて、安心してお任せすることができました。また、カナダでの生活がはじまった後も、最初の生活を始める時の不安な事柄など、いろいろお心遣い頂き、アフターサポートも素晴らしく、とても信頼することができました。<br/>
                <br/>
                Terryさんを始め、ABICの皆さんがいなければ、永住権を取得することはできなかったと思います。<br/>
                <br/>
                改めて、本当に感謝しております。
            </p>

            <div className="content-bg-blue">Case 2 : 田中　旭 様</div>
            <div class="float_center">
                <img src={img_cus_rev02} className="content-pic"/> 
            </div>
            <div className="content-bg-border">
                お名前
            </div>
            <p>
                田中 旭        
            </p>
            <div className="content-bg-border">
                現在の職業
            </div>
            <p>
                Assistant Manager, <br/>
                Real Estate Administration – Legal,<br/>
                Freelance Recipe Writer    
            </p>
            <div className="content-bg-border">
                カナダ移住
            </div>
            <p>
                2017年     
            </p>
            <div className="content-bg-border">
                カナダ移住生活は如何でしょうか    
            </div>
            <p>
                移民をするまでに、学生ビザ、ワークビザ、ワーキングホリデービザなどを含め、１０年近くカナダで過ごしていた私ですが、移民をとることにより、ストレスフリーで自由な生活をすることができるようになりました。移民後、転職をして新たな仕事に挑戦することができました。現在は、仕事にプラスになる法律に関わるコースを大学で学びながら、働くことができています。趣味だった料理の幅を広げ、副業でレシピを書くお仕事もはじめました。
            </p>
            <div className="content-bg-border">
                ABICのサービスについてのご感想
            </div>
            <p>
                小さな頃からバンクーバーにて学生生活を送っていた私は、大学卒業と同時に計画的に移民申請の準備をしていました。はじめの頃は、就職、会社の移民サポートなど、移民申請への手続きが淡々とスムーズに進みました。そんな中、カナダの移民制度の大幅な変更やポイント制度導入など、突然移民への道が困難になり苦労していたところ、テリーさんに出会いました。とても親身になっていただき、効率よく移民を取ることができました。とても感謝しています。
            </p>

            <div className="content-bg-blue">Case 3 : 齊藤　陽子 様</div>
            <div class="float_center">
                <img src={img_cus_rev03} className="content-pic"/> 
            </div>
            <div className="content-bg-border">
                お名前
            </div>
            <p>
                齊藤　陽子        
            </p>
            <div className="content-bg-border">
                現在の職業
            </div>
            <p>
                主婦   
            </p>
            <div className="content-bg-border">
                カナダ移住
            </div>
            <p>
                2008年        
            </p>
            <div className="content-bg-border">
                カナダ移住生活は如何でしょうか    
            </div>
            <p>
                子供の英語教育の為にカナダに移住したのですが、<br/>
                学費も教育も日本とかなり違って、<br/>
                自然の中で、伸び伸びと英語を学ぶ事ができ安全なので、<br/>
                子育てには最高です。
            </p>
            <div className="content-bg-border">
                ABICのサービスについてのご感想
            </div>
            <p>
                豊富な知識から電話対応も良く細かい所まで、<br/>
                とても丁寧で金額相応な完璧な結果を<br/>
                出してくださるので、大満足しています。
            </p>

            <div className="content-bg-blue">Case 4 : O.T. 様</div>
            <div class="float_center">
                <img src={img_cus_rev04} className="content-pic"/> 
            </div>
            <div className="content-bg-border">
                お名前
            </div>
            <p>
                O.T.        
            </p>
            <div className="content-bg-border">
                現在の職業
            </div>
            <p>
                リタイヤ   
            </p>
            <div className="content-bg-border">
                カナダ移住
            </div>
            <p>
                2010年        
            </p>
            <div className="content-bg-border">
                カナダ移住生活は如何でしょうか    
            </div>
            <p>
                毎日が楽しく飽きることがない、あっという間の10年目です。<br/>
                1年目より2年目と過ごす月日が増すごとに、私達はバンクーバーに、魅せられてます。<br/>
                車での遠出は雄大なカナダらしい景色を堪能、散歩では歴史を感じる建物を見つけ喜び、<br/>
                カメラを持ったら被写体で事欠くこと無いですね。<br/>
                誰が撮ってもプロ並みの出来映え（笑）。<br/>
                自然が身近にあり快適な生活環境、何とも言えない自由感、ほんとにいいですね。<br/>
                これからも、ゆったりとした時間と共に生活していけるのは幸せな事だと思います。<br/>
                ひとつ、気にかかるのは、物価高でしょうか。<br/>
                ここバンクーバーで生活出来てることが最高の贅沢です。そして感謝です。<br/>
            </p>
            <div className="content-bg-border">
                ABICのサービスについてのご感想
            </div>
            <p>
                カナダ移住のソフトランディング、ポイントを捉えたサービスでスムーズに日常生活が
                開始できました。<br/>
                日常生活での「困ったときの神頼み」ABIC　テリーさん、スタッフの皆さんに、<br/>
                きっちり誘導いただき助かっております。<br/>
                適度な距離感でのアドバイス、大変心地よいです。　<br/>
            </p>

            <div className="content-bg-blue">Case 5 : 匿名希望 様</div>
            <div class="float_center">
                <img src={img_cus_rev05} className="content-pic"/> 
            </div>
            <div className="content-bg-border">
                お名前
            </div>
            <p>
                匿名希望        
            </p>
            <div className="content-bg-border">
                現在の職業
            </div>
            <p>
                ガーデナー   
            </p>
            <div className="content-bg-border">
                カナダ移住
            </div>
            <p>
                2013年        
            </p>
            <div className="content-bg-border">
                カナダ移住生活は如何でしょうか    
            </div>
            <p>
                言葉の壁はありますが、福利厚生がしっかりしていて、<br/>
                日本の飲食店や日用雑貨も多くとても暮らしやすいです。
            </p>
            <div className="content-bg-border">
                ABICのサービスについてのご感想
            </div>
            <p>
                 自分はABIC以前に他の弁護士の方にお願いして移民の申請をしたのですが、<br/>
                ほぼ進展の無い状態で２年ほど待たされました。<br/>
                このままではダメだと思いABICさんにお願いしたところ１年もかからないうちに永住権を取得することができました。<br/>
                それから結婚し、妻の移民にもこちらの書類の不備が多々あったにもかかわらず<br/>
                根気よく最後まで丁寧にやっていただき、無事、妻の永住権も取得することができました。<br/>
                大変感謝しています。
            </p>

            <div className="content-bg-blue">Case 6 : Y.S. 様</div>
            <div class="float_center">
                <img src={img_cus_rev06} className="content-pic"/> 
                <p>ベテランのデザイナーとして、多くのハリウッド映画の製作に携わっている</p>
            </div>
            <div className="content-bg-border">
                お名前
            </div>
            <p>
                Y.S.        
            </p>
            <div className="content-bg-border">
                現在の職業
            </div>
            <p>
                Computer Graphics   
            </p>
            <div className="content-bg-border">
                カナダ移住
            </div>
            <p>
                2007年        
            </p>
            <div className="content-bg-border">
                カナダ移住生活は如何でしょうか    
            </div>
            <p>
                学生で来て、現地で結婚し、今では子供2人の生活なので来て良かったなと思います。<br/>
                長年住むと好きな事だけでなく、嫌いな事も出てきますが、それを含めて「地元」と感じるようになりました。日本へ帰国する際も、バンクーバーに戻るとほっとするというか、家だなぁと思いますね。
            </p>
            <div className="content-bg-border">
                ABICのサービスについてのご感想
            </div>
            <p>
                まだまだ国のシステムやビザの事もよくわからない頃に、色々とご丁寧に対応して頂いてとても満足です。その後も質問などにも気軽に答えて頂いて、本当に感謝しております！
            </p>
            <br/>
            <hr/>
            <h2>市民権（パスポート）取得</h2>
            <div className="content">
                他の国の市民権（パスポート）を持つことで、資産の配置、税務の軽減など富裕層にとって、リスクをヘッジする手段として欠かせない手段と言える。<br/>
                例えばグレナダはイギリス連邦加盟国であり、中国を含め141か国ヘビザ無し渡航が可能である。その他も、いくつかの国の市民権・パスポートプログラムがありますが、常時にアップデートされているため、その都度お問い合わせください。
            </div>
            <div className="content-bg-border">
                申請条件：
            </div>
            <p>
            ・　主申請者は18歳以上、健康、無犯罪、２０万USドル投資。<br/>
            ・　配偶者、お子様、両親、および18歳以上未婚の姉妹兄弟のご家族も一緒に申請することは可能
            </p>
            <div>
                <div class="float_center">
                    <img src={img_cus_rev11} className="content-pic"/> 
                    <img src={img_cus_rev12} className="content-pic"/> 
                </div>
            </div>
            <div className="content-bg-border">
                市民権（パスポート）プログラム
            </div>
            <p>
                Antigua & Barbuda<br/>
                Cyprus<br/>
                Dominica<br/>
                Grenada<br/>
                Greece<br/>
                Ireland<br/>
                Malta <br/>
                Montenegro<br/>
                Moldova<br/>
                Portugal<br/>
                St. kitts& Nevis<br/>
                Saint Lucia<br/>
                Turkey<br/>
                Vanuatu<br/>
            </p>
        </>
      );
    }
  }