import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_stu from './img/study_permit/im_stu.jpg';


export default class immigration_and_visa_canada_visa_trouble extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【ビザトラブル】</title>
            <meta name="description" content="
                勿論のことですが、ビザのトラブルが発生する前に、 移民&ビザの専門家に相談することを強く勧めします。 それでも以下のような問題が発生した場合、弊社にいち早くご連絡下さい。
            " />
          </Helmet>
            <h2>ビザトラブル</h2>
            <div class="float_right">
                <img src={im_stu} className="content-pic"/> 
            </div>
            <div className="content">
                <p>
                    勿論のことですが、ビザのトラブルが発生する前に、
                    移民&ビザの専門家に相談することを強く勧めします。
                    それでも以下のような問題が発生した場合、弊社にいち早くご連絡下さい。
                </p>
            </div>
            <p>
                ● カナダに入国の際、入国拒否をされた;<br/>
                ● うっかりカナダビザの延長を忘れて、許可された滞在期限を越えてしまった;<br/>
                ●カナダ ビジタービザ・カナダ学生ビザ・カナダワークビザの延長申請が却下された;<br/>
                ● 移民局から国外退去の通知を受けた;<br/>
                ● LMIAの申請が却下された;<br/>
                ● 東京でワークビザを申請したが、却下された;<br/>
                ● スギルドワーカー、結婚移民申請中に、移民局から追加資料を求められた;<br/>
                ● カナダPNP移民を申請したが却下された;<br/>
                ● カナダ移民局に書類を送付したが、送り先が違うと書類が送り返された;<br/>
                <br/>
                また、ビジター延長の申請できる回数は制限されていませんが、あまり長くビジター延長で継続的に滞在していると、カナダで不法就労している疑い、また自国との結びつきがないという理由から却下される事もあります。ビジター延長申請の際は滞在理由を具体的に説明し、その理由を証明する十分な補助書類をご用意下さい。
            </p>
        </>
      );
    }
  }