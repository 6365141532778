import React, { Component } from 'react';
import './App.css';

import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';


export default class contact extends Component {

    constructor(props){
        super(props);
        this.state = {
            name:"",
            email:"",
            age:"",
            gender:"",
            phone_number:"",
            address:"",
            educational_record:"",
            work_record:"",
            visit_record_inCanada:"",
            holding_visa_info:"",
            visa_you_want:"",
            how_to_contact:"",
            content:"",
            isSubmitted: false,
            isConfirmed: false,
            isError: false,
            hasNameError: false,
            hasEmailError: false,
            hasContentError: false,
        };
    }

    
  handleConfirm(event) {
    event.preventDefault();

    window.location.hash = "#pagetop"

    var stateCheck = true;

    if(this.state.name.length == 0
      || this.state.email.length == 0
      || this.state.content.length == 0)
    {
      stateCheck = false;

      if(this.state.name.length == 0)
      {
        this.setState({hasNameError: true});
      }
      if(this.state.email.length == 0)
      {
        this.setState({hasEmailError: true});
      }
      if(this.state.content.length == 0)
      {
        this.setState({hasContentError: true});
      }
    }


    if(stateCheck)
    {
      this.setState({isConfirmed: true});
    }else
    {
      this.setState({isError: true});
    }
  }

  handleReturn()
  {
    this.setState({isConfirmed: false});
  }

  replaceReturnForTextArea(v)
  {
    var text = v.replace(/\n/g,"%0D%0A");
    text = text.replace(/\r/g,"%0D%0A");
    return text;
  }
    
  handleSubmit() {

    var lineFeed = "\r\n";
    var lineFeedForText = "%0D%0A";

    //console.log(this.state.content);
    var mailto = [
      'お名前 ： '+this.state.name,
      ,
      'メールアドレス ： '+this.state.email,
      ,
      '年齢 ： '+this.state.age,
      ,
      '性別 ： '+this.state.gender,
      ,
      'ＴＥＬ ： '+this.state.phone_number,
      ,
      '住所 ： '+this.state.address,
      ,
      '最終学歴及び在学期間 ： ',
      this.replaceReturnForTextArea(this.state.educational_record),
      ,
      '職歴（会社、職務及び期間） ： ',
      this.replaceReturnForTextArea(this.state.work_record),
      ,
      'カナダの滞在歴（滞在期間とビザタイプ) ： ',
      this.replaceReturnForTextArea(this.state.visit_record_inCanada),
      ,
      '現在所持しているビザのタイプと有効期限 ： ',
      this.replaceReturnForTextArea(this.state.holding_visa_info),
      ,
      'ご検討中のビザ種別 ： ',
      this.replaceReturnForTextArea(this.state.visa_you_want),
      ,
      'ご希望の連絡手段（電話もしくはEメール）と時間帯 ： ',
      this.replaceReturnForTextArea(this.state.how_to_contact),
      ,
      'ご相談内容についてお書き下さい ： ',
      this.replaceReturnForTextArea(this.state.content)
    ];

    window.location.href = 'mailto:' + 'Japan@ABICanada.com' + '?subject=ABIC お問い合わせフォーム' + '&body=' + mailto.join(encodeURIComponent(lineFeed));


    //this.setState({isSubmitted: true});
  }

  handleCopyInputs = async() => {

    var mailto = 
      'お名前 ： '+this.state.name+'\n\n'+
      'メールアドレス ： '+this.state.email+'\n\n'+
      '年齢 ： '+this.state.age+'\n\n'+
      '性別 ： '+this.state.gender+'\n\n'+
      'ＴＥＬ ： '+this.state.phone_number+'\n\n'+
      '住所 ： '+this.state.address+'\n\n'+
      '最終学歴及び在学期間 ： '+'\n'+
      this.replaceReturnForTextArea(this.state.educational_record)+'\n\n'+
      '職歴（会社、職務及び期間） ： '+'\n'+
      this.replaceReturnForTextArea(this.state.work_record)+'\n\n'+
      'カナダの滞在歴（滞在期間とビザタイプ) ： '+'\n'+
      this.replaceReturnForTextArea(this.state.visit_record_inCanada)+'\n\n'+   
      '現在所持しているビザのタイプと有効期限 ： '+'\n'+
      this.replaceReturnForTextArea(this.state.holding_visa_info)+'\n\n'+
      'ご検討中のビザ種別 ： '+'\n'+
      this.replaceReturnForTextArea(this.state.visa_you_want)+'\n\n'+
      'ご希望の連絡手段（電話もしくはEメール）と時間帯 ： '+'\n'+
      this.replaceReturnForTextArea(this.state.how_to_contact)+'\n\n'+
      'ご相談内容についてお書き下さい ： '+'\n'+
      this.replaceReturnForTextArea(this.state.content);

    await global.navigator.clipboard.writeText(mailto);

    alert("お問い合わせ内容をコピーしました。");
  };

  handleNameChange(event) {
    const inputValue = event.target.value;
    const isEmpty = inputValue === "";
    this.setState({                      
      name: inputValue,
      hasNameError: isEmpty,
    });
  }

  handleEmailChange(event) {
    const inputValue = event.target.value;
    const isEmpty = inputValue === "";
    this.setState({                      
      email: inputValue,
      hasEmailError: isEmpty,
    });
  }

  handleAgeChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      age: inputValue,
    });
  }

  handleGenderChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      gender: inputValue,
    });
  }
  handlePhoneNumberChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      phone_number: inputValue,
    });
  }

  handleAddressChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      address: inputValue,
    });
  }
  handleEducationalRecordChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      educational_record: inputValue,
    });
  }
  handleWorkRecordChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      work_record: inputValue,
    });
  }
  handleVisitRecordChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      visit_record_inCanada: inputValue,
    });
  }
  handleHoldingVisaChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      holding_visa_info: inputValue,
    });
  }
  handleVisaYouWantChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      visa_you_want: inputValue,
    });
  }
  handleHowToContactChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      how_to_contact: inputValue,
    });
  }
  handleContentChange(event) {
    const inputValue = event.target.value;
    const isEmpty = inputValue === "";
    this.setState({
      content: inputValue,
      hasContentError: isEmpty,
    });
  }

  render() {
    let nameErrorText;
    if (this.state.hasNameError) {
      nameErrorText = (
        <span className="form_required">
          名前を入力してください<br/>
        </span>
      );
    }

    let emailErrorText;
    if (this.state.hasEmailError) {
      emailErrorText = (
        <span className="form_required">
          メールアドレスを入力してください<br/>
        </span>
      );
    }

    let contentErrorText;
    if (this.state.hasContentError) {
      contentErrorText = (
        <span className="form_required">
          ご相談内容を入力してください
        </span>
      );
    } 

    let errorGuideMessage;
    if (this.state.isError)
      {
      errorGuideMessage = (
        <span className="form_required">
          いずれかの必須項目が入力されていません。ご確認ください。
        </span>
      );
    } 

    let contactForm;
      if (this.state.isConfirmed) {
        if (this.state.isSubmitted) {
          contactForm = (
            <p>
              <span className = "message">送信完了しました。お問い合わせ有難うございます。</span><br/>
              <br/>
              <a href="/">HOMEへ戻る</a>
            </p>
          )
        } else {
          contactForm = (
            <div>
              <p>
                以下の内容でお問い合わせメールを作成します。<br/>
                「メールを作成する」ボタンを押すとメールアプリが立ち上がります。そちらのメールを送信してください。
              </p>
                <hr/>
                <p class="contact_confirm">
                  <p className="contact_label">お名前 ：<span>{this.state.name}</span></p>
                  <p className="contact_label">メールアドレス ：<span>{this.state.email}</span></p>
                  <p className="contact_label">年齢 ： <span>{this.state.age}</span></p>
                  <p className="contact_label">性別 ： <span>{this.state.gender}</span></p>
                  <p className="contact_label">ＴＥＬ ： <span>{this.state.phone_number}</span></p>
                  <p className="contact_label">住所 ： <span>{this.state.address}</span></p>
                  <p className="contact_label">
                    最終学歴及び在学期間 ： <br/>
                    <span>{this.state.educational_record}</span>
                  </p>
                  <p className="contact_label">
                    職歴（会社、職務及び期間） ： <br/>
                    <span>{this.state.work_record}</span></p>
                  <p className="contact_label">
                    カナダの滞在歴（滞在期間とビザタイプ) ： <br/>
                    <span>{this.state.visit_record_inCanada}</span></p>
                  <p className="contact_label">
                     現在所持しているビザのタイプと有効期限 ： <br/>
                     <span>{this.state.holding_visa_info}</span></p>
                  <p className="contact_label">
                    ご検討中のビザ種別 ：<br/>
                    <span>{this.state.visa_you_want}</span></p>
                  <p className="contact_label">
                    ご希望の連絡手段（電話もしくはEメール）と時間帯 ：<br/>
                    <span>{this.state.how_to_contact}</span></p>
                  <p className="contact_label">
                    ご相談内容についてお書き下さい ： <br/>
                    <span>{this.state.content}</span></p>
                </p>
                <hr/>
                <p className="send_button">
                  <button>
                      <a href="/">
                       ホームに戻る
                      </a>
                    </button>
                  <button
                    onClick={()=>{this.handleReturn()}}
                  ><a>内容変更する</a></button>
                  <button
                    onClick={()=>{this.handleSubmit()}}
                  ><a>メールを作成する</a></button>
                </p>
                <p className="">
                  ※メールアプリが起動しない場合は、入力した内容をコピーし、メールにて<span><a href="mailto:Japan@ABICanada.com">Japan@ABICanada.com</a></span>へお送りください。</p>
                <p className="send_button">
                  <button
                    onClick={()=>{this.handleCopyInputs()}}
                  ><a>入力した内容をコピーする</a></button>
                </p>
            </div>
          )
        }
      } else {
        if(this.state.isError)
        {
          console.log("error");
          console.log(this.state.hasNameError,this.state.hasEmailError,this.state.hasContentError);

          console.log(nameErrorText,emailErrorText,contentErrorText);

        }
        contactForm = (    
          <p>
            下記フォームへ入力し [ 入力内容を確認 ] ボタンを押してください<br/>
            内容の確認後、入力内容を元にメールを自動作成いたします。そちらの送信をもってお問い合わせ完了となります。
            <br/>
            <br/>
            ＊なお、フォームからの送信ができない、或は送信後、二日営業日以内に こちらからの返信がない場合、お手数ですが、下記までご連絡ください。<br/>
            <br/>
            <a href="mailto:Japan@ABICanada.com">Japan@ABICanada.com</a><br/>
            <form className="contact" onSubmit={(event)=>{this.handleConfirm(event)}}>
              <p>{errorGuideMessage}</p>
              <p className="contact_label">お名前（必須）<span className="form_required_icon">※</span></p>
              {nameErrorText}
              <input
                value = {this.state.name}
                size = "30"
                onChange={(event)=>{this.handleNameChange(event)}}
              />
              <p className="contact_label">メールアドレス（必須）<span className="form_required_icon">※</span></p>
              {emailErrorText}
              <input
                value = {this.state.email}
                size = "50"
                className="input_long"
                onChange={(event)=>{this.handleEmailChange(event)}}
              />
              <p className="contact_label">年齢</p>
              <input type="number"
                value = {this.state.age}
                size = "5"
                onChange={(event)=>{this.handleAgeChange(event)}}
              />
              <p className="contact_label">性別</p>
              <div style={{color:"gray"}}>
              <select name="gender"
              onChange={(event)=>{this.handleGenderChange(event)}}>
                <option value="" hidden></option>
                <option value="男性">男性</option>
                <option value="女性">女性</option>
                <option value="その他">その他</option>
              </select>
              </div>
              <p className="contact_label">ＴＥＬ</p>
              <input
                type="tel"
                value = {this.state.phone_number}
                onChange={(event)=>{this.handlePhoneNumberChange(event)}}
              />
              <p style={{color:"gray"}}>
                例：123-456-7890
              </p>
              <p className="contact_label">住所</p>
              <input
                className="input_long"
                value = {this.state.address}
                size = "50"
                onChange={(event)=>{this.handleAddressChange(event)}}
              />
              <p className="contact_label">最終学歴及び在学期間</p>
              <textarea
                value = {this.state.educational_record}
                size = "50"
                onChange={(event)=>{this.handleEducationalRecordChange(event)}}
              />
              <p className="contact_label">職歴（会社、職務及び期間）</p>
              <textarea
                value = {this.state.work_record}
                onChange={(event)=>{this.handleWorkRecordChange(event)}}
              />
              <p className="contact_label">カナダの滞在歴（滞在期間とビザタイプ)</p>
              <textarea
                value = {this.state.visit_record_inCanada}
                onChange={(event)=>{this.handleVisitRecordChange(event)}}
              />
              <p className="contact_label">現在所持しているビザのタイプと有効期限</p>
              <textarea
                value = {this.state.holding_visa_info}
                onChange={(event)=>{this.handleHoldingVisaChange(event)}}
              />
              <p className="contact_label">ご検討中のビザ種別</p>
              <select name="visa_you_want"
              onChange={(event)=>{this.handleVisaYouWantChange(event)}}>
                <option value="" hidden>--以下からお選びください--</option>
                <option value="移民">移民</option>
                <option value="就労">就労</option>
                <option value="コモンロー">コモンロー</option>
                <option value="学生">学生</option>
                <option value="その他">その他</option>
              </select>
              <p className="contact_label">ご希望の連絡手段（電話もしくはEメール）と時間帯</p>
              <textarea
                value = {this.state.how_to_contact}
                onChange={(event)=>{this.handleHowToContactChange(event)}}
              />
              <p className="contact_label">ご相談内容についてお書き下さい（必須）<span className="form_required_icon">※</span></p>
              {contentErrorText}
              <textarea 
                className = "contact_content"
                value = {this.state.content}
                onChange={(event)=>this.handleContentChange(event)}
              /><br/>
    
              <p className="send_button">
              <a href="#pagetop"><button><a>入力内容を確認</a></button></a>
              </p>
            </form>
          </p>
        );
      }

    return (
        <>       
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【お問い合わせ】</title>
            <meta name="description" content="
            " />
          </Helmet>
            <h2>お問い合わせ</h2>
            <div>
                {contactForm}
            </div>
        </>
        );
    }
}