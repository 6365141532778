import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_sem from './img/seminar_info/im_sem.jpg';

export default class seminar_info extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【セミナーのご案内】</title>
            <meta name="description" content="
            ABICでは、 カナダでより豊かなライフを求め、真剣に就労、移民を考えている人に 対し、
            移民&ビザの手続きを含めたトータルサポートを行っています。皆様の就職・永住が成功へ導くよう努力しております。
            どうぞお気軽にお問い合わせ下さい!
            " />
          </Helmet>
            <h2>セミナーのご案内</h2>
            <div className="content-bg-blue">
                カナダ移民＆ビザセミナー      
            </div>
            <p>
                <div class="float_right">
                    <img src={im_sem} className="content-pic"/> 
                </div>
                <b>内容：</b>
                <p style={{color: "#3333CC"}}>
                    ワークビザを確実に取りたいが、<br/>
                    スポンサーがまだ見つかっていません。<br/>
                    でも、ほかにどんな方法があるのだろう？<br/>
                    特に、学歴もスキルもないけれど、<br/>
                    これからどのようにすれば移民に繋がるのでしょう？<br/>
                    今回のビザセミナーで説明いたします。<br/>
                </p>
                <p>
                    <b>開催時間: 未定</b><br/>
                    <b>場所</b>: ABIC事務所会議室又はZOOMによるオンライン<br/>
                    <b>費用</b>: <span style={{color:"red"}}>参加無料</span><br/>
                    <b>主催</b>: ABIC(エービック)カナダ移民＆ビザ事務所<br/>
                    <b>予約</b>: 604-688-1956 または　 <a href="mailto:japan@abicanada.com">japan@abicanada.com</a> まで<br/>
                    <b>場所</b>: United Kingdom Building Suite 101 or 111<br/>
                                 409 Granville Street, Vancouver, BC CANADA V6C 1T2, カナダラインウォーターフロント駅すぐ<br/>
                    <br/>
                    ご予約はお早めにどうぞ！<br/>
                    <br/>
                </p>
            </p>

            <div className="content-bg-blue">
                「カナダ起業セミナー」
            </div>
            <p>
                <div class="float_right">
                    <img src={im_sem} className="content-pic"/> 
                </div>
                <b>内容：</b><br/>
                <br/>
                事業企画のポイント、会社の登記、ビジネスライセンスの取得、財務管理、法人の税金申告、 労働基準法、ワークビザについて 等
                <p style={{color: "#3333CC"}}>
                    資金ゼロ、人脈ゼロから、どうやって起業できるか？<br/>
                    運営中にトラブルのことで、まずどこに相談したらよいのか？<br/>
                </p>
                <p>s
                    こんな悩みを持つ方はぜひこのセミナーにご参加してみてください。事業企画から会社設立、会社登記や財務管理など、カナダで起業する為に知っておきたいポイントについてわかりやすく解説します。カナダでの起業や支店オープンを考えていらっしゃる方は是非ご参加下さい。
                </p>
                <p>
                    <b>開催時間: 未定</b><br/>
                    <b>場所</b>: ABIC事務所会議室又はZOOMによるオンライン<br/>
                    <b>費用</b>: <span style={{color:"red"}}>参加無料</span><br/>
                    <b>主催</b>: ABIC(エービック)カナダ移民＆ビザ事務所<br/>
                    <b>予約</b>: 604-688-1956 または　 <a href="mailto:japan@abicanada.com">japan@abicanada.com</a> まで<br/>
                    <b>場所</b>: United Kingdom Building Suite 101 or 111<br/>
                                 409 Granville Street, Vancouver, BC CANADA V6C 1T2, カナダラインウォーターフロント駅すぐ<br/>
                    <br/>
                </p>
            </p>
            <div className="content-bg-blue">
                ABIC説明会の模様
            </div>
            <p>
                ABICでは、 カナダでより豊かなライフを求め、真剣に就労、移民を考えている人に 対し、<br/>
                移民&ビザの手続きを含めたトータルサポートを行っています。皆様の就職・永住が成功へ導くよう努力しております。<br/>
                どうぞお気軽にお問い合わせ下さい!<br/>
            </p>
            <div className="youtube">
                <iframe src="https://www.youtube.com/embed/xiZ7FJh0L6o" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> 
            <br/>
            <a href="/contact">お問い合わせはこちらから</a><br/>
            <br/>
            <a href="/company_info/overview">会社概要</a>
        </>
      );
    }
  }