import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import '../../App.css';
import DownloadLink from "react-download-link";
import {Helmet} from "react-helmet";

import im_stu from './img/study_permit/im_stu.jpg';


export default class immigration_and_visa_canada_visitor_visa extends Component {
  render() {
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【ビジタービザ】</title>
            <meta name="description" content="
            通称“ビザ”とはカナダに入国できる許可のものであり、滞在するには目的によって“パーミット”を空港・国境又は国内の移民局に申請し、発行してもらわなければなりません。本文中、皆様が理解しやすいため、パーミットをビザと称する箇所がありますのでご注意下さい。例えば、Temporary Resident Status as a Visitorをビジタービザと記載しています。
            " />
          </Helmet>
            <h2>ビジタービザ</h2>
            <div className="content-bg-blue">
                カナダビザ & パーミット
            </div>
            <p>
                通称“ビザ”とはカナダに入国できる許可のものであり、滞在するには目的によって“パーミット”を空港・国境又は国内の移民局に申請し、発行してもらわなければなりません。本文中、皆様が理解しやすいため、パーミットをビザと称する箇所がありますのでご注意下さい。例えば、Temporary Resident Status as a Visitorをビジタービザと記載しています。
            </p>

            <div className="content-bg-blue">
                カナダビジタービザ (Temporary Resident Visitor Visa & Temporary Resident Status )
            </div>
            <p>
            観光・親族訪問・視察の目的でカナダに入国する前に、居住地にもっとも近いカナダビザオフィスにTemporary Resident Visaを事前に取得しなければなりません。カナダ入国の際、空港・国境(Port of Entry)の移民官よりテンポラリーレジデント許可が発行されます。ただし日本は査証免除の国であるため、日本人の場合は事前にこちらのTemporary Resident Visitor Visaを取得する必要はありません。通常はパスポートにスタンプのみが押されて、6ヶ月の滞在期間が与えられます。<br/>
            <br/>
            <b>注意点:</b><br/>
            カナダビジタービザにより滞在許可の期限（６ヶ月）までカナダで学校に通うことができます。ただしそれは滞在期間を超えないことが原則であり、 滞在期限を超える学校のコースに申し込む場合、学生ビザを取得することが必要です。<br/>
            </p>

            <div className="content-bg-blue">
                カナダ ビジタービザの延長　(Visitor Extension)
            </div>
            <p>
                カナダ入国時許可された期間（通常6ヶ月）を超えてカナダに滞在されたい場合など、必要によりカナダのビジターの延長申請をする事が可能です。申請できる回数の制限はありませんが、十分な、なおかつ適切な理由が必要とされます。また、ビジタービザを延長する場合は現在お持ちのビザの有効期限が切れる1ヶ月前までに申請することを勧めします。
            </p>
            <div className="content-bg-border">
                基本的に必要申請書類
            </div>
            <p>
                申請方法：　オンラインもしくは郵送<br/>
                <br/>
                ◆ 延長申請書（IMM5708）：<a href="http://www.cic.gc.ca/english/pdf/kits/forms/IMM5708E.PDF" target="_blunk" download="IMM5708E.pdf">IMM5708E.pdf</a><br/>
                ◆ チェックリスト：<a href="http://www.cic.gc.ca/english/pdf/kits/forms/IMM5558E.PDF" target="_blunk" download="IMM5558E.pdf">IMM5558E.pdf</a><br/>
                ◆ ビジター延長申請料　＄100（CND）<br/>
                ◆ パスポートのコピー（パスポート番号、有効期限、氏名、生年月日が記載されたページ、及び直近でカナダに入国した際のスタンプのページ）<br/>
                ◆ 現在所持しているビザのコピー（もしあれば）<br/>
                ◆ 残高証明書<br/>
                ◆ 日本への帰国予定日の航空券（もしあれば）<br/>


                ビジター延長のプロセス期間は現在のところ3～4ヶ月となっています。現在お持ちのビザの有効期限が切れる前に申請をしていれば、プロセス期間中に現在のビザが切れてしまっても延長申請の結果が届くまではカナダに合法的に滞在する事ができます。 但し、延長申請中にカナダ国外に出てしまうとその延長申請はキャンセルされてしまいますのでご注意下さい。<br/>


                また、ビジター延長の申請できる回数は制限されていませんが、あまり長くビジター延長で継続的に滞在していると、カナダで不法就労している疑い、また自国との結びつきがないという理由から却下される事もあります。ビジター延長申請の際は滞在理由を具体的に説明し、その理由を証明する十分な補助書類をご用意下さい。<br/>
            </p>
        </>
      );
    }
  }