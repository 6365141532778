import React, { Component } from 'react';
import './../../App.css';
import {Helmet} from "react-helmet";

export default class contact extends Component {

    constructor(props){
        super(props);
        this.state = {
            name:"",
            email:"",
            age:"",
            gender:"",
            phone_number:"",
            address:"",
            educational_record:"",
            work_record:"",
            visit_record_inCanada:"",
            holding_visa_info:"",
            visa_you_want:"",
            how_to_contact:"",
            content:"",
            isSubmitted: false,
            isConfirmed: false,
            isError: false,
            hasNameError: false,
            hasEmailError: false,
            hasContentError: false,
        };
    }

    
  handleConfirm(event) {
    event.preventDefault();

    window.location.hash = "#pagetop"

    var stateCheck = true;

    if(this.state.name.length == 0
      || this.state.email.length == 0
      || this.state.content.length == 0)
    {
      stateCheck = false;

      if(this.state.name.length == 0)
      {
        this.setState({hasNameError: true});
      }
      if(this.state.email.length == 0)
      {
        this.setState({hasEmailError: true});
      }
      if(this.state.content.length == 0)
      {
        this.setState({hasContentError: true});
      }
    }


    if(stateCheck)
    {
      this.setState({isConfirmed: true});
    }else
    {
      this.setState({isError: true});
    }
  }

  handleReturn()
  {
    this.setState({isConfirmed: false});
  }

  replaceReturnForTextArea(v)
  {
    var text = v.replace(/\n/g,"%0D%0A");
    text = text.replace(/\r/g,"%0D%0A");
    return text;
  }
    
  handleSubmit() {

    var lineFeed = "\r\n";
    var lineFeedForText = "%0D%0A";

    console.log(this.state.content);
    var mailto = [
      '姓名 ： '+this.state.name,
      ,
      '电子邮箱 ： '+this.state.email,
      ,
      '年齢 ： '+this.state.age,
      ,
      '性別 ： '+this.state.gender,
      ,
      'TEL ： '+this.state.phone_number,
      ,
      '目前所在地 ： '+this.state.address,
      ,
      '最终学历以及在学期间 ： ',
      this.replaceReturnForTextArea(this.state.educational_record),
      ,
      '工作经历（公司名，职位，工作期间） ： ',
      this.replaceReturnForTextArea(this.state.work_record),
      ,
      '加拿大的经历（访问或学习或工作以及期间) ： ',
      this.replaceReturnForTextArea(this.state.visit_record_inCanada),
      ,
      '目前所在地的签证（如非公民或永久居民） ： ',
      this.replaceReturnForTextArea(this.state.holding_visa_info),
      ,
      '希望获得的加拿大签证 ： ',
      this.replaceReturnForTextArea(this.state.visa_you_want),
      ,
      '您希望的联系方式（电话或电子邮件）和时间段 ： ',
      this.replaceReturnForTextArea(this.state.how_to_contact),
      ,
      '请填写咨询内容 ： ',
      this.replaceReturnForTextArea(this.state.content)
    ];

    window.location.href = 'mailto:' + 'abic@ABICanada.com' + '?subject=ABIC 免费咨询表' + '&body=' + mailto.join(encodeURIComponent(lineFeed));


    //this.setState({isSubmitted: true});
  }

  handleCopyInputs = async() => {

    var mailto = 
      '姓名 ： '+this.state.name+'\n\n'+
      '电子邮箱 ： '+this.state.email+'\n\n'+
      '年齢 ： '+this.state.age+'\n\n'+
      '性別 ： '+this.state.gender+'\n\n'+
      'TEL ： '+this.state.phone_number+'\n\n'+
      '目前所在地 ： '+this.state.address+'\n\n'+
      '最终学历以及在学期间 ： '+'\n'+
      this.replaceReturnForTextArea(this.state.educational_record)+'\n\n'+
      '工作经历（公司名，职位，工作期间） ： '+'\n'+
      this.replaceReturnForTextArea(this.state.work_record)+'\n\n'+
      '加拿大的经历（访问或学习或工作以及期间) ： '+'\n'+
      this.replaceReturnForTextArea(this.state.visit_record_inCanada)+'\n\n'+   
      '目前所在地的签证（如非公民或永久居民） ： '+'\n'+
      this.replaceReturnForTextArea(this.state.holding_visa_info)+'\n\n'+
      '希望获得的加拿大签证 ： '+'\n'+
      this.replaceReturnForTextArea(this.state.visa_you_want)+'\n\n'+
      '您希望的联系方式（电话或电子邮件）和时间段 ： '+'\n'+
      this.replaceReturnForTextArea(this.state.how_to_contact)+'\n\n'+
      '请填写咨询内容 ： '+'\n'+
      this.replaceReturnForTextArea(this.state.content);

    await global.navigator.clipboard.writeText(mailto);

    alert("您的諮詢內容已複製到剪貼簿。");
  };

  handleNameChange(event) {
    const inputValue = event.target.value;
    const isEmpty = inputValue === "";
    this.setState({                      
      name: inputValue,
      hasNameError: isEmpty,
    });
  }

  handleEmailChange(event) {
    const inputValue = event.target.value;
    const isEmpty = inputValue === "";
    this.setState({                      
      email: inputValue,
      hasEmailError: isEmpty,
    });
  }

  handleAgeChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      age: inputValue,
    });
  }

  handleGenderChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      gender: inputValue,
    });
  }
  handlePhoneNumberChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      phone_number: inputValue,
    });
  }

  handleAddressChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      address: inputValue,
    });
  }
  handleEducationalRecordChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      educational_record: inputValue,
    });
  }
  handleWorkRecordChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      work_record: inputValue,
    });
  }
  handleVisitRecordChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      visit_record_inCanada: inputValue,
    });
  }
  handleHoldingVisaChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      holding_visa_info: inputValue,
    });
  }
  handleVisaYouWantChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      visa_you_want: inputValue,
    });
  }
  handleHowToContactChange(event) {
    const inputValue = event.target.value;
    this.setState({                      
      how_to_contact: inputValue,
    });
  }
  handleContentChange(event) {
    const inputValue = event.target.value;
    const isEmpty = inputValue === "";
    this.setState({
      content: inputValue,
      hasContentError: isEmpty,
    });
  }

  render() {
    let nameErrorText;
    if (this.state.hasNameError) {
      nameErrorText = (
        <span className="form_required">
          请输入您的名字<br/>
        </span>
      );
    }

    let emailErrorText;
    if (this.state.hasEmailError) {
      emailErrorText = (
        <span className="form_required">
          请输入您的电子邮件地址<br/>
        </span>
      );
    }

    let contentErrorText;
    if (this.state.hasContentError) {
      contentErrorText = (
        <span className="form_required">
          请输入咨询内容
        </span>
      );
    } 

    let errorGuideMessage;
    if (this.state.isError)
      {
      errorGuideMessage = (
        <span className="form_required">
          有未输入的必填项目，请确认
        </span>
      );
    } 

    let contactForm;
      if (this.state.isConfirmed) {
        if (this.state.isSubmitted) {
          contactForm = (
            <p>
              <span className = "message">发送完成。感谢您的垂询。</span><br/>
              <br/>
              <a href="/chinese/">回归HOME</a>
            </p>
          )
        } else {
          contactForm = (
            <div>
              <p>
              确认内容后，将根据输入的内容自动创建电子邮件。您的询问将在发送时完成。
              </p>
                <hr/>
                <p class="contact_confirm">
                  <p className="contact_label">姓名 ：<span>{this.state.name}</span></p>
                  <p className="contact_label">电子邮箱 ：<span>{this.state.email}</span></p>
                  <p className="contact_label">年齢 ： <span>{this.state.age}</span></p>
                  <p className="contact_label">性別 ： <span>{this.state.gender}</span></p>
                  <p className="contact_label">TEL ： <span>{this.state.phone_number}</span></p>
                  <p className="contact_label">目前所在地（城市、国家） ： <span>{this.state.address}</span></p>
                  <p className="contact_label">
                    最终学历以及在学期间 ： <br/>
                    <span>{this.state.educational_record}</span>
                  </p>
                  <p className="contact_label">
                    工作经历（公司名，职位，工作期间） ： <br/>
                    <span>{this.state.work_record}</span></p>
                  <p className="contact_label">
                    加拿大的经历（访问或学习或工作以及期间)： <br/>
                    <span>{this.state.visit_record_inCanada}</span></p>
                  <p className="contact_label">
                    目前所在地的签证（如非公民或永久居民）： <br/>
                     <span>{this.state.holding_visa_info}</span></p>
                  <p className="contact_label">
                    希望获得的加拿大签证：<br/>
                    <span>{this.state.visa_you_want}</span></p>
                  <p className="contact_label">
                    您希望的联系方式（电话或电子邮件）和时间段：<br/>
                    <span>{this.state.how_to_contact}</span></p>
                  <p className="contact_label">
                    请填写咨询内容（必填）： <br/>
                    <span>{this.state.content}</span></p>
                </p>
                <hr/>
                <p className="send_button">
                <button>
                      <a href="/chinese/">
                      返回首頁
                      </a>
                    </button>
                <button
                  onClick={()=>{this.handleReturn()}}
                ><a>修改内容</a></button>
                <button
                  onClick={()=>{this.handleSubmit()}}
                ><a>發送電子郵件</a></button>
              </p>
              <p className="">
                  ※若無法啟動電子郵件應用程式，請點擊以下複製您填冩的內容，並通過電子郵件發送至<span><a href="mailto:abic@ABICanada.com">abic@ABICanada.com</a></span></p>
                <p className="send_button">
                  <button
                    onClick={()=>{this.handleCopyInputs()}}
                  ><a>複製所填冩的內容</a></button>
                </p>
            </div>
          )
        }
      } else {
        if(this.state.isError)
        {
          console.log("error");
          console.log(this.state.hasNameError,this.state.hasEmailError,this.state.hasContentError);

          console.log(nameErrorText,emailErrorText,contentErrorText);

        }
        contactForm = (    
          <p>
            请填写下面的表格，然后按[請核對您所填寫的資訊]按钮。<br/>
            确认内容后，将根据输入的内容自动创建电子邮件。您的询问将在发送时完成。
            <br/>
            <br/>
            *如果您无法通过表格提交，或者在提交后的两个工作日内未收到我们的回复，请通过以下方式与我们联系。<br/>
            <br/>
            <a href="mailto:abic@ABICanada.com">abic@ABICanada.com</a><br/>
            <form className="contact" onSubmit={(event)=>{this.handleConfirm(event)}}>
              <p>{errorGuideMessage}</p>
              <p className="contact_label">姓名（必填）<span className="form_required_icon">※</span></p>
              {nameErrorText}
              <input
                value = {this.state.name}
                size = "30"
                onChange={(event)=>{this.handleNameChange(event)}}
              />
              <p className="contact_label">电子邮箱（必填）<span className="form_required_icon">※</span></p>
              {emailErrorText}
              <input
                value = {this.state.email}
                size = "50"
                className="input_long"
                onChange={(event)=>{this.handleEmailChange(event)}}
              />
              <p className="contact_label">年齢</p>
              <input type="number"
                value = {this.state.age}
                size = "5"
                onChange={(event)=>{this.handleAgeChange(event)}}
              />
              <p className="contact_label">性別</p>
              <div style={{color:"gray"}}>
              <select name="gender"
              onChange={(event)=>{this.handleGenderChange(event)}}>
                <option value="" hidden></option>
                <option value="男性">男性</option>
                <option value="女性">女性</option>
                <option value="その他">其他</option>
              </select>
              </div>
              <p className="contact_label">TEL</p>
              <input
                type="tel"
                value = {this.state.phone_number}
                onChange={(event)=>{this.handlePhoneNumberChange(event)}}
              />
              <p style={{color:"gray"}}>
                例：123-456-7890
              </p>
              <p className="contact_label">目前所在地（城市、国家）</p>
              <input
                className="input_long"
                value = {this.state.address}
                size = "50"
                onChange={(event)=>{this.handleAddressChange(event)}}
              />
              <p className="contact_label">最终学历以及在学期间</p>
              <textarea
                value = {this.state.educational_record}
                size = "50"
                onChange={(event)=>{this.handleEducationalRecordChange(event)}}
              />
              <p className="contact_label">工作经历（公司名，职位，工作期间）</p>
              <textarea
                value = {this.state.work_record}
                onChange={(event)=>{this.handleWorkRecordChange(event)}}
              />
              <p className="contact_label">加拿大的经历（访问或学习或工作以及期间)</p>
              <textarea
                value = {this.state.visit_record_inCanada}
                onChange={(event)=>{this.handleVisitRecordChange(event)}}
              />
              <p className="contact_label">目前所在地的签证（如非公民或永久居民）</p>
              <textarea
                value = {this.state.holding_visa_info}
                onChange={(event)=>{this.handleHoldingVisaChange(event)}}
              />
              <p className="contact_label">希望获得的加拿大签证</p>
              <select name="visa_you_want"
              onChange={(event)=>{this.handleVisaYouWantChange(event)}}>
                <option value="" hidden>--请从以下选择--</option>
                <option value="移民">移民</option>
                <option value="工作">工作</option>
                <option value="学习">学习</option>
                <option value="访问">访问</option>
                <option value="其他">其他</option>
              </select>
              <p className="contact_label">您希望的联系方式（电话或电子邮件）和时间段</p>
              <textarea
                value = {this.state.how_to_contact}
                onChange={(event)=>{this.handleHowToContactChange(event)}}
              />
              <p className="contact_label">请填写咨询内容（必填）<span className="form_required_icon">※</span></p>
              {contentErrorText}
              <textarea 
                className = "contact_content"
                value = {this.state.content}
                onChange={(event)=>this.handleContentChange(event)}
              /><br/>
    
              <p className="send_button">
              <a href="#pagetop"><button>請核對您所填寫的資訊</button></a>
              </p>
            </form>
          </p>
        );
      }

    return (
        <>       
          <Helmet>
            <meta charSet="utf-8" />
            <title>亚历山大移民事务所ABIC Immigration【免费咨询表】</title>
            <meta name="description" content="
                
            " />
          </Helmet>
          <span class="cn-part">
            <h2>免费咨询表</h2>
            <div>
                {contactForm}
            </div>
          </span>
        </>
        );
    }
}