import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_v_kaz from './img/family_class/im_v_kaz.jpg';

export default class immigration_and_visa_canada_family_class extends Component {
  render() {
      return (
        <>
           <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【家族移民】</title>
            <meta name="description" content="
                カナダの市民権／永住権を持つ人がスポンサーになり、その方の家族のカナダ移民申請をすることができます。このクラスで申請できる家族メンバーは以下の通りです。
            " />
          </Helmet>
            <h2>家族移民<span> (Family Class)</span></h2>
            <div class="float_right">
              <img src={im_v_kaz} className="content-pic"/> 
            </div>
            <div className="content">
                <p>
                    カナダの市民権／永住権を持つ人がスポンサーになり、その方の家族のカナダ移民申請をすることができます。このクラスで申請できる家族メンバーは以下の通りです。
                </p>
            </div>
            <div className="content-bg-blue">
                申請可能な家族メンバー
            </div>
            <p>
                ◆ 配偶者/コモンロー/コンジュガルパートナー<br/> 
                ◆ 両親<br/> 
                ◆ 子供(*未婚で19才未満、他) 2014年8月1日より<br/> 
                ◆ 18歳未満の養子<br/> 
                ◆ 兄弟、甥、姪、孫などで両親がいない。未婚・18才未満<br/> 
                ◆ その他の親戚(移民法の規定に基づく)<br/> 
            </p>
            <div className="content-bg-blue">
                ファミリークラス カナダ移民申請の流れ
            </div>
            <p>
                <h4>1 スポンサー資格の確認</h4>
                以下の全ての条件を満たさなければスポンサーにはなれません。
                <br/>
                <div className="content">
                    • カナダ在住のカナダ市民又はカナダ永住者<br/>
                    • 18歳以上<br/>
                    • Undertaking agreementに承諾すること(3～10年間の経済保証）<br/>
                    • 配偶者、子供以外の家族メンバーをスポンサーする場合、カナダ政府が定める一定以上の収入があること
                </div>
            </p>
            <p>
                ※以上の条件を満たしても、スポンサーになれない場合もあります。例えば、以前の配偶者をのスポンサーになり、離婚したがその扶養義務がまだ完了していない場合、新たに外国の方と結婚した場合でも、すぐにスポンサーになることはできません。
            </p>
            <p>
                <h4>2　国外、国内のどちらかの提出先を決める</h4>
                カナダ国外に居住する移民申請者はMississauga, Ontarioへ<br/>
                カナダ国内に居住するならば、Vegreville, Albertaの移民センターへ書類を送付します<br/>
                ※国外申請で申込んだ方は申請中何度でもカナダへの訪問は可能。<br/>
                ※国内申請の場合出国後カナダ再入国も可能だが、入国拒否された場合は移民申請が中断されてしまう可能性はあるので、十分にご気をつけてください。<br/>
            </p>
            <p>
                <h4>3　移民申請フォーム記入と書類の収集</h4>
                必要な申請フォームは移民局のウェブサイト又はコールセンターに電話をしてリクエストすることができます。<br/>
                移民局コールセンター (1-888-242-2100)<br/>
                <br/>
                ・ フォームに自分に当てはまる項目を全て記入しましょう。<br/>
                ・ 記入漏れがあると申請書類が返送されてしまうこともあります。<br/>
                ・ 申請フォームに加え、二人の関係を証明する補足資料を添付します。<br/>
                ・ 特にコモンロー関係は継続的に１２ヶ月以上同居した事実を立証することが必要です。<br/>
                <span className="text_indent"> 例：賃貸契約書、共同名義銀行口座、公共料金の明細書等</span><br/>
            </p>
            <p>
                <h4>4　移民局が徴収する費用</h4>
                • スポンサー：　７５ドル<br/>
                • 移民申請者：４７５ドル<br/>
                • 移民申請者の扶養子供：１５０ドル（22歳以下）、５５０ドル（22歳又は以上）<br/>
            </p>
            <p>
                <h4>5　永住権が下りるまで</h4>
                まずはスポンサーの承認レターが届きます。<br/>
                国内(In Land)申請の方はオープンワークパーミットの申請はこの時点でできます。<br/>
                そして面接通知又は面接免除並びにパスポートリクエストが届きます。<br/>
                最後に移民ビザがパスポートに張り、申請者の手元に送り返され、ビザの有効までにカナダに入国し、ランディング手続きを行い、永住カード（メープルカード）が約１－２ヶ月後に届きます。<br/>
            </p>
            <p>
                <h4>6　注意点</h4>
                移民局から追加書類・面接をリクエストされた場合はすぐに対応しましょう。<br/>
                住所変更の際、必ず移民局に連絡すること。<br/>

                ABICではファミリークラスによる移民申請を数多く取り扱っています。<br/>
                普段お仕事で忙しい方には、提出する書類も多く、ご自身の負担も大きいと思われます。<br/>
                ABICのコンサルタントは、貴方の状況に応じて最適な申請パッケージを作成し、 より確実でスムーズなプロセスとなるよう完全サポート致します。まずお問合せください。<br/>
            </p>
            <p>
            <h4>下記のケースに当たる方はスポンサー資格は認めない。</h4>
                • 過去に配偶者かパートナーのスポンサーにより永住権を取得した日から5年はまだ経過していない。<br/>
                • 過去にスポンサーした配偶者かパートナーの扶養義務はまだ完了されておらず、つまり3年はまだ経過していない。<br/>

            </p>
        </>
      );
    }
  }