import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import start_up_pic from './img/start_up.png';

export default class start_up extends Component {

  render() {
    return (
      <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>亚历山大移民事务所ABIC Immigration【创业咨询】</title>
            <meta name="description" content=""
                
             />
          </Helmet>
          <span class="cn-part">
          <h2>
              创业咨询
          </h2>
          <div class="float_right">
              <img src={start_up_pic} className="content-pic width_200px"/> 
          </div>
          <p>
            进入加拿大投资或者开发市场可以成为您北美发展战略的一个很好的选择。很多人以为北美的经济制度非常健全，商品及服务体系完善，很难有再发展的空间。这样的想法是完全错误的。的确，成功并不容易，但是始终有其发展空间，甚至某些领域还很大。我们在经济的大舞台上可以看到“可口可乐”在经历百年发展后仍然继续保持稳步发展，同时也会惊叹“微软”凭借新兴技术的突飞猛进。<b>您，始终有机会!</b>
          </p>
          <p>
            目前真正进入北美市场的中国企业还相当少。在北美，您不需要每天请客喝酒拉关系，然而却是检验您是否理解和掌握北美的商业习惯和企业管理方式的时候到了。第一步应该是去理解、去接受。如果您因文化不同而立刻拒绝理解；或者依然循着曾经在中国成功的方法；或者无法“入乡随俗”，投资失败很可能就在前面。
          </p>
          <p>
            有价值的咨询可帮您少走或不走弯路、避免潜在的巨大商业风险。ABIC公司希望成为您的桥梁、您的良友，协助您迈出北美发展的第一步。我们深知您的成功不存在侥幸，所以ABIC投资顾问会脚踏实地、立足于北美当地的商业习惯和文化角度，提供已经过实践考验过的、切实可行的方案，一起与您探讨和实施，而不是机械地将MBA理论生搬硬套。
        </p>
        <div className="content-bg-blue">
            ABIC怎样协助您在北美发展？
        </div>
        <div className="content">
            1.可行性调查 (Feasibility Study)
        </div>
        <p>
            市场分析—最重要的是实地考察！<br/>
            拜访客户—了解客户的需求，往往效果好！<br/>
            资金调配—各项预算合理吗？ <br/>
            人员设备—是否可以保证需求？<br/>
        </p>
        <p>
            <b>要点:</b> 调查首先应该是客观的，而不能具有任何偏向性，更非某个人的独断。建议取得公司内董事、主要负责人意见的一致。
        </p>
        <div className="content">
            2.商业计划 (Business Plan)
        </div>
        <p>
            确定目的—此计划交由谁阅览？<br/>
            抓住要点—过于冗长会失去方向！
        </p>
        <p>
            <b>要点:</b>  首先要确定目的，审批的部门是政府？银行？还是企业自己使用？ 
        </p>
        <div className="content">
            3.成立公司 (Incorporation)
        </div>
        <p>
            选择地点—是否让客户感觉不便？<br/>
            成立手续—有否遗漏某些登记手续？<br/>
            税务法律—很重要、必不可少的咨询！<br/>
            常年顾问—用最少的钱办最多的事！
        </p>
        <p>
            <b>要点:</b>  公司成立手续的确不难，尤其在国外。然而您有必要注意公司的法规、当地税务和法律知识的咨询，否则极易造成巨大商业损失。
        </p>
        <div className="content">
            4.市场销售 (Marketing & Sales)
        </div>
        <p>
            品牌命名—是否为当地市场接受？<br/>
            广告促销—花钱越多越好吗？<br/>
            展览会议—充分地利用了吗？<br/>
            行业协会—在北美不容忽视！<br/>
            地区代理—决定您的成败！

        </p>
        <p>
            <b>要点:</b>  您的智慧、能力、和努力在竞争中可能得到回报，也可能一无所获；但您有的是一个公平的机会！
        </p>
        <div className="content">
            5.经营管理 (Operation Management)
        </div>
        <p>
            人才管理—需要您明确的引导！
            部门管理—生产和销售部门的协调？
            业务管理—分工明确吗？
        </p>
        <p>
            <b>要点:</b>  您的部下有可能是加拿大籍的德国人，与您有截然不同的文化背景和习惯，互相沟通是管理的第一步！
        </p>            
        </span>
    </>
    );
  }
}