import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_v_koj from './img/skilled_worker/im_v_koj.jpg';

export default class immigration_and_visa_canada_skilled_worker extends Component {
  render() {
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【個人移民】</title>
            <meta name="description" content="
            連邦スキルドワーカープログラムは、数ある永住権プログラムの中で最も一般的なプログラムです。これまでの永住権取得者の約３割がこのプログラムを経てカナダへ移民しています。
            " />
          </Helmet>
            <h2>個人移民<span> (Skilled Worker)</span></h2>
            <div class="float_right">
              <img src={im_v_koj} className="content-pic"/> 
            </div>
            <div className="content">
                <p>
                    連邦スキルドワーカープログラムは、数ある永住権プログラムの中で最も一般的なプログラムです。これまでの永住権取得者の約３割がこのプログラムを経てカナダへ移民しています。
                    <br/>
                    しかし、このプログラムを経た移住者のカナダへの適応問題などが指摘され、ここ数年、申請条件は頻繁に変更され、かつ、一部受付停止もありました。 パスマークを超えていることが必要です。
                </p>
            </div>
            <a href="http://www.cic.gc.ca/english/immigrate/skilled/apply-who.asp">
                Eligibility to apply as a Federal Skilled Worker (Express Entry) - Government of Canada
            </a>

            <div className="content-bg-blue">
                主な申請条件
            </div>
            <p>
                ● 職歴経験<br/>
                • Managerial jobs (skill type 0) <br/> 
                • Professional jobs (skill level A) <br/> 
                • Technical jobs and skilled trades (skill level B)<br/> 
            </p>
            <p>
                ● 語学力<br/>
                必要とされる語学力カナダ語学力ベンチマーク（英語又はフランス語）の７以上。英語はIELSTまたはCELPIPで、フランス語はTEFを受験する必要がある。
            </p>
            <p>
                ● 学歴<br/>
                カナダでの学歴もしくは海外の学歴であれば、カナダ移民局が認定した機関よりECAレポートを取得すること。
            </p>
            <p>
                ● ポイント査定<br/>
                カナダ移民局指定の６つのファクターにて合計ポイントが６７点以上ある。
            </p>
            <table className="bglb">
                <tr>
                    <th width="10vw"></th>
                    <th width="60vw">Selection factors</th>
                    <th width="10vw">Mark</th>
                </tr>
                <tr>
                    <td>1</td>
                    <td>語学力（第一言語Max.24、第二言語Max.4）</td>
                    <td>28</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>学歴(PhD. Max.15)</td>
                    <td>25</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>職歴(6年又は以上Max.15)</td>
                    <td>15</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>年齢(18歳-35歳でMax.12)</td>
                    <td>12</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>雇用の確保(Arranged Employment)</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>適応性（カナダでの就学、就労の経歴など）</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td colSpan="2">Total(満点)</td>
                    <td>100</td>
                </tr>
                <tr>
                    <td colSpan="2">Pass Mark(合格)</td>
                    <td>67</td>
                </tr>
            </table>
            <br/>

            <p>
            ● 資金証明
            </p>
            <table className="bglb">
                <tr>
                    <th width="40vw">申請者本人を含む 家族の合計人数</th>
                    <th width="60vw">求められる資金 （カナダドル）</th>
                </tr>
                <tr>
                    <td>1</td>
                    <td>$11,115</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>$13,837</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>$17,011</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>$20,654</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>$23,425</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>$26,419</td>
                </tr>
                <tr>
                    <td>7 又は以上</td>
                    <td>$29,414</td>
                </tr>
                <tr>
                    <td>家族メンバー１名追加</td>
                    <td>$3,492</td>
                </tr>
            </table>
            <br/>
            <p>2020年現在。金額のアップデートは移民局サイトをご確認下さい。</p>
            <hr/>
        </>
      );
    }
  }