import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";


export default class immigration_and_visa_canada_canadian_express_class extends Component {
  render() {
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【カナダ経験移民】</title>
            <meta name="description" content="
            2008年9月17日より実施され、その後数度改正され、カナダ政府に促進されている永住プログラムです。
            カナダでの就労経験を持ち、一定期間以上の管理・専門または技術職 (Skilled Worker) の経験がある人を対象とし、中級レベルの語学力が求められます。
            " />
          </Helmet>

            <h2>カナダ経験移民<span> (Canadian Experience Class)</span></h2>
            <div className="content">
                <p>
                2008年9月17日より実施され、その後数度改正され、カナダ政府に促進されている永住プログラムです。
                <br/>
                カナダでの就労経験を持ち、一定期間以上の管理・専門または技術職 (Skilled Worker) の経験がある人を対象とし、中級レベルの語学力が求められます。
            </p>
            </div>
            <p>
            </p>
            <div className="content-bg-blue">
                基本条件
            </div>
            <p>
                ● 各職業(NOC)レベルに求められた語学レベルに満たすこと<br/> 
                ● 申請日より過去の３年のうち、カナダ国内で合法的に最低１年フルタイム（若しくは相当するパートタイム）スキルドワーカー(Skilled worker)による就労<br/> 
                ● 職歴経験は職種リスト(Canadian National Occupational Classification)の中に、スキルタイプ０（マネージャー）スキルタイプＡ（プロフェショナル）またはスキルタイプＢ（テクニカル・トレード）に記載されている職業によるものです<br/> 
            </p>
            <div className="content-bg-blue">
                Skilled Worker Experience/スキルドワーカー経験とは
            </div>
            <p>
                カナダ人材開発省 (ESDC: Employment and Social Development Canada) の 職種リスト(NOC: National Occupational Classification)に記載されている下記のレベルにある職業です。 <br/> 
                スキルタイプ０（マネージャー）<br/> 
                スキルタイプＡ（プロフェショナル）<br/> 
                または スキルタイプＢ（テクニカル）<br/> 

                <h4>必要語学レベルとは</h4>
                Skilled Level O, A　→　CLB7レベル以上（英語・フランス語）、IELTSの場合、 6.0以上<br/> 
                Skilled Level B　→　CLB5レベル以上（英語・フランス語）、IELTSの場合、 5.0以上(reading 4.0以上)<br/> 
            </p>
        </>
      );
    }
  }