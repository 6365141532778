import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";
import { FacebookProvider, Page, EmbeddedPost    } from 'react-facebook';

import title_home from './img/title_home.png';
import workingwoman from './img/workingwoman.jpg';

export default class chinese_home extends Component {

  render() {
    return (
      <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>亚历山大移民事务所ABIC Immigration</title>
            <meta name="description" content="
            加拿大 移民/永居｜签证、就业、创业、投资和移民生活的全方位支持 
            请联系ABIC办理工作签证、家庭团聚移民、技术/创业移民、企业家，投资移民等。
            " />
          </Helmet>
          <span class="cn-part">
            <img src={title_home} className="content-pic full-width" /> 
            <div className="content-bg-blue">
            亚历山大移民事務所-ABIC是加拿大政府认证的加拿大移民代理（MEI YU / ICCRC
            R421895），协同加拿大律师和金融，地产，会计师等各行业的专业人士，根据您的需求
            提供专业和细致的服务。
            </div>
            </span>
            <div class="width40 float_right">
                <img src={workingwoman} className="content-pic"/> 
              </div>
            <span class="cn-part">
            <div className="content">
              <p>在ABIC亚历山大加拿大移民事务所，加拿大政府认证的移民代理（ICCRC会员）和顾问将协助您处理所有加拿大移民（永久居留/移民）和签证程序。</p>
              <p>加拿大移民法更新频繁，处理方法和提交的文件因情况而异，互联网上的信息可能是旧的、或不正确的，可能会误导。加拿大移民网站只有英文和法文，很多人担心是否可正确或完整地理解。这就需要专业人士的帮助。</p>
              <p>ABIC亚历山大加拿大移民事务所经验丰富的专业人员，可以以恰当的方式及时地回复您的咨询。除了咨询加拿大移民（永久居民/移民）和获得各种签证，我们还可以咨询申请被拒、入境被拒、逾期居留等各种疑难问题，并提出解决方案。</p>
              <p>对于移民加拿大的申请人来说，遇到一个真正可靠的代理人是最重要的。ABIC亚历山大加拿大移民事务所 提供细致而又免费服务，例如首次免费咨询、免费电子邮件评估表和每月免费研讨会，以帮助您熟悉我们的服务。希望您在仔细研讨和调查后最终选择ABIC亚历山大加拿大移民事务所。</p>
            </div>
            <br/>
            </span>

          <div className="facebook_page">
            <FacebookProvider appId="148521673491558">
              <Page href="https://www.facebook.com/ABIC-Immigration-Consulting-102846402001790" tabs="timeline" width="1000" />              
            </FacebookProvider>  

              <div id="fb-root"></div>
              <div class="fb-page" data-href="https://www.facebook.com/AbicImmigration" data-tabs="timeline" data-width="1000" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"></div>
              <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=148521673491558&autoLogAppEvents=1" nonce="fWDg5uHs"></script>
          </div>
          <br/>
          <span class="cn-part">
                <div className="content-bg-blue">
                  美国移民加拿大漫谈
                </div>
                <div class="youtube">
                  <iframe width="100%" height="auto" src="https://www.youtube.com/embed/GyKkR8KcDU4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </span>
    </>
    );
  }
}