import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

export default class immigration_and_visa_canada_skilled_trades extends Component {
  render() {
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【トレード移民】</title>
            <meta name="description" content="
            ● 各職業(NOC)レベルに求められた語学ポイントに満たすこと（speaking and listeningはCLB５もしくは以上、reading and writingはCLB４もしくは以上）
            ● 申請日より過去の５年のうち、最低２年フルタイム（若しくは相当するパートタイム）スキルドトレード職業(Skilled Trade)による就労
            " />
          </Helmet>
            <h2>トレード移民<span> (Skilled Trades)</span></h2>
            <div className="content-bg-blue">
                基本条件
            </div>
            <p>
                ● 各職業(NOC)レベルに求められた語学ポイントに満たすこと（speaking and listeningはCLB５もしくは以上、reading and writingはCLB４もしくは以上）
            </p>
            <p>
                ● 申請日より過去の５年のうち、最低２年フルタイム（若しくは相当するパートタイム）スキルドトレード職業(Skilled Trade)による就労
            </p>
            <p>
                ● 職歴経験は職種リスト(Canadian National Occupational Classification)の中に、スキルドワーカー職業によるものです。下記の職業グループを参考ください。<br/> 
                Major Group 72, industrial, electrical and construction trades<br/> 
                Major Group 73, maintenance and equipment operation trades<br/> 
                Major Group 82, supervisors and technical jobs in natural resources, agriculture and related production<br/> 
                Major Group 92, processing, manufacturing and utilities supervisors and central control operators<br/> 
                Minor Group 632, chefs and cooks<br/> 
                Minor Group 633, butchers and bakers<br/> 
            </p>
            <p>
                ● １年以上フルタイムのジョブオファー又は連邦・州政府が発行するスキルドトレード証書を所持すること
            </p>
            <p>
                ● 資金証明
            </p>
            <table className="bglb">
                <tr>
                    <th width="40vw">申請者本人を含む 家族の合計人数</th>
                    <th width="60vw">求められる資金 （カナダドル）</th>
                </tr>
                <tr>
                    <td>1</td>
                    <td>$12,960</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>$16,135</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>$19,836</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>$24,083</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>$27,315</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>$30,806</td>
                </tr>
                <tr>
                    <td>7 又は以上</td>
                    <td>$34,299</td>
                </tr>
                <tr>
                    <td>家族メンバー１名追加</td>
                    <td>$3,492</td>
                </tr>
            </table>
            <p>2020年現在。金額のアップデートは移民局サイトをご確認下さい。</p>
        </>
      );
    }
  }