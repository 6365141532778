import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

export default class company_info_step_for_applying extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【申請のステップ】</title>
            <meta name="description" content="
                十分なカウンセリングを通して、お客様の履歴を分析し、移民・移住専門家と専属弁護士によって、移民・移住資格の可能性を審査します。これは申請に当たって、もっとも重要なファーストステップです。
            " />
          </Helmet>
            <h2>申請のステップ</h2>
            <div className="content">
                <p>
                十分なカウンセリングを通して、お客様の履歴を分析し、移民・移住専門家と専属弁護士によって、移民・移住資格の可能性を審査します。これは申請に当たって、もっとも重要なファーストステップです。
                </p>
            </div>
            <div className="content-bg-border">
                ご契約
            </div>
            <p>
                可能性が高い申請者のみを引き受け、代理契約を結びます。契約はお互いの責任と義務を果たす約束であり、ABIC社は厳格に契約を守り、お客様にプロのサービスを提供します。と同時に、お客様から様々の協力も必要とされ、一緒に成功へ歩みます。
            </p>
            <div className="content-bg-border">
                書類準備
            </div>
            <p>
                事業移民への申請書類はかなりの量で、内容も専門的です、また、移民審査も年々厳しくなり、単なる事務処理的な申請では成功は困難と思われます。ABIC社はプロの専門家がお客様に必要な書類準備を指導し、完全なパッケージで移民官によい第一印象を残します。
            </p>
            <div className="content-bg-border">
                訪問視察
            </div>
            <p>
                ファイルナンバー、書類審査を待ちの前後、お客様が一度カナダへ訪問することを薦めます。カナダの文化、経済、社会、環境などを肌で直感してみることができ、移民成功の可能性も高めます。もし、カナダ市場を開拓する計画があれば、ABIC社は市場調査、投資プロジェクト、会社の設立、顧問顧問弁護士などのサービスが提供できます。
            </p>
            <div className="content-bg-border">
                面接及びリハーサル
            </div>
            <p>
                移民局は書類審査を合格した申請者に、面接通知を送ってきます。そのため、当社は専門コンサルタントによる事前の面接リハーサルを行います。申請者が移民官の質問に流暢に答えられるよう、当社は十分にお客様をトレーニングします。ご希望によって、有料にて弁護士による面接の同行も可能です。
            </p>
            <div className="content-bg-border">
                永住認可
            </div>
            <p>
                面接をパスした後、移民局の指示通りに、指定されたカナダの銀行に投資を行います。ビザが発行されるまで、健康診断、身分調査などを完了させなければいけないが、ABIC社はそれについての書類準備、提出を丁寧にサポートします。
            </p>
            <div className="content-bg-border">
                移民・移住生活
            </div>
            <p>
                永住ビザが移民局からABICに送られ、申請者に渡されます。空港で移民手続きを終え、移住者が安心にカナダで生活できるように、ABIC社は空港迎えサービス、住居探し、住民登録、医療保険、銀行口座などの生活面をサポートします。また、ビジネスを始める方には、必要な情報を提供し、当社と提携する公認会計士が税務問題を丁寧に指導します。
            </p>
        </>
      );
    }
  }