import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_stu2 from './img/education_and_abroad_to_study/im_stu2.jpg';

export default class education_and_abroad_to_study extends Component {
  render() {
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【教育・留学】</title>
            <meta name="description" content="
                カナダの教育は各州政府によって管轄、実施されています。
                カナダの義務教育は州により若干異なりますが、通常6ないし7歳から15ないし16歳までとなっています。カナダでは無料又は税金免除といった義務教育制度が多く、高い教育が確保されています。
            " />
          </Helmet>
            <h2>教育・留学</h2>
            <div className="content-bg-blue">
                カナダの教育制度
            </div>
            <div  class="float_right">
                <img src={im_stu2} className="content-pic"/> 
            </div>
            <div className="content">
                カナダの教育は各州政府によって管轄、実施されています。<br/>
                カナダの義務教育は州により若干異なりますが、通常6ないし7歳から15ないし16歳までとなっています。カナダでは無料又は税金免除といった義務教育制度が多く、高い教育が確保されています。<br/>
            </div>
            <div className="content-bg-border">
                0歳～4歳
            </div>
            <p>
                初等教育以前の教育機関としてDay Care, Pre-schoolといった保育園に通わせることが可能です。<br/>
                私立と公立の両方がありますが、料金はどちらもほぼ同じです。<br/>
                Day Care, Pre-schoolによっては6歳まで通えるところもあります。<br/>
                <br/>
                費用は週に3～5回通った場合、毎月650ドル～1000ドル前後です。<br/>
                但し、低所得家族に該当する場合、州政府から75％～85％も援助されますので、大変助かります。<br/>
            </p>
            <div className="content-bg-border">
                5歳
            </div>
            <p>
                小学校に付属された幼稚園（キンダーガーデン）に入学することができます。年齢の条件として、その年の１２月３１日までに５歳になるのであれば、９月から入学できます。<br/>
                通常、バンクーバー市内の場合、学校への申し込みは前年度の１１月から入学年度の１月末までの受付となります。また、幼稚園を含め、小学校、中学校、高校の申し込みは直接居地域のキャッチメントの学校にします。<br/>

                他のエリアの方（バーナビ、ノースバンクーバー、リッチモンド等）は申し込み時期や方法が異なりますので、それぞれのスクールボードのウェブサイトをご確認ください。<br/>
                尚、バンクーバー在住の方は<a href="http://www.vsb.bc.ca/kindergarten">Vancouver School Board</a>サイトをご参考下さい。<br/>
            </p>
            <div className="content-bg-border">
                6歳～12歳（K7）
            </div>
            <p>
                日本の小学校に当たるエレメンタリスクール（Elementary/Primary School）に通うことになります。BC州ではほとんどの小学校はグレード１からグレード７までとなります。公立校は授業料がかかりませんが、私立校の場合、年間授業料は１万2千ドル前後になります。但し、外国人留学生の場合、公立校でも私立校とほぼ同じ授業料がかかります。<br/>
                <br/>

                バンクーバーの公立小学校にを外国人留学生として入学させる場合は、バンクーバー教育委員会の<a href="http://intered.vsb.bc.ca/brochures/japanese">日本語サイト</a>もご参考下さい。<br/>
                <br/>

                BC州では毎年、州内のエレメンタリースクールの成績レポート、ランキングが作成され、公式に発表されます。これらの資料は学校を選択する際の参考になります。<br/>
            </p>
            <div className="content-bg-border">
                13歳～18歳
            </div>
            <p>
                日本の中学校、高校に当たるセカンダリースクール（Secondary School）、ハイスクール(High School)は通常、グレート８から１２までとなります。<br/>

                授業料、申込、規定等はほぼ小学校と同じです。 エレメンタリースクール同様、セカンダリースクールも成績レポート、ランキングもが同じく毎年統計作成され、毎年公式に発表されます。<br/>
            </p>
            <div className="content-bg-border">
                18歳以上
            </div>
            <p>
                高校を卒業してからの進学先としては、大学(University)、カレッジ(College)、専門高等教育機関(Institute)などPost Secondary Schoolが考えられます。これらの教育機関では外国からの留学生も積極的に受入れています。<br/>
                BC州には、１１の大学、１１のカレッジと３つの専門高等教育機関があります。<br/>
            </p>

            <div className="content-bg-border">
                大学
            </div>
            <p>
                カナダの大学へ進学する為の入学試験は要ありませんが、高校での成績と州政府が実施するテストの結果を元に入学資格が審査されます。日本から総合大学へ直接進学する場合、大学により異なりますが優秀な成績で高校を卒業していることと、英語能力の証明(ＴＯＥＦＬテストの点数など)が求められます。<br/>
            </p>
            <a href="http://www.capilanou.ca/home.html">Capilano University</a><br/>
            <a href="http://www.ecuad.ca/">Emily Carr University of Art and Design</a><br/>
            <a href="http://www.kwantlen.bc.ca/home.html">Kwantlen Polytechnic University</a><br/>
            <a href="http://www.royalroads.ca/">Royal Roads University</a><br/>
            <a href="http://www.sfu.ca/">Simon Fraser University</a><br/>
            <a href="http://www.tru.ca/">Thompson Rivers University</a><br/>
            <a href="http://www.ubc.ca/">University of British Columbia</a><br/>
            <a href="http://www.ufv.ca/">University of the Fraser Valley</a><br/>
            <a href="http://www.unbc.ca/">University of Northern British Columbia</a><br/>
            <a href="http://www.uvic.ca/">University of Victoria</a><br/>
            <a href="http://www.viu.ca/">Vancouver Island University</a><br/>

            <div className="content-bg-border">
                カレッジ
            </div>
            <p>
                カレッジの位置づけとして、職業訓練、大学への編入、大人向けの基礎教育の三つが上げられます。<br/>
                コースの期間は１～3年ですが、専門資格だけを取得する数ヶ月のコースもあります。<br/>
            </p>
            <a href="http://camosun.ca/">Camosun College</a><br/>
            <a href="https://cnc.bc.ca/">College of New Caledonia</a><br/>
            <a href="http://www.cotr.bc.ca/">College of the Rockies</a><br/>
            <a href="http://www.douglas.bc.ca/home.html">Douglas College</a><br/>
            <a href="http://www.langara.bc.ca/">Langara College</a><br/>
            <a href="http://www.okanagan.bc.ca/site15.aspx">Okanagan College</a><br/>
            <a href="https://www.nic.bc.ca/">North Island College</a><br/>
            <a href="http://www.nlc.bc.ca/">Northern Lights College</a><br/>
            <a href="http://www.nwcc.bc.ca/">Northwest Community College</a><br/>
            <a href="http://selkirk.ca/">Selkirk College</a><br/>
            <a href="http://www.vcc.ca/">Vancouver Community College</a><br/>

            <div className="content-bg-border">
                専門高等教育機関
            </div>
            <p>
                高度な技術関連分野において専門技術を学ぶことができます。<br/>
            </p>
            <a href="http://www.bcit.ca/">British Columbia Institute of Technology</a><br/>
            <a href="http://www.jibc.ca/">Justice Institute of B.C.</a><br/>
            <a href="http://www.nvit.bc.ca/">Nicola Valley Institute of Technology</a><br/>
        </>
      );
    }
  }