import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import logo2 from './img/logo2.gif';

export default class index_en extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC-Consulting</title>
            <meta name="description" content="
                Professionals of ABIC accumulated more than seven years of specialized experiences in Canadian immigration field, has assist over thousand immigration & Visa cases. □ Vancouver Based consulting firm.
            " />
          </Helmet>
            <h2>Alexander Business Investing Consults</h2>
            <div class="float_center">
              <img src={logo2} className="content-pic"/> 
            </div>

             <div className="content-bg-blue">Who are we?</div>
             <div className="content-bg-border">
                Immigration Professional Service since 1998
            </div>
            <p>
                Professionals of ABIC accumulated more than seven years of specialized experiences in Canadian immigration field, has assist over thousand immigration & Visa cases.
            </p>
            <div className="content-bg-border">
                Vancouver Based consulting firm
            </div>
            <p>
                The Head office of ABIC is locate in Vancouver, Canada. Our correspondence offices in Shanghai and Beijing are able to assist the client contact to us conveniently.
            </p>
            <div className="content-bg-border">
                Members of CSIC
            </div>
            <p>
                ABIC do have in-house Licensed Immigration consultant (Mei Yu, CSIC ID#M052713). <a href="https://www.csic-scci.ca/">CSIC website</a><br/>
                Our professional Immigration consulting service will meet your satisfaction.
            </p>
            <div className="content-bg-border">
                Law firm association
            </div>
            <p>
            ABIC is an association of William N. King Law Firm and also co-operate with the other Law offices in different location in Canada and China.
            </p>


            <div className="content-bg-blue">What we do?</div>
            <div className="content-bg-border">
                Immigration, Student Permit, Work Permit, Visitor Visa
            </div>
            <p>
                ABIC exclusively focuses on PNP, Skilled worker and family sponsored Immigration, Work and Student Visa, and Canadian Business Consultation.
            </p>
            <div className="content-bg-border">
                Business and Investment consulting services
            </div>
            <p>
                Investment & Entrepreneur Immigration is our expertise. ABIC is able to offering feasibility study, business plan, Incorporation, accounting, marketing and management service.
            </p>
            <div className="content-bg-border">
                Settlement services
            </div>
            <p>
                ABIC is able to offer a full range of settlement services to clients. Our services include airport pick-up, accommodation, automobile, banking, schooling, job search, tax return, etc.
            </p>

            <div className="content-bg-blue">WHere to find us?</div>
            <p>
            <table className="bglb">
                <tr>
                    <th width="20%">By An Appointment</th>
                    <td width="60%">604-688-1956 (Terry)</td>
                </tr>
                <tr>
                    <th>Inquiry</th>
                    <td>
                        E-mail: <a href="mailto:japan@abicanada.com">abic@ABICanada.com</a><br/>
                    </td>
                </tr>
                <tr>
                    <th>Address</th>
                    <td>
                        United Kingdom Building Suite#1617 - 409 Granville,<br/>
                        Vancouver, BC, Canada V6C 1T2
                    </td>
                </tr>
                <tr>
                    <th>Website</th>
                    <td><a href="/">www.ABICanada.com</a> (English and Japanese)</td>
                </tr>
            </table>
            </p>
        </>
      );
    }
  }