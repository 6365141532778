import React, { Component } from 'react';
import './App.css';

export default class link extends Component {
  render() {
      return (
        <>
            <h2>情報リンク集サイト</h2>
            <div className="content-bg-blue">
                移住生活サポート会社
            </div>
            <p>
                以下は全て日本語によるプロフェショナルのサービスを提供できる会社又は専門家です。
            </p>
            <div className="content-bg-border">
                不動産（家の賃貸＆購入）
            </div>
            <p>
                <a href="http://www.jmurakami.com/">ポイントグレイ・プランニング（サットングループ）</a><br/>
                1990年不動産にライセンス取得し、カナダ最大大手不動産会社、サットングループに所属。
                代表取締役の村上丈二氏はメダリオンクラブ賞など受賞多数の不動産スペシャリスト。
            </p>
            <div className="content-bg-border">
                旅行会社 
            </div>
            <p>
                <a href="http://www.iace-canada.com/">IACEトラベル</a><br/>
                北米最大の支店数を誇るIACE トラベルに。カナダにはバンクーバーとトロントに支店を持ち、
                現地の旅行を日本語でサポート。
            </p>
            <div className="content-bg-border">
                金融投資
            </div>
            <p>
                <a href="http://dir.rbcinvestments.com/chris.kwok">RBC Dominion Securities （RBCファイナンシャルグループ 証券部）</a><br/>
                カナダ最大の銀行、子会社の証券部。バンクーバー本店ではベテランの日本人アドバイザーによる資産・預金管理のサービスが利用でき、高金利の預金のほか、為替のレートなどについてもアドバイスが受けれる。<br/>
                <br/>
                <a href="https://www.freedom55financial.com/">Freedom 55 Financial</a><br/>
                バンクーバー最大のファイナンシャル・アドバイザー・グループ。ファイナンシャル・プランニングを中心に、必要なRRSP,RESP,TFSA,投資信託(Mutual funds, Segregated funds)、住宅ローン、生命保険、重大疾病保険、所得保障保険などを扱っている。<br/>
                <br/>
                <a href="http://freedom55.exblog.jp/i8/">Freedom 55 Financialブログ：バンクーバーでファイナンシャル・プランニング</a><br/>
            </p>
            <div className="content-bg-border">
                語学学校（英会話）
            </div>
            <p>
                <a href="http://www.vec.ca/">Vancouver English Centre</a><br/>
                世界３０か国以上から多国籍の学生が集まる大規模の語学学校。講師全員が大学卒のカナダ人でTEFLまたはTESLの資格を持っている。<br/>
                <br/>
                <a href="http://www.efjapan.co.jp/ryugaku/canada/">EFのカナダ留学コース</a><br/>
                実績40年を誇るEFのカナダ留学プログラムでは、長期留学や短期留学をはじめ、高校交換留学、大学院・MBA・大学進学準備、インターンシップなど、様々なコースを提供している。<br/>
                <br/>
                <a href="http://www.kgic.ca/">King George International College</a><br/>
                海外生活に必要な英語の基本を勉強できる語学学校。趣味や旅行などにも役に立つ英会話など、
                目的に合わせてプログラムが選べる。英語初級者から入学可能。<br/>
            </p>
            <div className="content-bg-border">
                私立専門学校
            </div>
            <p>
            <a href="http://www.gbcol.ca/%22">Gas Town Business College</a><br/>
            実際のビジネス環境の中で実戦に使える英語を特化コース、インターンシップも付いている。<br/>
            <br/>
            <a href="http://sprottshaw.com/en_CA/">Sprott-Shaw Community College</a><br/>
            100年以上の歴史を持つ専門学校の老舗で、短大の学位コースもある。看護、幼児教育のディプローマーコースも人気。

  
            </p>
            <div className="content-bg-border">
                留学エージェント
            </div>
            <p>
            <a href="http://www.canadajournal.com/">カナダジャーナル</a><br/>
            サイトではカナダの情報を満載。短期から長期の留学プランもある。<br/>
            <br/>
            <a href="http://www.clubhousecanada.com/">ワーキングホリデーをサポート１３年、独自の英会話クラブが魅了。</a><br/>
            ワーキングホリデーをサポート１３年、独自の英会話クラブが魅了。<br/>
            <br/>
            <a href="http://www.abroadcanada.com/">アブロードカナダ</a><br/>
            アットホームな現地オフィスで「無料」サポートを充実。<br/>
            <br/>
            <a href="http://www.chibicanada.com/">ちびカナダ</a><br/>
            信頼と評判のカナダ無料留学センター<br/>
            <br/>
            <a href="http://www.icanada.jp/">I Canada留学</a><br/>
            学校案内、生活相談、ビザ相談など、ベテランカウンセラーに安心してお任せ ください。<br/>
            <br/>
            <a href="http://www.eastwestcanada.jp/">East-West 留学</a><br/>
            バンクーバー・トロントの両都市、そして日本にもオフィスを構える。<br/>
            <br/>
            <a href="http://www.clubhousecanada.com/">Club-House (日加センター)</a><br/>
            英会話クラブ、の他、住宅情報、生活情報など、移住の様々な面においてのサポートを提供。<br/>
            <br/>
            <a href="http://www.jirikiryugaku.com/">自力留学.com</a><br/>
            カナダなどへ語学留学やワーキングホリデーしたい人を無料で応援する情報・体験談。<br/>
            <br/>
  
            </p>
            <div className="content-bg-border">
                医療機関
            </div>
            <p>
            <a href="http://www.transmedcanada.com/">メインランドクリニック</a><br/>
            好評の日本語医療通訳センター。日本人スタッフが多数在籍で安心。<br/>
            </p>
            <div className="content-bg-border">
                海外保険
            </div>
            <p>
            <a href="http://www.biis.ca/">ブリッジス・インターナショナル</a><br/>
            日本語でサポートが受けれる保険代理店。 1988年に創立され、現在では支店の数が50を超えるBC州最大の独立系保険代理店グループ。移住者、ワーホリ、留学生向けの保険の取り扱いもある。
            </p>
            <div className="content-bg-border">
                日本語学校
            </div>
            <p>
            <a href="http://www.yano.bc.ca/">矢野アカデミー</a><br/>
            1994年にバンクーバーに設立された日本語学校。「日本語教師養成講座」や「ビジネス敬語講座」などを行っている。
            </p>
            <div className="content-bg-border">
                関連機関
            </div>
            <a href="http://www.cic.gc.ca/english/index.asp">カナダ移民局</a><br/>
            <a href="http://www.canadainternational.gc.ca/seattle/index.aspx?lang=eng">在シアトル　カナダビザオフィス</a><br/>
            <a href="http://www.canadanet.or.jp/i_v/index.shtml">在日カナダ大使館(東京ビザオフィス)</a><br/>
            <a href="http://www.hrsdc.gc.ca/">カナダ人材開発省（HRSDC）</a><br/>
            <a href="http://www.vancouver.ca.emb-japan.go.jp/index_j.htm">在バンクーバー日本国総領事館</a><br/>
            <a href="http://www.jawhm.or.jp/">日本ワーキングホリデー協会</a><br/>
            <div className="content-bg-border">
                お役立ちサイト
            </div>
            <a href="http://studyincanada.ciao.jp/">カナダ留学情報サイト Study in Canada</a><br/>
            <a href="http://www.e-maple.net/">カナダ総合情報サイト e-Maple.net</a><br/>
            <a href="http://www.infocanada.net/">インフォカナダネット</a><br/>
            <a href="http://www.peer-net.net/j/list.html">ピアねっと（日本語ヘルプ電話リスト）</a><br/>
            <a href="http://www.ryugakuagent.com/">留学エージェント.com</a><br/>
            <a href="http://www.v-shinpo.com/">バンクーバー新報</a><br/>
            <a href="http://www.oopsweb.com/">バンクーバーの生活・遊び情報誌 OOPS!</a><br/>
            <a href="http://www.loopp.com/">バンクーバー情報サイト Loopp.com</a><br/>
            <p style={{margin:"0 0 0 20px"}}>
                <a href="http://www.loopp.com/01/canadainfo/index03b.php/#top">医療と社会保障</a><br/>
                <a href="http://www.loopp.com/01/canadainfo/marriage.php/#top">カナダで結婚</a><br/>
                <a href="http://www.loopp.com/01/canadainfo/index11.php/#top">バンクーバーの観光地</a><br/>
            </p>
            <a href="http://www.canadajournal.com/">カナダジャーナル</a><br/>
            <a href="http://www.thefraser.com/">ふれいざー</a><br/>
        </>
        );
    }
}