import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import contact_us_pic from './img/contact_us.jpg';

export default class contact_us extends Component {

  render() {
    return (
      <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC Immigration【Contact us】</title>
            <meta name="description" content="
                
            " />
          </Helmet>
          <span class="en-part">
          <h2>Contact us</h2>
          <p>
            <div className="content-bg-blue">
              Contact information
            </div>
            Monday to Friday: 9:00am – 5:30pm<br/>
            ABIC Immigration Consulting<br/>
            409 Granville Street, Suite 1617<br/>
            Vancouver, B.C., Canada V6C 1T2<br/>
            Phone: 1-604-688-1956<br/>
            E-mail: abic@ABICanada.com<br/>
          </p>
          <p>
                <table width={"100%"}>
                        <tr className="colored">
                            <td width={"120px"}>
                                <b>
                                  By Appointment
                                </b>
                            </td>
                            <td  width="auto">
                                604-688-1956 (Carolyn / Terry)
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>
                                  Inquiry by email
                                </b>	
                            </td>
                            <td>
                              <a href="mailto:abic@abicanada.com">abic@abicanada.com</a>
                            </td>
                        </tr>
                        <tr className="colored">
                            <td>
                                <b>
                                  Address
                                </b>
                            </td>
                            <td>
                              United Kingdom Building<br/>
                              Suite#1617 - 409 Granville,<br/>
                              Vancouver, BC, V6C 1T2 Canada
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Website</b>
                            </td>
                            <td>
                                www.ABICanada.com
                            </td>
                        </tr>
                    </table>
                </p>
          <p>
            <div className="content-bg-blue">
            Access and Location
            </div>
              Skytrain: Waterfront station 0-minute walk<br/>
              Bus: #135, #160, #190, (on W. Hastings St.) 0-minute walk<br/>
              #4,#6,#7,#17, #50, (On Granville St.) 2-minutes walk<br/>
              #5,#19,#22,N22, (On Pender St.) 3-minutes walk<br/>
              Parking: 443 Seymour St. Vancouver<br/>
          </p>
          <div className="googleMap">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5204.976126544984!2d-123.113636!3d49.286097!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486717881504227%3A0x7f3d7b8a00251b70!2s409%20Granville%20St%2C%20Vancouver%2C%20BC%20V6C%201T2%2C%20Canada!5e0!3m2!1sen!2sus!4v1606472191146!5m2!1sen!2sus" width="600" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe><br/>
          </div>
          <br/>
          <img src={contact_us_pic} className="content-pic full-width"/>        
          </span>
    </>
    );
  }
}