import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import immigration_services0 from './img/Immigration&Visa/immigration_visa0.jpg';
import immigration_services1 from './img/Immigration&Visa/immigration_visa1.jpg';
import immigration_services2 from './img/Immigration&Visa/immigration_visa2.jpg';
import immigration_services3 from './img/Immigration&Visa/immigration_visa3.jpg';


export default class immigration_services extends Component {

  render() {
    return (
      <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC Immigration【Immigration &amp; Visa】</title>
            <meta name="description" content=""
                
             />
          </Helmet>
          <span class="en-part">
            <h2>
              Immigration &amp; Visa
            </h2>
            <div class="float_right">
              <img src={immigration_services0} className="content-pic width_200px"/> 
            </div>
            <p>
              We are dedicated to find the right immigration program for those who wish to secure their permanent resident
              and business establishment. Besides the global citizenship program, we also provide a wide range of Canadian
              immigration consulting services for all visa types and immigration categories, and professional business
              consultation that may help your entrepreneur immigration applications. We will help you analyze all the
              programs and choose the most suitable one for you and your family.
            </p>
            <div className="content-bg-blue">
              Permanent Residence
            </div>
            <div class="float_right">
              <img src={immigration_services1} className="content-pic width_200px"/> 
            </div>
            <div className="content">
              Business Immigration
            </div>
            <p>
                  <b>● PNP Entrepreneur</b> – Each province and territory have its own stream and
                  requirements for business people. These applicants can only apply the
                  permeant residence after being nominated by a Canadian province or
                  territory.
                  <br/>
                  <br/>
            </p>
            <p>
                  <b>● Start-up Visa</b> – This program targets entrepreneurs with the skills and potential to build business in
                  Canada that are innovative, can create jobs for Canadians, and can compete on a global scale.
            </p>
            <p>
                  <b>● Self-employed</b> – To immigrate as a self-employed person, the applicant must have relevant experience
                  in cultural or athletic activities, and be willing and able to make a significant contribution to the cultural
                  or athletic life of Canada.
            </p>
            <div className="content">
              Skills Immigration
            </div>
            <p>
                  <b>● Provincial nominees</b> – Each province and territory have its own stream and requirements for skilled or
                  semi-skilled worker. These applicants can only apply the permeant residence after being nominated by a
                  Canadian province or territory.
            </p>
            <p>
                  <b>● Express Entry</b> – Express Entry is an online system for skilled workers, and there are three programs.
                  Canadian Experience Class is for the applicants who have Canadian work experience gained in the 3
                  years before apply. Federal Skilled Worker Program is for the applicants who have foreign work
                  experience and meet criteria for education and other factors. Federal Skilled Trades Program is for the
                  applicants who are qualified in a skilled trade with a valid job offer or a certificate of qualification.
            </p>
            <p>
                  TR to PR Pathway – This temporary resident (TR) to permanent resident (PR) pathway is a limited-time
                  pathway to permanent residence. It is for certain temporary residents who are currently working in Canada and
                  their families.
            </p>
            <p>
                  Caregivers - Immigrate by providing care for children, the elderly or those with medical needs, or work as a
                  live-in caregiver
                  <br/>
                  Family Sponsorship - Sponsor your relatives, including your spouse, partner, children, parents, grandparents,
                  and others to immigrate to Canada.
            </p>
            <br/>
            <div className="content-bg-blue">
              Temporary Residence
            </div>
            <div class="float_right">
              <img src={immigration_services2} className="content-pic width_200px"/> 
            </div>
            <p>
              <b>Visit</b> - Come to Canada as a traveller, business visitors or reunite with
              family members, or extend your stay in Canada.
            </p>
            <p>
              <b>Study</b> - After being accepted by a learning institution in Canada, you must prove that you have enough money
              to pay for your tuition fees, living expenses, rent and return transportation. You must satisfy an immigration
              officer that you will leave Canada at the end of your authorized stay.
            </p>
            <p>
              <b>LMIA</b> – A Labour Market Impact Assessment (LMIA) is a document that an employer in Canada may need to
              get before hiring a foreign worker. A positive LMIA will show that there is a need for a foreign worker to fill
              the job. It will also show that no Canadian worker or permanent resident is available to do the job.
            </p>
            <p>
              <b>Work</b> - After received a job offer from a Canadian employer in a qualifying occupation, and a positive Labour
              Market Impact Assessment (your employer must do this), you must show that you have enough money to
              support yourself and any accompanying family members for the duration of your stay in Canada; and satisfy an
              immigration officer that you will leave Canada at the end of your authorized period;
            </p>
            <p>
              <b>International Mobility Program</b> - The International Mobility Program lets the employer hire a temporary
              worker without a Labour Market Impact Assessment (LMIA). If it is the case that the offered work may provide
              broad economic, cultural or other competitive advantages for Canada such as intra-company transferees, or
              provide reciprocal benefits for Canadian citizens and permanent residents, such as working holiday program.
            </p>
            <br/>
            <div className="content-bg-blue">
              Other Services
            </div>
            <div class="float_right">
              <img src={immigration_services3} className="content-pic width_200px"/> 
            </div>
            <p>
              <b>Visa refusal review and reapply</b> –If your visa application is refused, we may
              help you to review the application and advise the possibility of reapply. In
              many cases, the applicant simply couldn’t understand the immigration policy. We have many successful
              experiences with reapplying.
            </p>
            <p>
              <b>Canadian PR Card Renewal</b> - You will need a valid PR card to return to Canada by plane, train, bus or boat.
              To keep your permanent resident status, you must have been in Canada for at least 2 out of 5 years (730 days).
              These 730 days don’t need to be continuous. Some of your time abroad may count towards the 730 days.
            </p>
            <p>
             <b>Canadian Citizenship</b> - To become a Canadian citizen, the permanent residents must have lived in Canada for
              at least 3 out of 5 years (1095 days), filed their taxes, passed a citizenship test, and prove their language skills.
            </p>
            <p>
              <b>Global citizenship Program</b> - There are many citizenship investment programs, such as Grenada, Turkey,
              Dominica, St. Kitts &amp; Nevis, etc. Most of these programs require $100,000 to 250,000 USD investments, so that
              the investor and family members have the privilege of acquiring an alternative citizenship, which give them the
              right to settle and travel freely to over 100 countries.
            </p>
          </span>
    </>
    );
  }
}