import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_citizenship00 from './img/citizenship/im_citizenship00.jpg';
import im_citizenship01 from './img/citizenship/im_citizenship01.jpg';
import im_citizenship02 from './img/citizenship/im_citizenship02.jpg';
import im_citizenship03 from './img/citizenship/im_citizenship03.jpg';
import im_citizenship04 from './img/citizenship/im_citizenship04.jpg';
import im_citizenship05 from './img/citizenship/im_citizenship05.jpg';

export default class second_citizenship extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【第二市民権・パスポート取得】</title>
            <meta name="description" content="
            メリット・コスト・投資による市民権
            " />
          </Helmet>
          <h2>第二市民権・パスポート取得</h2>
            <div className="content-bg-blue">
                メリット
            </div>
            <div className="content-bg-border">
                オフショア投資＆節税
            </div>
            <p>
            「非居住者、または非永住者」の立場になれば、源泉課税方式が適用されるので海外で得た運用益や譲渡益は日本の課税対象になりません。
            </p>  
            <p>
              オフショア信託は海外信託という意味で、多くの場合、税率の低いタックスヘイブンの国や地域のことといいます。多くのタックスヘイブンの国・地域のオフショア金融センターは、健全な司法・金融システムを備えており、財産の安全が保証されており、持ち株会社、免除会社、不動産信託、オフショア会社の設立に特に適しています。所得税や相続税を非常に低く（あるいはゼロに）設定しています。オフショア信託で資産を管理しておけば、本来かかるはずの税金を大幅に節約できるのです。
            </p>  
            <div class="float_right">
                    <img src={im_citizenship00} className="img_citizenship0"/> 
                    <img src={im_citizenship01} className="img_citizenship0"/> 
                </div>
            <h4>相続税の速算表</h4>
            <table className="bglb">
                <tr>
                    <th width="40vw">法廷相続分に応ずる取得金額</th>
                    <th width="10vw">税率</th>
                    <th width="20vw">控除額</th>
                </tr>
                <tr>
                    <td>1000万円以下</td>
                    <td>10％</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>3000万円以下</td>
                    <td>15％</td>
                    <td>50万円</td>
                </tr>
                <tr>
                    <td>5000万円以下</td>
                    <td>20％</td>
                    <td>200万</td>
                </tr>
                <tr>
                    <td>1億円以下</td>
                    <td>30％</td>
                    <td>700万円</td>
                </tr>
                <tr>
                    <td>2億円以下</td>
                    <td>40％</td>
                    <td>1700万円</td>
                </tr>
                <tr>
                    <td>3億円以下</td>
                    <td>45％</td>
                    <td>2700万円</td>
                </tr>
                <tr>
                    <td>6億円以下</td>
                    <td>50％</td>
                    <td>4200万円</td>
                </tr>
                <tr>
                    <td>6億円超</td>
                    <td>55％</td>
                    <td>7200万円</td>
                </tr>
            </table>
            <div className="content-bg-border">
                欧米に自由に定住が可能
            </div>
            <p>
              EUパスポートを取得した場合、EU圏所在の国で居住、仕事、リタイヤーは可能となります。アメリカ、カナダ、イギリスなどにも旅行は可能。
            </p>
            <div className="content-bg-border">
                手続きが簡単、成功率は高い
            </div>
            <p>
              ほとんどの場合、数か月から一年程度でパスポートの取得は可能で、居住する制限もありません。申請者に高い条件を設定しておらず、投資のみで、学歴、語学、資産の条件はほとんど不要です。
            </p>
            <div className="content-bg-border">
                子供教育
            </div>
            <p>
              所在国の国民と同等な福利、義務教育を受けられます。大学でも無料もしくは安い学費で通学できます。
            </p>
            <div className="content-bg-blue">
              コスト
            </div>
            <p>
              投資コストは約１０万ＵＳドル～２５万ＵＳドル
            </p>
            <div className="content-bg-blue">
              投資による市民権
            </div>

              <div className="content-bg-border">
                EU（欧州連合）
              </div>
              <div className="citizenship-half">
                <div className="citizenship-half0">
                  <p>
                    IRELAND
                  </p>
                  <p>
                    PORTUGAL
                  </p>
                  <p>
                    CYPRUS
                  </p>
                  <p>
                    MALTA
                  </p>
                  <p>
                    GREECE
                  </p>
                  <p>
                    HUNGARY
                  </p>
                  <p>
                    MONTENEGRO
                  </p>
                  <p>
                    NORTH MACEDONIA
                  </p>
                </div>
                <div className="citizenship-half1">
                    <img src={im_citizenship02} className="img_citizenship1"/> 
                    <img src={im_citizenship03} className="img_citizenship1"/> 
                </div>
              </div>

              <div className="content-bg-border">
                コモンウェルス（英連邦）
              </div>
              <div className="citizenship-half">
                <div className="citizenship-half0">
                  <p>
                    ANTIGUA AND BARBUDA
                  </p>
                  <p>
                    DOMINICA 
                  </p>
                  <p>
                    GRENADA
                  </p>
                  <p>
                    ST KITTS AND NEVIS
                  </p>
                  <p>
                    SAINT LUCIA
                  </p>
                  <p>
                    VANUATU
                  </p>
                </div>
              <div className="citizenship-half1">
                  <img src={im_citizenship04} className="img_citizenship1"/> 
                  <img src={im_citizenship05} className="img_citizenship1"/> 
              </div>
            </div>
        </>
        );
        }
    }