import React, { Component } from 'react';
import './App.css';
import {BrowserRouter, Route, Link, Switch} from 'react-router-dom';
import {Helmet} from "react-helmet";
import NotFound from './pages/Notfound.js';

import { slide as Menu } from 'react-burger-menu'
import Collapsible from 'react-collapsible';

import Home from './pages/home/home.js';
import index_en from './pages/eng_page/index.js';

import chinese_home from './pages/chinese/index.js';
import chinese_about_us from './pages/chinese/about_us.js';
import chinese_immigration_services from './pages/chinese/immigration_services.js';
import chinese_start_up from './pages/chinese/start_up.js';
import chinese_passport_program from './pages/chinese/passport_program.js';
import chinese_contact_us from './pages/chinese/contact_us.js';
import chinese_contact from './pages/chinese/contact.js';
import chinese_seminer_info from './pages/chinese/seminer_info.js';

import english_home from './pages/english/index.js';
import english_about_us from './pages/english/about_us.js';
import english_immigration_services from './pages/english/immigration_services.js';
import english_business_investment from './pages/english/business_investment.js';
import english_passport_program from './pages/english/passport_program.js';
import english_contact_us from './pages/english/contact_us.js';
import english_contact from './pages/english/contact.js';
import english_seminer_info from './pages/english/seminer_info.js';

import immigration_and_visa_canada_business from './pages/immigration_and_visa_canada/business.js';
import immigration_and_visa_canada_skilled_worker from './pages/immigration_and_visa_canada/skilled_worker.js';
import immigration_and_visa_canada_canadian_express_class from './pages/immigration_and_visa_canada/canadian_express_class.js';
import immigration_and_visa_canada_skilled_trades from './pages/immigration_and_visa_canada/skilled_trades.js';
import immigration_and_visa_canada_family_class from './pages/immigration_and_visa_canada/family_class.js';
import immigration_and_visa_canada_provincial_nominee_program from './pages/immigration_and_visa_canada/provincial_nominee_program.js';
import immigration_and_visa_canada_work_permit from './pages/immigration_and_visa_canada/work_permit.js';
import immigration_and_visa_canada_working_holiday from './pages/immigration_and_visa_canada/working_holiday.js';
import immigration_and_visa_canada_study_permit from './pages/immigration_and_visa_canada/study_permit.js';
import immigration_and_visa_canada_visitor_visa from './pages/immigration_and_visa_canada/visitor_visa.js';
import immigration_and_visa_canada_visa_trouble from './pages/immigration_and_visa_canada/visa_trouble.js';

import starting_business_and_investing from './pages/starting_business_and_investing/info.js';

import emigrant_life_merit from './pages/emigrant_life/merit.js';
import emigrant_life_finding_job from './pages/emigrant_life/finding_job.js';
import emigrant_life_education_and_abroad_to_study from './pages/emigrant_life/education_and_abroad_to_study.js';
import emigrant_life_health_care_system from './pages/emigrant_life/health_care_system.js';
import emigrant_life_finding_a_house from './pages/emigrant_life/finding_a_house.js';
import emigrant_life_assets_and_tax from './pages/emigrant_life/assets_and_tax.js';

import company_info_interview from './pages/company_info/interview.js';
import company_info_overview from './pages/company_info/overview.js';
import company_info_step_for_applying from './pages/company_info/step_for_applying.js';
import company_info_business_partnership from './pages/company_info/business_partnership.js';
import company_info_for_corporate_customers from './pages/company_info/for_corporate_customers.js';

import customer_review_info from './pages/customer_review/info.js';

import seminar_info from './pages/info/seminar_info.js';
import second_citizenship from './pages/info/second_citizenship.js';

import link from './link.js';
import contact from './contact.js';

import logo from './img/header/logo.gif';
import logo_cn from './pages/chinese/img/logo_cn.png';
import logo_en from './pages/english/img/logo_en.png';

import banner from './img/header/kv.jpg';
import contact_banner from './img/sidebar/side-contact.png';
import counseling_banner from './img/sidebar/side-counseling.png';
import seminar_banner from './img/sidebar/side-seminar.png';
import facebook_banner from './img/sidebar/side-facebook.png';
import citizen_banner from './img/sidebar/side-citizen.png';
import youtube from './img/sidebar/icon-youtube.png';
import facebook_icon from './img/sidebar/icon-fbook.png';
import twitter from './img/sidebar/icon-twitter.png';

import contact_banner_cn from './pages/chinese/img/sidebar/side-contact-cn.png';
import counseling_banner_cn from './pages/chinese/img/sidebar/side-counseling-cn.png';
import seminar_banner_cn from './pages/chinese/img/sidebar/side-seminar-cn.png';
import facebook_banner_cn from './pages/chinese/img/sidebar/side-facebook-cn.png';
import citizen_banner_cn from './pages/chinese/img/sidebar/side-citizen-cn.png';

import mobile_phone_icon from './img/mobile_menu/phone-icon.png';
import mobile_mail_icon from './img/mobile_menu/mail-icon.png';

export default class App extends Component {

  componentDidMount() {

    // Switch Language part
    var part_position_adding = [];
    var part_position_removing0 = [];
    var part_position_removing1 = [];
    if(window.location.pathname.indexOf('/chinese/') !== -1)
    {
      part_position_adding = document.getElementsByClassName("cn-part");
      part_position_removing0 = document.getElementsByClassName("jp-part");
      part_position_removing1 = document.getElementsByClassName("en-part");

    }else if(window.location.pathname.indexOf('/english/') !== -1)
    {
      part_position_adding = document.getElementsByClassName("en-part");
      part_position_removing0 = document.getElementsByClassName("jp-part");
      part_position_removing1 = document.getElementsByClassName("cn-part");
    }else{
      part_position_adding = document.getElementsByClassName("jp-part");
      part_position_removing0 = document.getElementsByClassName("cn-part");
      part_position_removing1 = document.getElementsByClassName("en-part");
    }

    // display part_position_adding
    if(part_position_adding.length > 0)
    {
      for( var i = 0; i < part_position_adding.length; i++)
      {
        //console.log(part_position_adding[i]);
        part_position_adding[i].classList.remove("hide");
      }
    }

    // hide part_position_removing0
    if(part_position_removing0.length > 0)
    {
      for( var i = 0; i < part_position_removing0.length; i++)
      {
        //console.log(part_position_removing0[i]);
        part_position_removing0[i].classList.add("hide");
      }
    }
    // hide part_position_removing1
    if(part_position_removing1.length > 0)
    {
      for( var i = 0; i < part_position_removing1.length; i++)
      {
        //console.log(part_position_removing1[i]);
        part_position_removing1[i].classList.add("hide");
      }
    }
  }

  showSettings (event) {
    event.preventDefault();
  }

  onClickMM(e) { //Hide Facebook frame when open mobile menu

    var mobile_menu_state = document.getElementsByClassName('bm-menu-wrap')[0].ariaHidden;
    var clicked_target = e.target;

    if(clicked_target.id === "react-burger-menu-btn"
      || clicked_target.id === "react-burger-cross-btn"
      || clicked_target.className === "bm-overlay")
    {
      if(document.getElementsByClassName('fb-page')[0])
      {
        var fb_frame = document.getElementsByClassName('fb-page')[0];
  
        if(mobile_menu_state === "true")
        {
          fb_frame.classList.add("hide")
        }else
        {
          fb_frame.classList.remove("hide")
        }
      }else if(document.getElementsByClassName('youtube')[0])
      {
        var yt_frame = document.getElementsByClassName('youtube')[0];
  
        if(mobile_menu_state === "true")
        {
          yt_frame.classList.add("hide")
        }else
        {
          yt_frame.classList.remove("hide")
        }
      }
    }   
  }

  MobileMenuJP()
  {

    return(
      <div className="mobile_header">
        <a href="/"><img src={logo} className="header-logo-mobile jp-part" alt="logo" /></a> 
        <Menu right>
            <a id="home" className="menu-item menu-item-main" href="/">Home</a>
            <Collapsible className="menu-item menu-item-main " trigger="移民&ビザ">
              <li>
                <a id="immigration_and_visa_canada" className="menu-item" href="/immigration_and_visa_canada/business">ビジネス移民</a>
              </li>
              <li>
                <a id="immigration_and_visa_canada" className="menu-item" href="/immigration_and_visa_canada/skilled_worker">個人移民</a>
              </li>
              <li>
                <a id="immigration_and_visa_canada" className="menu-item" href="/immigration_and_visa_canada/canadian_express_class">カナダ経験移民</a>
              </li>
              <li>
                <a id="immigration_and_visa_canada" className="menu-item" href="/immigration_and_visa_canada/skilled_trades">トレード移民</a>
              </li>
              <li>
                <a id="immigration_and_visa_canada" className="menu-item" href="/immigration_and_visa_canada/family_class">家族移民</a>
              </li>
              <li>
                <a id="immigration_and_visa_canada" className="menu-item" href="/immigration_and_visa_canada/provincial_nominee_program">PNP移民</a>
              </li>
              <li>
                <a id="immigration_and_visa_canada" className="menu-item" href="/immigration_and_visa_canada/work_permit">ワークビザ</a>
              </li>
              <li>
                <a id="immigration_and_visa_canada" className="menu-item" href="/immigration_and_visa_canada/working_holiday">ワーキングホリデー</a>
              </li>
              <li>
                <a id="immigration_and_visa_canada" className="menu-item" href="/immigration_and_visa_canada/study_permit">学生ビザ</a>
              </li>
              <li>
                <a id="immigration_and_visa_canada" className="menu-item" href="/immigration_and_visa_canada/visitor_visa">ビジタービザ</a>
              </li>
              <li>
                <a id="immigration_and_visa_canada" className="menu-item" href="/immigration_and_visa_canada/visa_trouble">ビザトラブル</a>
              </li>
            </Collapsible>
          <a id="starting_business_and_investing" className="menu-item menu-item-main" href="/starting_business_and_investing/info">起業＆投資</a>
          <Collapsible className="menu-item menu-item-main" trigger="移住生活">
            <li>
              <a id="emigrant_life" className="menu-item" href="/emigrant_life/merit">永住メリット</a>
            </li>
            <li>
              <a id="emigrant_life" className="menu-item" href="/emigrant_life/finding_job">仕事探し</a>
            </li>
            <li>
              <a id="emigrant_life" className="menu-item" href="/emigrant_life/education_and_abroad_to_study">教育・留学</a>
            </li>
            <li>
              <a id="emigrant_life" className="menu-item" href="/emigrant_life/health_care_system">医療制度</a>
            </li>
            <li>
              <a id="emigrant_life" className="menu-item" href="/emigrant_life/finding_a_house">住居探し</a>
            </li>
            <li>
              <a id="emigrant_life" className="menu-item" href="/emigrant_life/assets_and_tax">資産・税金</a>
            </li>
          </Collapsible>
          <Collapsible className="menu-item menu-item-main" trigger="会社案内">
            <li>
              <a id="company_info" className="menu-item" href="/company_info/interview">社長インタビュー</a>
            </li>
            <li>
              <a id="company_info" className="menu-item" href="/company_info/overview">会社概要</a>
            </li>
            <li>
              <a id="company_info" className="menu-item" href="/company_info/business_partnership">業務提携</a>
            </li>
            <li>
              <a id="company_info" className="menu-item" href="/company_info/business_partnership">法人のお客様へ</a>
            </li>
            <li>
              <a id="company_info" className="menu-item" href="/company_info/for_corporate_customers">申請のステップ</a>
            </li>
          </Collapsible>
          <a id="customer_review" className="menu-item" href="/customer_review/info">お客様の声</a>
          <a className="menu-item menu-banner" href="/"><img src={contact_banner} className="sidebar-logo"/></a>
          <div className="mobile_menu_icon">
            <a className="menu-item menu-banner" href="tel:6046881956"><img src={mobile_phone_icon} className="sidebar-icon"/></a>
            <a className="menu-item menu-banner" href="mailto:japan@abicanada.com"><img src={mobile_mail_icon} className="sidebar-icon"/></a>
          </div>
          <a className="menu-item menu-banner" href="/contact"><img src={counseling_banner} className="sidebar-logo"/> </a>
          <a className="menu-item menu-banner" href="/info/seminar_info"><img src={seminar_banner} className="sidebar-logo"/></a>
          <a className="menu-item menu-banner" href="https://www.facebook.com/ABIC-Immigration-Consulting-111883600982808" target="blunk"><img src={facebook_banner} className="sidebar-logo"/></a>
          <a className="menu-item menu-banner" href="/info/second_citizenship"><img src={citizen_banner} className="sidebar-logo"/> </a>
          <div  className="sidebar-text-black">
            Follow Us:
            <div className="sns-icon">
              <a href="https://www.youtube.com/channel/UC5P74LqnIIeBc4pX1YJgOWw" target="blunk">
                <img src={youtube} className="sidebar-sns-logo"/> 
              </a>
              <a href="https://www.facebook.com/ABIC-Immigration-Consulting-111883600982808" target="blunk">
                <img src={facebook_icon} className="sidebar-sns-logo"/> 
              </a>
              <a href="https://twitter.com/ABICIMMIGRATION" target="blunk">
                <img src={twitter} className="sidebar-sns-logo"/> 
              </a>
            </div>
          </div>
      </Menu>
    </div>
    );
  };

  MobileMenuCN()
  {
    return(
      <div className="mobile_header">
        <a href="/chinese/"><img src={logo_cn} className="header-logo-mobile cn-part" alt="logo" /></a>
        <Menu right>
          <a id="home" className="menu-item menu-item-main" href="/chinese/">Home</a>
          <a id="home" className="menu-item menu-item-main" href="/chinese/about_us">關於我們</a>
          <a id="home" className="menu-item menu-item-main" href="/chinese/immigration_services">移民服務</a>
          <a id="home" className="menu-item menu-item-main" href="/chinese/start_up">创业咨询</a>
          <a id="home" className="menu-item menu-item-main" href="/chinese/passport_program">護照項目</a>
          <a id="home" className="menu-item menu-item-main" href="/chinese/contact_us">聯係我們</a>
          <a id="home" className="menu-item menu-item-main" href="/chinese/contact">免费咨询表</a>
        <a className="menu-item menu-banner" href="/"><img src={contact_banner_cn} className="sidebar-logo"/></a>
          <div className="mobile_menu_icon">
            <a className="menu-item menu-banner" href="tel:6046881956"><img src={mobile_phone_icon} className="sidebar-icon"/></a>
            <a className="menu-item menu-banner" href="mailto:abic@abicanada.com"><img src={mobile_mail_icon} className="sidebar-icon"/></a>
          </div>
          <a className="menu-item menu-banner" href="/chinese/contact"><img src={counseling_banner_cn} className="sidebar-logo"/> </a>
          <a className="menu-item menu-banner" href="/chinese/chinese_seminer_info"><img src={seminar_banner_cn} className="sidebar-logo"/></a>
          <a className="menu-item menu-banner" href="https://www.facebook.com/%E4%BA%9E%E6%AD%B7%E5%B1%B1%E5%A4%A7%E7%A7%BB%E6%B0%91%E4%BA%8B%E5%8B%99%E6%89%80-ABIC-102846402001790/" target="blunk"><img src={facebook_banner_cn} className="sidebar-logo"/></a>
          <a className="menu-item menu-banner" href="/chinese/passport_program"><img src={citizen_banner_cn} className="sidebar-logo"/> </a>
          <div  className="sidebar-text-black">
            Follow Us:
            <div className="sns-icon">
              <a href="https://www.youtube.com/watch?v=GyKkR8KcDU4" target="blunk">
                <img src={youtube} className="sidebar-sns-logo"/> 
              </a>
              <a href="https://www.facebook.com/ABIC-Immigration-Consulting-111883600982808" target="blunk">
                <img src={facebook_icon} className="sidebar-sns-logo"/> 
              </a>
              <a href="https://twitter.com/ABICIMMIGRATION" target="blunk">
                <img src={twitter} className="sidebar-sns-logo"/> 
              </a>
            </div>
          </div>
          </Menu>
    </div>
    );
  };

  MobileMenuEN()
  {
    return(
      <div className="mobile_header">
        <a href="/english/"><img src={logo_en} className="header-logo-mobile" alt="logo" /></a>
      <Menu right>
        <a id="home" className="menu-item menu-item-main" href="/english/">Home</a>
        <a id="home" className="menu-item menu-item-main" href="/english/about_us">About us</a>
        <a id="home" className="menu-item menu-item-main" href="/english/immigration_services">Immigration & Visa</a>
        <a id="home" className="menu-item menu-item-main" href="/english/business_investment">Business & Investment</a>
        <a id="home" className="menu-item menu-item-main" href="/english/contact_us">Contact us</a>
        <div className="mobile_menu_icon">
          <a className="menu-item menu-banner" href="tel:6046881956"><img src={mobile_phone_icon} className="sidebar-icon"/></a>
          <a className="menu-item menu-banner" href="mailto:abic@abicanada.com"><img src={mobile_mail_icon} className="sidebar-icon"/></a>
        </div>
        <div  className="sidebar-text-black">
          Follow Us:
          <div className="sns-icon">
            <a href="https://www.facebook.com/ABIC-Immigration-Consulting-111883600982808" target="blunk">
              <img src={facebook_icon} className="sidebar-sns-logo"/> 
            </a>
          </div>
        </div>
      </Menu>
    </div>
    );
  };

  render(){
    return (
      <BrowserRouter>
        <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ABIC エービック カナダ移民＆ビザ</title>
          <meta name="description" content="ABIC（エービック）はカナダ政府公認のカナダ移民代理士（MEI YU/ICCRC R421895）・日本人ビザアドバイザーたちが、皆様の様々な状況に応じて親切・丁寧にサポートしております。ワークビザ、家族（結婚）移民、個人移民・企業家移民、投資家移民など、ABICにご相談ください。"
          />
        </Helmet>
          <div className="App-main">
              <div className="App-header">
                <div className="mobile_menu_button jp-part" onClick={this.onClickMM}>
                  {this.MobileMenuJP()}
                </div>
                <div className="mobile_menu_button cn-part" onClick={this.onClickMM}>
                  {this.MobileMenuCN()}
                </div>
                <div className="mobile_menu_button en-part" onClick={this.onClickMM}>
                  {this.MobileMenuEN()}
                </div>
                <div className="App-header0 jp-part">
                  <p>
                    <strong>
                    <a href="">カナダ 移住</a>
                    ・
                    <a href="">移民</a>
                    ｜
                    <a href="">ビザ</a>
                    ・
                    <a href="">就労</a>
                    ・
                    <a href="">起業</a>
                    ・
                    <a href="">投資</a>
                    ・
                    <a href="">永住生活</a>
                    </strong>
                    を トータルサポート
                  </p>
                  <p>
                    ワークビザ、家族（結婚）移民、個人移民・企業家移民、投資家移民など、ABICにご相談ください。
                  </p>
                </div>
                <div className="App-header0 cn-part">
                  <p>
                    <strong>
                    <a href="">加拿大 移民/永居</a>
                    ｜
                    <a href="">签证</a>
                    、
                    <a href="">就业</a>
                    、
                    <a href="">创业</a>
                    、
                    </strong>
                    投资和移民生活的全方位支持
                  </p>
                  <p>
                    请联系ABIC办理工作签证、家庭团聚移民、技术/创业移民、企业家，投资移民等。
                  </p>
                </div>
                <div className="App-header0 en-part">
                  <p>
                    <strong>
                    <a href="">Canada Immigration</a>
                    , 
                    <a href="">Permanent Resident</a>
                    , 
                    <a href="">Employment</a>
                    , 
                    <a href="">Start-up</a>
                    , 
                    <a href="">Investor</a>
                    , 
                    <a href="">life support</a>
                    , 
                    </strong>
                  </p>
                  <p>
                    Please contact ABIC Immigration consulting for work visa, study permit, family class, Entrepreneur, Investor immigration
                  </p>
                </div>
                <div className="App-header1">
                    <div className="left-hand header1-left">
                      <div className="header1-left-content">
                        <img src={logo} className="header-logo jp-part" alt="logo" /> 
                        <img src={logo_cn} className="header-logo cn-part" alt="logo" /> 
                        <img src={logo_en} className="header-logo en-part" alt="logo" /> 
                        <div class="language_buttons">
                          <a href="/">日本語</a>                         
                          <a href="/chinese/">中文</a>
                          <a href="/english/">English</a>
                        </div>
                      </div>
                    </div>
                    <div id="pagetop" className="right-hand header1-right">
                      <img src={banner} className="header-banner" alt="logo" />
                        <ul class="menu jp-part">
                          <li class="menu__single">
                              <Link to="/">Home</Link><br />
                            <ul class="menu__second-level">
                            </ul>
                          </li>
                          <li class="menu__single">
                            <a class="init-bottom">移民&ビザ</a>
                            <ul class="menu__second-level">
                                <li>
                                  <Link to="/immigration_and_visa_canada/business">ビジネス移民<br /></Link>
                                </li>
                                <li>
                                  <Link to="/immigration_and_visa_canada/skilled_worker">個人移民</Link>
                                </li>
                                <li>
                                  <Link to="/immigration_and_visa_canada/canadian_express_class">カナダ経験移民</Link>
                                </li>
                                <li>
                                  <Link to="/immigration_and_visa_canada/skilled_trades">トレード移民</Link>
                                </li>
                                <li>
                                <Link to="/immigration_and_visa_canada/family_class">家族移民</Link>
                                </li>
                                <li>
                                  <Link to="/immigration_and_visa_canada/provincial_nominee_program">PNP移民</Link>
                                </li>
                                <li>
                                  <Link to="/immigration_and_visa_canada/work_permit">ワークビザ</Link>
                                </li>
                                <li>
                                  <Link to="/immigration_and_visa_canada/working_holiday">ワーキングホリデー</Link>
                                </li>
                                <li>
                                  <Link to="/immigration_and_visa_canada/study_permit">学生ビザ</Link>
                                </li>
                                <li>
                                  <Link to="/immigration_and_visa_canada/visitor_visa">ビジタービザ</Link>
                                </li>
                                <li>
                                  <Link to="/immigration_and_visa_canada/visa_trouble">ビザトラブル</Link>
                                </li>
                            </ul>
                          </li>
                          <li class="menu__single">
                            <Link to="/starting_business_and_investing/info">起業＆投資</Link>
                            <ul class="menu__second-level">
                            </ul>
                          </li>
                          <li class="menu__single">
                            <a href="#" class="init-bottom">移住生活</a>
                            <ul class="menu__second-level">
                                <li><Link to="/emigrant_life/merit">永住メリット</Link></li>
                                <li><Link to="/emigrant_life/finding_job">仕事探し</Link></li>
                                <li><Link to="/emigrant_life/education_and_abroad_to_study">教育・留学</Link></li>
                                <li><Link to="/emigrant_life/health_care_system">医療制度</Link></li>
                                <li><Link to="/emigrant_life/finding_a_house">住居探し</Link></li>
                                <li><Link to="/emigrant_life/assets_and_tax">資産・税金</Link></li>
                            </ul>
                          </li>
                          <li class="menu__single">
                            <a href="#" class="init-bottom">会社案内</a>
                            <ul class="menu__second-level">
                                <li><Link to="/company_info/interview">社長インタビュー</Link></li>
                                <li><Link to="/company_info/overview">会社概要</Link></li>
                                <li><Link to="/company_info/business_partnership">業務提携</Link></li>
                                <li><Link to="/company_info/for_corporate_customers">法人のお客様へ</Link></li>
                                <li><Link to="/company_info/step_for_applying">申請のステップ</Link></li>
                            </ul>
                          </li>
                          <li class="menu__single">
                            <Link to="/customer_review/info">お客様の声</Link>
                            <ul class="menu__second-level">
                            </ul>
                          </li>
                        </ul>
                        <ul class="menu cn-part">
                          <li class="menu__single">
                              <Link to="/chinese/">Home</Link>
                          </li>
                          <li class="menu__single">
                              <Link to="/chinese/about_us">關於我們</Link>
                          </li>
                          <li class="menu__single">
                              <Link to="/chinese/immigration_services">移民服務</Link>
                          </li>
                          <li class="menu__single">
                              <Link to="/chinese/start_up">创业咨询</Link>
                          </li>
                          <li class="menu__single">
                              <Link to="/chinese/passport_program">護照項目</Link>
                          </li>
                          <li class="menu__single">
                              <Link to="/chinese/contact_us">聯係我們</Link>
                          </li>
                        </ul>
                        <ul class="menu en-part">
                          <li class="menu__single">
                                <Link to="/english/">Home</Link>
                            </li>
                            <li class="menu__single">
                                <Link to="/english/about_us">About us</Link>
                            </li>
                            <li class="menu__single">
                                <Link to="/english/immigration_services">Immigration</Link>
                            </li>
                            <li class="menu__single">
                                <Link to="/english/business_investment">Business</Link>
                            </li>
                            <li class="menu__single">
                                <Link to="/english/contact_us">Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                  </div>
                </div>
                <div className="body-part">
                <div className="left-hand body sidebar">
                      <div className="body-content jp-part">
                        <a href="mailto:japan@abicanada.com">
                          <img src={contact_banner} className="sidebar-logo"/>
                        </a>
                        <a href="/contact">
                          <img src={counseling_banner} className="sidebar-logo"/> 
                        </a>
                        <Link to="/info/seminar_info">
                          <img src={seminar_banner} className="sidebar-logo"/>
                        </Link>                      
                        <a href="https://www.facebook.com/ABIC-Immigration-Consulting-111883600982808" target="blunk">
                        <img src={facebook_banner} className="sidebar-logo"/> 
                        </a>
                        <Link to="/info/second_citizenship">
                          <img src={citizen_banner} className="sidebar-logo"/> 
                        </Link>  
                        <div  className="sidebar-text-black">
                          Follow Us:
                          <div className="sns-icon">
                            <a href="https://www.youtube.com/channel/UC5P74LqnIIeBc4pX1YJgOWw" target="blunk">
                              <img src={youtube} className="sidebar-sns-logo"/> 
                            </a>
                            <a href="https://www.facebook.com/ABIC-Immigration-Consulting-111883600982808" target="blunk">
                              <img src={facebook_icon} className="sidebar-sns-logo"/> 
                            </a>
                            <a href="https://twitter.com/ABICIMMIGRATION" target="blunk">
                              <img src={twitter} className="sidebar-sns-logo"/> 
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="body-content cn-part">
                        <a href="mailto:abic@abicanada.com">
                          <img src={contact_banner_cn} className="sidebar-logo"/>
                        </a>
                        <a href="/chinese/contact">
                          <img src={counseling_banner_cn} className="sidebar-logo"/> 
                        </a>
                        <Link to="/chinese/chinese_seminer_info">
                          <img src={seminar_banner_cn} className="sidebar-logo"/>
                        </Link>                      
                        <a href="https://www.facebook.com/%E4%BA%9E%E6%AD%B7%E5%B1%B1%E5%A4%A7%E7%A7%BB%E6%B0%91%E4%BA%8B%E5%8B%99%E6%89%80-ABIC-102846402001790/" target="blunk">
                        <img src={facebook_banner_cn} className="sidebar-logo"/> 
                        </a>
                        <Link to="/chinese/passport_program">
                          <img src={citizen_banner_cn} className="sidebar-logo"/> 
                        </Link>  
                        <div  className="sidebar-text-black">
                          Follow Us:
                          <div className="sns-icon">
                            <a href="https://www.youtube.com/watch?v=GyKkR8KcDU4" target="blunk">
                              <img src={youtube} className="sidebar-sns-logo"/> 
                            </a>
                            <a href="https://www.facebook.com/%E4%BA%9E%E6%AD%B7%E5%B1%B1%E5%A4%A7%E7%A7%BB%E6%B0%91%E4%BA%8B%E5%8B%99%E6%89%80-ABIC-102846402001790/" target="blunk">
                              <img src={facebook_icon} className="sidebar-sns-logo"/> 
                            </a>
                            <a href="https://twitter.com/ABICIMMIGRATION" target="blunk">
                              <img src={twitter} className="sidebar-sns-logo"/> 
                            </a>
                          </div>
                        </div>
                      </div>
                  </div>

                  <div className="right-hand body main-part">
                      <div className="body-content"> 
                        <Switch>
                            <Route exact path="/" component={Home} />

                            <Route exact path="/index_en" component={index_en} />

                            <Route exact path="/chinese/" component={chinese_home} />
                            <Route  path="/chinese/about_us" component={chinese_about_us} />
                            <Route  path="/chinese/immigration_services" component={chinese_immigration_services} />
                            <Route  path="/chinese/start_up" component={chinese_start_up} />
                            <Route path="/chinese/passport_program" component={chinese_passport_program} />
                            <Route path="/chinese/contact_us" component={chinese_contact_us} />
                            <Route path="/chinese/contact" component={chinese_contact} />
                            <Route path="/chinese/chinese_seminer_info" component={chinese_seminer_info} />

                            <Route exact path="/english/" component={english_home}/>
                            <Route path="/english/about_us" component={english_about_us} />
                            <Route path="/english/immigration_services" component={english_immigration_services} />
                            <Route path="/english/business_investment" component={english_business_investment} />
                            <Route path="/english/passport_program" component={english_passport_program} />
                            <Route path="/english/contact_us" component={english_contact_us} />
                            <Route path="/english/contact" component={english_contact} />
                            <Route path="/english/english_seminer_info" component={english_seminer_info} />
                        
                            <Route path="/immigration_and_visa_canada/business" component={immigration_and_visa_canada_business} />
                            <Route path="/immigration_and_visa_canada/skilled_worker" component={immigration_and_visa_canada_skilled_worker} />
                            <Route path="/immigration_and_visa_canada/canadian_express_class" component={immigration_and_visa_canada_canadian_express_class} />
                            <Route path="/immigration_and_visa_canada/skilled_trades" component={immigration_and_visa_canada_skilled_trades} />
                            <Route path="/immigration_and_visa_canada/family_class" component={immigration_and_visa_canada_family_class} />
                            <Route path="/immigration_and_visa_canada/provincial_nominee_program" component={immigration_and_visa_canada_provincial_nominee_program} />
                            <Route path="/immigration_and_visa_canada/work_permit" component={immigration_and_visa_canada_work_permit} />
                            <Route path="/immigration_and_visa_canada/working_holiday" component={immigration_and_visa_canada_working_holiday} />
                            <Route path="/immigration_and_visa_canada/study_permit" component={immigration_and_visa_canada_study_permit} />
                            <Route path="/immigration_and_visa_canada/visitor_visa" component={immigration_and_visa_canada_visitor_visa} />
                            <Route path="/immigration_and_visa_canada/visa_trouble" component={immigration_and_visa_canada_visa_trouble} />

                            <Route path="/starting_business_and_investing/info" component={starting_business_and_investing} />

                            <Route path="/emigrant_life/merit" component={emigrant_life_merit} />
                            <Route path="/emigrant_life/finding_job" component={emigrant_life_finding_job} />
                            <Route path="/emigrant_life/education_and_abroad_to_study" component={emigrant_life_education_and_abroad_to_study} />
                            <Route path="/emigrant_life/health_care_system" component={emigrant_life_health_care_system} />
                            <Route path="/emigrant_life/finding_a_house" component={emigrant_life_finding_a_house} />
                            <Route path="/emigrant_life/assets_and_tax" component={emigrant_life_assets_and_tax} />

                            <Route path="/company_info/interview" component={company_info_interview} />
                            <Route path="/company_info/overview" component={company_info_overview} />
                            <Route path="/company_info/step_for_applying" component={company_info_step_for_applying} />
                            <Route path="/company_info/business_partnership" component={company_info_business_partnership} />
                            <Route path="/company_info/for_corporate_customers" component={company_info_for_corporate_customers} />

                            <Route path="/customer_review/info" component={customer_review_info} />

                            <Route path="/info/seminar_info" component={seminar_info} />
                            <Route path="/info/second_citizenship" component={second_citizenship} />

                            <Route path="/link" component={link} />
                            <Route path="/contact" component={contact} />
                            <Route path="/" component={NotFound} />
                        </Switch>
                      </div>
                    </div>
                </div>
                <div className="footer jp-part">
                  <a href="/company_info/overview">会社概要</a>
                  <span>  | </span>
                  <a href="/contact">お問い合わせ・カウンセリング</a>
                  <span>  | </span>
                  <a href="/link">リンク集</a>
                </div> 
                <div className="footer cn-part">
                  <a href="/chinese/about_us">事务所简介</a>
                    <span>  | </span>
                    <a href="/chinese/contact">免费咨询表</a>
                </div> 
                <div className="footer en-part"> 
                  <a href="/english/about_us">Abouts us</a>
                    <span>  | </span>
                    <a href="/english/contact_us">Contact us</a>
                </div> 

            </div> 
        </div>   
      </BrowserRouter>       
    );
  }
}