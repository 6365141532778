import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_i_b_kig from './img/business/im_i_b_kig.jpg';
import im_i_b_jie from './img/business/im_i_b_jie.jpg';
import im_i_b_tou from './img/business/im_i_b_tou.jpg';


export default class immigration_and_visa_canada_business extends Component {
  render() {
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【ビジネス移民】</title>
            <meta name="description" content="
            創業移民の場合(Start-up Immigration)
            自営業移民の場合(Self-employed)
            投資移民の場合(Investor)
            " />
          </Helmet>
          <h2>ビジネス移民<span> (Business)</span></h2>
          <div className="content-bg-blue">
            創業移民の場合(Start-up Immigration)
          </div>
          <div class="float_right">
              <img src={im_i_b_kig} className="content-pic"/> 
          </div>
          <p>
            ● 認定されて投資機関よりサポートレターを受ける<br/>
            ● 株式保有の規定に満たす<br/>
            ● カナダでビジネスを運営及び管理<br/>
            ● カナダで会社を登記し、主な運営はカナダ国内で行う<br/>
            ● 英語テストを受け、CLB5レベル若しくは以上<br/>
            ● 最低の銀行残高を所持<br/>
          </p>
          <div className="content-bg-blue">
            自営業移民の場合(Self-employed) 
          </div>
          <div class="float_right">
              <img src={im_i_b_jie} className="content-pic"/> 
          </div>
          <p>
            ● 文化やスポーツ分野で世界レベルの選手、俳優、芸術家などまたはこれらの分野で自営業の経験<br/>
            ● カナダで自営業としてビジネスを運営したい<br/>
            ● バックグランドの審査で、一定のポイントを超える<br/>
          </p>
          <div className="content-bg-blue">
            投資移民の場合(Investor)
          </div>
          <div class="float_right">
              <img src={im_i_b_tou} className="content-pic"/> 
          </div>
          <p>
            <p>● 現在では、連邦及びケベック投資移民はクローズされ、申請を受付されておりません。</p>
          </p>
        </>
      );
    }
  }