import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_job from './img/finding_job/im_job.jpg';

export default class finding_job extends Component {
  render() {
      return (
        <>
           <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【仕事探し】</title>
            <meta name="description" content="
                カナダで仕事を探す方法は色々あります。
                ここでは、カナダの求人情報の収集の仕方から面接でのポイントまで、
                カナダでの就職についてご案内します。
                尚、基本的に外国人がカナダで働く為には決まった雇用主の元で
                就労開始前にワークビザを取得する必要があります。
            " />
          </Helmet>
            <h2>仕事探し</h2>
            <div class="float_right">
                <img src={im_job} className="content-pic"/> 
            </div>
            <div className="content">
                カナダで仕事を探す方法は色々あります。<br/>
                ここでは、カナダの求人情報の収集の仕方から面接でのポイントまで、<br/>
                カナダでの就職についてご案内します。<br/>
                尚、基本的に外国人がカナダで働く為には決まった雇用主の元で<br/>
                就労開始前にワークビザを取得する必要があります。<br/>
                但し、ワーキングホリデーやオープンワークなど、<br/>
                雇用主が限定されないワークビザをお持ちの場合はすぐにでも働き始めることができます。<br/>
                カナダのワークビザについてはワークビザのページをご参考下さい。<br/>
            </div>
            <div className="content-bg-blue">
                ステップ1     カナダ求人探し
            </div>
            <p>
                カナダの求人を探す方法は色々ありますが、以下の方法は基本的な探し方です。
            </p>

            <div className="content-bg-border">
                A） 新聞、フリーペーパーのClassified（クラシファイド）をチェック                
            </div>
            <p>
                カナダの道端には新聞やフリーペーパーを入手できるBOXがよく設置されています。 ほとんどの新聞には「Classified」という求人広告欄があり、様々なカナダ求人情報を見つけることができます。又、バンクーバー新報やOops!など日系の新聞でも多くの求人が掲載されています。
            </p>

            <div className="content-bg-border">
                B） インターネットで探す  
            </div>
            <p>
                地元の情報サイトなどを通して探す事もできますし、検索エンジンに求人に関するキーワードを入力すると、関連したカナダ求人情報サイトなどを検索することができます。   
            </p>
            <h4>日系</h4>
            <a href="http://www.v-shinpo.com/">バンクーバー新報</a><br/>
            <a href="http://www.oopsweb.com/2009/kyujin/index.html">ウップスマガジン</a><br/>
            <a href="http://www.canadajournal.com/job.html">カナダジャーナル</a><br/>
            <a href="https://kiyukai.org/index.php/job/">企友会求人情報</a><br/>
            <br/>
            <h4>ローカル</h4>
            <a href="https://www.jobbank.gc.ca/home">Job Bank</a><br/>
            <a href="http://www.working.com/">Working.com</a><br/>
            <a href="https://ca.indeed.com/">Indeed</a><br/>
            <a href="http://www.bcjobs.ca/job.cfm?JobID=51209">BC Jobs</a><br/>

            <div className="content-bg-border">
                C） エージェントに登録
            </div>
            <p>
                仕事を紹介してくれるエージェントなどに登録し、自分の経歴、希望にあった求人を探す事ができます。
            </p>

            <h4>ローカル</h4>
                <a href="http://www.angusone.com/">JAngus One – バンクーバーNO.1人材エージェント</a><br/>
                <a href="https://www.roberthalf.ca/">Robert Halfカナダ大手のエージェント</a><br/>
                <a href="http://paragon-personnel.com/index.htm">バンクーバーナニーを斡旋する専門エージェント</a><br/>
            <div className="content-bg-border">
                D） 店頭のスタッフ募集張り紙をチェック
            </div>
            <p>
                スーパーやレストランやカフェなどお店の店頭に「Hiring Now」などスタッフ募集の張り紙がでている事もあります。<br/>
                気になるカナダでの求人を見つけたら直接履歴書を持っていく方法もあります。
            </p>

            <div className="content-bg-border">
                E） 働きたい会社への問い合わせ
            </div>
            <p>
                求人をしていなくとも希望職種の会社やお店へ履歴書を送る、直接電話で、または出向いて空きポジションがあるか問い合わせてみる、Sなどアプローチする。<br/>
                ワーキングホリデーできている人を雇用している場合、入れ替わりが激しいので空きがある可能性もあります。<br/>
                <br/>
                また、日系の電話帳（「ダイヤルバンクーバー」「タウンページ」など）を利用し、希望職種を探すも可能です。<br/>
                （ABICではセミナー開催の際に上記の電話帳を無料にて配布しています。）
            </p>
            <div className="content-bg-border">
                F） 紹介
            </div>
            <p>
                友人や知り合いを通して求人をしている会社、お店の情報を得るのも一つの方法です。友人の仕事の引継ぎや友人の紹介により仕事を見つけるケースもあるので、友人に自分が仕事を探している事を伝えておくこともいいでしょう。<br/>　
                <br/>
                もしバンクーバーで知り合いや友人がまだそれほどいなければ、コミュニティ団体や、その団体のイベントに参加し、人脈を広げるのもいいでしょう。<br/>
                <br/>
                バンクーバーでは企友会という日系ビジネス協会があり、ほぼ毎月イベントが開始されます。まずはこのようなイベントに参加してみてはいかがでしょうか？<br/>
                思いにもよらない出会いがあるかもしれません。<br/>
            </p>

            <div className="content-bg-blue">
                ステップ2     応募
            </div>
            <p>
                <b>希望する職種をみつけたらその職種に応募する履歴書を作成しましょう。</b><br/>
                <br/>
                ● 履歴書は日英両方用意しておいた方が無難<br/>
                ● 前の雇用者からの推薦状なども◎<br/>
                ● 日本で特別な資格や免許を取得している場合は英訳してもらっておくと役に立つ（卒業証書も同様）<br/>
                ● Resumeと共にカバーレターも作成する<br/>
                <br/>
                <b>英文履歴書（Resume）は日本の履歴書と異なり、年齢、性別、既/未婚、子供の有無などを記載しません。<br/>
                また、手書きではなく、パソコンで作成したものが好ましいです。 <br/></b>
                <br/>
                ポイントとしては、<br/>
                ● 原則的にはレターサイズ1ページに職歴、学歴、特殊技能など簡潔にまとめること。<br/>
                ● 誤字、脱字のないよう十分にチェックし、レイアウトにも気をつける。<br/>
            </p>
            <h4>＜Resumeの基本的な書き方＞</h4>
            <p>
                名前<br/>
                住所<br/>
                電話番号<br/>
                E-mailアドレス<br/>
                <br/>
                <b>Objective（目的、希望職種）</b><br/>
                <br/>
                <b>Work Experience（職歴）</b><br/>
                最近の職歴から古い職歴の順で記入する。<br/>
                各職歴ごと、会社名、ポジション名、期間、職務内容を記載<br/>
                ● 全ての職歴を書く必要はないが、応募する職種に活かせそうなものは記載する<br/>
                ● 職歴の他、ボランティア、インターンシップなども記載する<br/>
                <br/>
                <b>Education（学歴）</b><br/>
                新しいものから順に記入。<br/>
                最終学歴、または大学以上の期間、学校名、専攻、場所を記載<br/>
                <br/>
                <b>Skill（スキル）</b><br/>
                パソコンスキルやTOEFL/TOEICなど語学力をアピールするもの<br/>
                <br/>
                Resumeを送る際、カバーレターも審査の大きなポイントとなります。<br/>
                カバーレターにはResumeからアピールしたい部分をピックアップし、<br/>
                自分がその職種にふさわしいという事を自己PRします。<br/>
                Resume同様、レターサイズ1ページにまとめ、採用担当者への宛名を入れましょう。<br/>
            </p>
            <div className="content-bg-blue">
                ステップ3     面接
            </div>
            <p>
                応募後、アポが取れたらいよいよ面接です。面接では以下の点に気をつけましょう。<br/>
                <br/>
                ● 事前に応募する会社、応募職種について十分に調べ、その職種についての自分のセールスポイントを考えておく<br/>
                ● 服装は職種によりますが、清潔感のある格好を心がける<br/>
                ● 自分の意思や熱意を伝えるよう、相手の目を見ながら落ち着いてゆっくり話す<br/>
                ● 英語が不得意であれば、事前によく練習しておくこと<br/>
                <br/>
                ＜NG＞<br/>
                ● 面接への遅刻や直前のキャンセル、面接日程の変更<br/>
                ● 応募する会社の業務内容、募集職種について全く調べない<br/>
                ● どんなポジションで仕事をしていきたいかが明確でない<br/>
                ● 相手が聞く質問に逸れて、関係ないことを一方的に話す<br/>
            </p>

            <div className="content-bg-blue">
                ステップ4     雇用契約
            </div>
            <p>
                雇用条件に関する認識のズレが後々のトラブルの原因になる事もあります。<br/>
                チップ、残業の扱い、給与の支払いなど雇用条件については雇用時に十分に確認し、書面にて契約・労働環境について交わすようにしましょう。 解決できそうになりトラブルに発展してしまった場合は、HRSDC（カナダ人材開発省）や日系のボランティア団体などに問い合わせ、相談しましょう。<br/>
                <br/>
                フルタイムの場合、1日8時間、1週間でトータル40時間が基準労働時間になります。<br/>
                これを超えての労働は超過勤務となり、8時間以上は給料の1.5倍、12時間を越える場合は給与の2倍が支払われなければいけません。 また、1日のうち5時間以上働く場合は最低30分の休憩をとる事が義務付けられています。<br/>
                <br/>
                <h4>BC州の労働基準法</h4>
                <a href="http://www.labour.gov.bc.ca/esb/esaguide/#12">Employment Standard Act</a><br/>
                退職、解雇については雇用契約の内容に準じますが通常はTwo Weeks Noticeと言われ、退職する2週間前までに辞める意思を伝えなければなりません。 解雇の場合、雇用主は解雇を通知してから48時間以内に労働者に全ての給与を支払う義務があります。自らの辞職の場合は辞職してから6日以内に給与が支払われます。<br/>
                <br/>
                <h4>失業保険</h4>
                失業保険の掛け金は給与から源泉徴収され、雇用者側も負担します。<br/>
                受給の資格は、原則として<br/>
                <br/>
                ● 失業前52週間に420～700時間以上働いている    （地域の失業率により異なる）<br/>
                ● 掛け金を支払っている<br/>
                尚、初めて仕事に就いた人、または過去2年間のブランクがある人の場合は最低910時間働かないと保険申請ができません。<br/>
                但し、産休や病気治療の場合は600時間以上働いていれば申請が可能です。<br/>
                <br/>
                失業保険は自ら辞職、または不品行等で解雇された場合には適用されません。<br/>
                失業保険の問い合わせ先：<br/>
                Contact Information for Employment Insurance (EI)<br/>
                1-800-206-7218<br/>
                <br/>
                <div style={{margin: "0 0 0 20px", fontWeight:"normal"}}>
                <h4>申請方法</h4>
                    解雇の際に雇用主から解雇の理由、給与の記録などが記載された離職証明（Record of Employment）を受け取る。<br/>
                    （尚、過去52週間のうち複数の職に就いていた場合は全ての雇用主の証明が必要。）<br/>
                    離職証明とSINカードを持参し、最寄の雇用センターに行き、 所定の申し込み用紙に記入の上、最寄のHuman Resources Centre of Canadaで申請する。<br/>
                    申請後約2週間でレポートカードが送付されるので、郵送または電話ですぐにこれに対応する。<br/>
                    申請が認められると4週間後に保険金が支払われる。<br/>
                    尚、レポートカードは失業期間中は2週間毎に提出しなければならない。<br/>
                    支給金額は給与の55％が普通で、14週～45週受け取れる。<br/>
                    この期間中、働くこともできるが収入は全て報告する義務があり、保険金の25％または週50ドル以上になると超過分が差し引かれる。<br/>
                </div>
            </p>
        </>
      );
    }
  }