import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import certificate from './img/overview/certificate.jpg';
import office_image from './img/overview/office-image.jpg';
import office_map from './img/overview/office-map.jpg';

import about_us0 from './img/overview/about_us0.png';
import about_us1 from './img/overview/about_us1.png';
import about_us2 from './img/overview/about_us2.png';

export default class company_info_overview extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【会社概要】</title>
            <meta name="description" content="
            Alexander Business Investment Consultants Inc.(ABIC), エービックカナダ移民＆ビザ事務所, United Kingdom Building Suite 1617, 409 Granville Street Vancouver, BC CANADA V6C 1T2
            " />
          </Helmet>
            <h2>会社概要</h2>
            <p>
                <table>
                    <tr className="colored">
                        <td width={"120px"}>
                            <b>
                                会社名(英語)<br/>
                                会社名(日本語)
                            </b>
                        </td>
                        <td  width="auto">
                            Alexander Business Investment Consultants Inc.(ABIC)<br/>
                            エービックカナダ移民＆ビザ事務所
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>
                                本社所在地
                            </b>	
                        </td>
                        <td>
                            United Kingdom Building Suite 1617, 409 Granville Street
    Vancouver, BC CANADA V6C 1T2
                        </td>
                    </tr>
                    <tr className="colored">
                        <td>
                            <b>創業</b>
                        </td>
                        <td>
                            2000年11月
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>代表取締役</b>
                        </td>
                        <td>
                            Terry Jin　（テリー・ジン）
                        </td>
                    </tr>
                    <tr className="colored">
                        <td>
                            <b>業務内容</b>
                        </td>
                        <td>
                            カナダへの移住＆各種移民、ビザ代行のサポート<br/>
                            北米で起業コンサルティング又は投資のサポート<br/>
                            その他ビジネスサポート事業<br/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>弁護士パートナー</b>
                        </td>
                        <td>
                        下記の分野にて、各弁護士と提携し、リーガルサービスを提供することは可能です。<br/>
                        ・ 移民＆訴訟<br/>
                        ・ 会社法務<br/>
                        ・ ビジネス契約<br/>
                        ・ アメリカ法律事務<br/>
                        ・ ICBC交通事故<br/>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>取引先銀行</b>
                        </td>
                        <td>
                            TD Canada Trust
                        </td>
                    </tr>
                    <tr className="colored">
                        <td>
                            <b>カナダBC州登録<br/>
                                (コンサルタント業)
                            </b>
                        </td>
                        <td>
                            BC0679946
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>
                                移民協会登録<br/>
                                (カナダ政府承認)
                            </b>
                        </td>
                        <td>
                            MEI YU RCIC#R421895<br/>
                            CICC website www.college-ic.ca/
                        </td>
                    </tr>
                    <tr className="colored">
                        <td>
                            <b>企業理念</b>
                        </td>
                        <td>
                            カナダでより豊かなライフを求め、真剣に就労、移住を考えている人に 対し、移民＆ビザの手続きを含めたトータル的なサポートを行い、クライアントの就職・永住が成功へ導くよう努力する。
                        </td>
                    </tr>

                </table>
            </p>
            <div className="content-bg-blue">
                代理人資格証書
            </div>
            <div className="content">
                カナダ政府公認移民代理の資格を取得(2004年)、カナダ移民協会正規メンバー
            </div>
            <img src={certificate} className="content-pic full-width"/> 
            <div class="about_us_pics">
                <img src={about_us0} className="content-pic"/> 
                <img src={about_us1} className="content-pic"/> 
                <img src={about_us2} className="content-pic"/> 
            </div>
            <div className="content-bg-blue">
                ABICへの行き方
            </div>
            <p>
                <table>
                        <tr>
                            <td width="100px">
                                <b>
                                    電車
                                </b>
                            </td>
                            <td>
                                ウォーターフロント駅より０分
                            </td>
                        </tr>
                        <tr>
                            <td width="40%">
                                <b>
                                    バス
                                </b>
                            </td>
                            <td>
                                #135,#160,#190(W. Hastings)より０分<br/>
                                #4,#6,#7,#17,#50(Granville)より２分<br/>
                                #5,#19,#22,N22(Pedner)より３分<br/>
                                #3,#4,#6,#7,#8,#17,#50,#98(Cordova)より５分<br/>
                            </td>
                        </tr>
                        <tr>
                            <td width="40%">
                                <b>
                                    駐車場<br/>
                                    Public Parking
                                </b>
                            </td>
                            <td>
                                443 Seymour St. Vancouver
                            </td>
                        </tr>
                </table> 
                <br/>
                <img src={office_image} className="content-pic center full-width"/> <br/>
                <br/>
                <div className="googleMap">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5204.976126544984!2d-123.113636!3d49.286097!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486717881504227%3A0x7f3d7b8a00251b70!2s409%20Granville%20St%2C%20Vancouver%2C%20BC%20V6C%201T2%2C%20Canada!5e0!3m2!1sen!2sus!4v1606472191146!5m2!1sen!2sus" width="600" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe><br/>
                </div>
                <br/>
                <div className="full-width textAlignCenter">
                    <img src={office_map} className="content-pic"/> <br/>
                </div>
                <br/>
            </p>
            <div className="content-bg-blue">
                ABICへのご連絡
            </div>
            <p>
                エービックカナダ移民＆ビザ事務所<br/>
                日本語担当：めぐみ、ひさこ、テリー<br/>
                電話：６０４－６８８－１９５６<br/>
                ファックス：６０４－６８８－６３８７<br/>
                Eメール： <a href="mailto:Japan@ABICanada.com">Japan@ABICanada.com</a><br/>
            </p>
        </>
      );
    }
  }