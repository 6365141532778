import React, { Component } from 'react';
import '../App.css';
import {Helmet} from "react-helmet";

export default class Notfound extends Component {
    componentDidMount()
    {
        setTimeout("location.href='/'",1000*5);
    }

  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【Page Not Found】</title>
            <meta name="description" content="
            " />
          </Helmet>
            <h2>Page Not Found</h2>
            <h5 className="position_center">
                Either the page you are looking for does not exist, or it has been removed when the web page was updated.<br/>
                We apologise for this, but please use the menu to navigate to the home page or the page you are looking for.
            </h5>
            <h4 className="position_center">You will be taken to the home page <b>after 5 seconds</b>. Please wait a moment.</h4>
        </>
      );
    }
  }