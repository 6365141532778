import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import business_invest from './img/business_invest.jpg';

export default class business_investment extends Component {

  render() {
    return (
      <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC Immigration【Business & Investment】</title>
            <meta name="description" content=""
                
             />
          </Helmet>
          <span class="en-part">
          <h2>
            Business & Investment
          </h2>
          <div class="float_right">
              <img src={business_invest} className="content-pic"/> 
          </div>
          <p>
            Doing business or Invest in Canada is the foreign entrepreneur’s primary point of contact. Furthermore, this
            investment may help you meet the immigration requirement. The supports that we can provide include:
          </p>
          <br/>
          <p>
            ● Assistance in developing a business case for your next investment project<br/>
            ● Information and advice on doing business in Canada<br/>
            ● Strategic market intelligence on your specific industry<br/>
            ● Business Site visits<br/>
            ● Locate a location for commercial lease<br/>
            ● Purchase and acquire a existing business<br/>
          </p>
          <br/>
          <p>
            Investment &amp; Entrepreneur Immigration is our expertise. ABIC Consultants offer feasibility study, business
            plan, Incorporation, accounting, marketing and management service, and a full range of settlement services to
            clients. Our services include airport pick-up, accommodation, automobile, banking, schooling, job search, tax
            return, etc.
            <br/>
            <br/>
        </p>
        <p>
            In addition, we can introduce you to the investment support professionals, such as lenders, lawyers, accountants,
            information specialists and private-sector industry associations, help you access local investment opportunities,
            networks and programs.
        </p>
        </span>
    </>
    );
  }
}