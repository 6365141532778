import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";


import im_v_kaz from './img/family_class/im_v_kaz.jpg';
import BC_PNP_Business_Category from './img/provincial_nominee_program/BC_PNP_Business_Category.jpg';

export default class immigration_and_visa_canada_provincial_nominee_program extends Component {
  render() {
      return (
        <>
           <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【PNP移民】</title>
            <meta name="description" content="
            カナダの各州政府は各地の経済、産業、労働市場、地理などの面を考慮し、独自に開発した移住プログラムをもっています。
            " />
          </Helmet>
            <h2>PNP移民<span> (Provincial Nominee Program)</span></h2>
            <div class="float_right">
              <img src={im_v_kaz} className="content-pic"/> 
            </div>
            <div className="content">
                <p>
                カナダの各州政府は各地の経済、産業、労働市場、地理などの面を考慮し、独自に開発した移住プログラムをもっています。</p>
            </div>
            <div className="content-bg-blue">
                PNPのステップ
            </div>
            <p>
            1. 州移民部にノミネート移民の申請<br/>
            2. ノミネートとして承認<br/>
            3. 連邦移民局へ永住ビザを申請<br/>
            <br/>
            現在、計10州がノミネート移民プログラムをもっています。ここではBC州を紹介しましょう。<br/>
            </p>
            <div className="content-bg-blue">
                ブリティッシュコロンビア州 (British Columbia) PNP
            </div>
            <p>
                BC州の移民プログラムは<span style={{color: "#0066CC"}}> Skills Immigration</span>,<span style={{color: "#0066CC"}}> Express Entry BC </span>と<span style={{color: "#0066CC"}}> Entrepreneur Immigration </span> の3つの方法はあります。<br/>
                Skills Immigrationには以下のカテゴリーがあります。

            </p>
            <div className="content-bg-border">
                BC PNP Strategic Occupations
            </div>
            <p>
                1) Skilled Workers（マネージャー、スーパーバイザー等専門技術職）<br/>
                2) Entry Level and Semi- Skilled Pilot Project (ELSS)ロースキルプログラム<br/>
                3) Designated Health Professionals（医師、看護士など医療関係者）<br/>
                4) International Graduates（カナダの短大、大学、大学院等を卒業した留学生）<br/>
                5) International Post-Graduates （BC州で大学院士、博士号を取得した留学生）<br/>
            </p>
            <p>
                <h4>1) 　Skilled Workers</h4>
                <span style={{color: "#0066CC"}}>連邦政府のSkilled Workersと同じく、職業リストのO, A, Bレベルの方が対象。</span>
                <br/>
                <br/>
                申請条件<br/>
                ●　申請者はBC州の会社からのオファーがある（NOC／O, A, Bレベル ）<br/>
                ●　スポンサー（オファーを出す会社）はBC州で経営1年以上、従業員5名以上、および財政面も良好である<br/>
            </p>
            <p>
                <h4>2）　Entry Level and Semi Skilled (ELSS)</h4>
                <span style={{color: "#0066CC"}}>一部エントリーレベルの職種（NOC／C, Dレベル）で働く外国労働者が対象。</span>
                <br/>
                <br/>
                申請条件<br/>
                ●　スポンサーはBC州内で2年以上経営している<br/>
                ●　スポンサーは5名以上フルタイムの従業員を雇用している<br/>
                ●　スポンサーは法令を遵守し、財政面も良好である<br/>
                ●　申請者は9ヶ月以上スポンサー企業で継続して就労している<br/>
            </p>
            <p>
                <b>対象職種（一部）</b>
                <table className="bglb">
                    <tr>
                        <td>Food and Beverage Servers</td>
                        <td>Hotel Front Desk Clerks</td>
                    </tr>
                    <tr>
                        <td>Food counter Attendants</td>
                        <td>Light Duty Cleaners</td>
                    </tr>
                    <tr>
                        <td>Kitchen Helpers</td>
                        <td>Specialized Cleaners</td>
                    </tr>
                    <tr>
                        <td>Bartenders</td>
                        <td>Other Attendants in Accommodation</td>
                    </tr>
                    <tr>
                        <td>Tour and Travel Guides</td>
                        <td>Long-Haul Truck Drivers</td>
                    </tr>
                    <tr>
                        <td>Outdoor Sport and Recreational Guides</td>
                        <td>Casino Occupations</td>
                    </tr>
                </table>
            </p>
            <p>
                <h4>3）　Designated Health Professionals</h4>
                <span style={{color: "#0066CC"}}>このクラスは登録された正看護師、精神科看護師、州保健局の医師、助産師の職種に限られます</span><br/>
                <br/>
                <b>申請条件</b><br/>
                ●　看護師は登録、または登録資格を持つものに限る<br/>
                ●　医師はBC州内で最低9ヶ月以上の医療活動経験があり、推薦状がある<br/>
                ●　助産師はBC州の最低6ヶ月以上の認可グループでの助産活動経験があり、<br/>
                Midwives of British Columbia Collegeの登録証明がある<br/>
            </p>
            <p>
                <h4>4)　 International Graduates</h4>
                <span style={{color: "#0066CC"}}>カナダで認可を受けたPost-Secondaryレベルの教育機関で卒業および学位を取得し、Skilled Levelのジョブオファーを持った留学生が対象です。</span><br/>
                <br/>
                <b>申請条件</b><br/>
                ●　取得した学位で、総合的に最低70％（あるいはそれに相当するポイント）の成績を修めている<br/>
                ●　成績証明書の日付から3年以内に申請を提出する<br/>
                ●　Skilled Levelの職種で雇用される予定である<br/>
            </p>
            <p>
                <h4>5)　 International Post-Graduates</h4>
                <span style={{color: "#0066CC"}}>BC州内の大学で大学院士、博士号を取得してから２年以内に申請可能。申請時とくに雇用オファーは求められない。</span><br/>
                <br/>
                <b>申請条件</b><br/>
                ● 農業、バイオ、コンピューター、エンジニアなどの分野で履修した<br/>
                ● BC州に移住の意思<br/>
                ● 卒業後又は移民申請中、ワークビザを申請そして所持することが望ましい<br/>
            </p>
            <div className="content-bg-border">
                BC PNP Business Category
            </div>
            <p>
            <span style={{color: "#0066CC"}}>申請条件</span><br/>
            <br/>
                Base Category とRegional Pilotの二つのカテゴリーの申請条件は以下となる。<br/>
                EI Base categoryはBC州内場所に限らず、新規ビジネスの立上げ又は既存ビジネスの引継ぎによる起業家永住プログラムである。<br/>
                EI Regional Pilotは人口が少ない遠方地コミュニティーでビジネスをスタートアップするため、比較的に低コストによる起業家永住プログラムである。<br/>
            </p>
            <p className="textAlignCenter">
                <img src={BC_PNP_Business_Category} className="content-pic"/> <br/>
                <a href="https://www.welcomebc.ca/getmedia/e86adbb5-e9ad-4181-b9a7-d604f4453a51/BC-PNP-Entrepreneur-Immigration-Program-Guide.pdf.aspx">BC PNP Entrepreneur Immigration Program Guide</a>
            </p>
            <p>
                PNPビジネス移民クラスをもつ州は、BC州の他、アルバータ州、マニトバ州、サスカチュワン州など合計１０の州があります。州政府の移民プログラムの利点としては審査が早いことですが、各州の経済事情に大きく影響を受けます。ご自身のバックグランドではどの州が一番適正か、十分に情報収集をしましょう。
            </p>
        </>
      );
    }
  }