import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_gyo from './img/business_partnership/im_gyo.jpg';

export default class company_info_business_partnership extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【業務提携】</title>
            <meta name="description" content="
                エービック移民事務所はより多くのクライアントが利用しやすいため、下記の企業、団体との業務提携を随時募集しております。
            " />
          </Helmet>
            <h2>業務提携</h2>
            <div class="float_right">
            </div>
            <div className="content-bg-blue">
                留学エージェント、旅行会社、カナダ支社、各分野の専門家(投資・保険・不動産)
            </div>
            <div className="content">
                エービック移民事務所はより多くのクライアントが利用しやすいため、下記の企業、団体との業務提携を随時募集しております。<br/>
                どうぞ、604-688-1956　又はメール： <a href="mailto:Japan@ABICanada.com">Japan@ABICanada.com</a><br/>
                テリーまでお気軽にお問合せて下さい。
            </div>
            <p>
                <div  class="float_right">
                    <img src={im_gyo} className="content-pic"/> 
                </div>
                ￭ 学生ビザの取得、トラブルの解決 <br/>
                ￭ 入国拒否された場合の対策 <br/>
                ￭ ビザ申請前のカウンセリング <br/>
                ￭ ビザ却下された後の対策 <br/>
                ￭ ワークビザの取得及び代行 <br/>
                ￭ 就職先の無料紹介 <br/>
                ￭ 永住までのプランニング <br/>
                ￭ 移民ビザの代行申請 <br/>
                ￭ 永住及びロングステーサポート <br/>
                ￭ ビジネスコンサルティング <br/>
                ￭ 会社の設立、マーケティング <br/>
                ￭ 法人、個人の税金申告 <br/>
                ￭ 不動産（商業・住宅） <br/>
                ￭ 各種保険（商業・住宅・生命・団体） <br/>
                ￭ 投資（信託・年金・RRSP・RESP） <br/>
            </p>

        </>
      );
    }
  }