import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

export default class immigration_and_visa_canada_work_permit extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【ワークビザ】</title>
            <meta name="description" content="
            「カナダに留学して、働く経験をしてみたい」
            「カナダで仕事を見つけて、永住したい」
            毎年数十万人の留学生や移民を受け入れているカナダは、外国人の就労も積極的に受け入れています。
            ただし通常、外国人がカナダで働くには事前にワークビザ（Work Permit / 就労許可証）
            を取らなければなりません。" />
          </Helmet>
            <h2>ワークビザ</h2>
            <div className="content">
                <p>
                    「カナダに留学して、働く経験をしてみたい」<br/>
                    「カナダで仕事を見つけて、永住したい」<br/>
                    毎年数十万人の留学生や移民を受け入れているカナダは、外国人の就労も積極的に受け入れています。<br/>
                    ただし通常、外国人がカナダで働くには事前にワークビザ（Work Permit / 就労許可証）<br/>
                    を取らなければなりません。<br/>
                </p>
            </div>
            <div className="content-bg-blue">
                就労許可書（Work Permit）の種類
            </div>
            <p>
                ◆ レギュラーのワークビザ Work permit (regular type)<br/>
                ◆ 留学生のワークビザ Work permit for students<br/>
                ◆ ワーキングホリデービザ Working holiday work permit<br/>
                ◆ スペシャルワークビザ Work permit for special category<br/>
            </p>
            <p>
                ではレギュラーのワークビザ（Work Permit / 就労許可証）の取得について主に説明します。
            </p>
            <br/>
            <p>
                <b>通常のワークビザ申請のステップ</b><br/>

                1.　雇用主がEmployment and Social Development Canada（ESDC）にジョブオファーの承認（LMIA）をもらう<br/>
                2.　労働者がカナダ移民局へワークビザ（Work Permit / 就労許可証）を申請する
            </p>
            <br/>
            <p>
                <b>ESDC の審査基準</b><br/>

                ●  カナダ人や永住者を優先的に採用するために、求人活動またはトレーニングを十分に行ったか<br/>
                ●  カナダ労働市場の賃金や労働条件に見合っているか<br/>
                ●  その雇用を通してカナダ人や永住者の雇用を増やす、または維持することにつながるか<br/>
                ●  その雇用を通して、カナダに新しい技術や知識をもたらすか<br/>
                ●  その雇用はカナダの労働市場に過大な影響を与えないか<br/>
            </p>
            <br/>
            <p>
                ESDCからジョブオファーの承認（LMIA）が下りたら、東京にあるカナダ大使館査証部、<br/>
                またはカナダの入国地でワークビザ（Work Permit / 就労許可証）を申請します。<br/>
            </p>
            <p>
                上記が外国人である皆さんがカナダ仕事を見つけてワークビザを取るまでの流れです。<br/>
                こうして見ると、簡単に取れて1年間自由に働けるワーキングホリデーとの大きな違いがよくわかります。ワーキングホリデーでは2、3件の掛け持ちやパートタイムのアルバイトもできますが、 通常のワークビザはスポンサー企業でフルタイムの就労でなければなりません。                
            </p>
            <p>
                ワーキングホリデープログラムにより簡単に取れる1年オープンのワークビザは、とても貴重なものであることをここで少し理解ができたでしょう。<br/>        
            </p>
            <p>
                ワークビザを発行するのはあくまでも移民局ですが、その前の段階でスポンサー企業（雇い主）がESDC（労働局）にLMIAを申請をします。<br/>

                新聞やインターネット等の求人広告を見て応募し、面接を受けて、採用が決まれば LMIAの申請やご自身のワークビザについてエービックのビザアドバイザーにご相談下さい。<br/>
            </p>

            <div className="content-bg-blue">
                留学生が取得できるワークビザ
            </div>
            <p>
                ここでは外国人留学生が取得することが可能なワークビザ（Work Permit / 就労許可証）を紹介します。
            </p>
            <div className="content-bg-border">
                1. オンキャンパス就労 (On-Campus)
            </div>
            <p>
                対象者：公立のカレッジ、ユニバーシティ、または一部私立の学位プログラムを就学しているフルタイムの在学生。キャンパス内での就労にはWork Permit 申請不要。 
            </p>
            <div className="content-bg-border">
                2. オフキャンパス就労 (Off-campus)
            </div>
            <p>
                大学に在学中の留学生が勉強以外の余暇時間を利用し、校外で週に20時間（夏・冬休みはフルタイム就労は可能）就労できるプログラム。<br/>
                <br/>
                <br/>
                <b>申請条件</b><br/>
                ●  公立のカレッジ、ユニバーシティ、または一部私立の学位プログラムを就学している<br/>
                ●  有効な就学許可を所持していること<br/>
                ●  フルタイムで6ヶ月以上経過している<br/>
                ●  フルタイムに在籍、学校の授業に出席、コースを履修していること<br/>
                <br/>
                <br/>
                以前まで公立校のみが対象でしたが、最近は私立の大学も対象になっています。<br/>
                このWork Permitを申請するには、学校が所在した州教育部に登録して承認を受け、州教育部が移民局と協議を結んでいることが条件です。<br/>
                事前に学校に確認してみましょう。
            </p>
            <div className="content-bg-border">
                3. コープ・インターンシップ (Co-op and internship)
            </div>
            <p>
                これは在学中、企業で実務研修として働くことが可能なワークビザです。<br/>
                卒業後に研修先でそのまま雇用されるケースも多く、カナダで就職を考えている留学生には利用価値の高いプログラムの一つでしょう。<br/>
            <br/>
            <br/>
                <b>申請条件</b><br/>
                ●  有効な就学許可証を所持していること<br/>
                ●  就労（実習）は就学プログラムと関連し、欠かせない一環であること<br/>
                ●  就労の期間が就学プログラム全体の50％を超えないこと<br/>
            <br/>
            <br/>
                ※コープは、学生がアルバイトをする為のワークビザではありません。<br/>
                あくまで就学プログラムの一環であり、雇用主である学校から研修先に派遣されている就労であることが絶対条件です。
            </p>
            <div className="content-bg-border">
                4. 卒業生の就労 (Post-Graduation Work Permit) NEW
            </div>
            <p>
                2008年4月、カナダ連邦移民局よりPost-Graduation Work Permitが改定されました。<br/>
                この改定により、受講したプログラムの内容に関係なく最長3年間有効のオープンワークビザが取得でき、また雇用主のオファーがなくてもこのワークビザを申請することができます。<br/>
            <br/>
            <br/>
                <b>Post-Graduationワークビザを申請する条件</b><br/>
                ●  公立または承認された私立の大学・カレッジの学位プログラムを修了すること<br/>
                ●  最低８ヶ月以上のプログラムであること（８ヶ月から２年までのプログラムの場合はそれと同じ期間、2年以上の場合は最長３年のワークビザが取得できます。）<br/>
                ●  卒業してから90日以内に申請すること<br/>
                ●  申請時、有効な学生ビザを所持していること<br/>
                <br/>
                <br/>
                このPost-Graduation ワークビザは卒業してから申請します。 卒業証書または「卒業確認」のレターを学校から発行してもらいましょう。 申請してから取得するまでおおよそ２~３週間程度です。<br/>
                <br/>
                <br/>
                ※以前はほぼ公立大学のみ対象でしたが、学位単位を取れる私立の学校でも認めるようになっています。詳細は学校を通して各州の教育部に確認しましょう。<br/>
                <br/>
                <br/>
                移民局はカナダの大学の国際留学生に対し、ビザに関する政策を緩和する傾向にあります。<br/>
                今後真剣にカナダでの就職・移住を検討されている方は、公立の大学・カレッジまたは私立の学位プログラムをご検討されるとよいでしょう。<br/>
                カナダ大学レベルの英語能力と十分な資金を用意してください。<br/>

                外交官などの公用赴任、宗教家、ニュース報道メディア、スポーツ参加選手、会議などの講演者、劇場公演などのパフォーミング・アーティスト、契約に盛り込まれた技術サポート（機械の修理及び据付けなど）、企業間研修者、キャンパス内就労、医療関連の実習生、宗教関連者、その他。
            </p>
            <div className="content-bg-blue">
                カナダ スペシャルワークビザ Work permit for special category
            </div>
            <p>
                国際協定(NAFTA,他のFTA,GATS)に基づいたプロフェショナル、<br/>
                社内転勤のマネージャー＆テクニシャン、企業家、投資家ためのワークビザ
            </p>
        </>
      );
    }
  }