import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import about_us0 from './img/about_us0.png';
import about_us1 from './img/about_us1.png';
import about_us2 from './img/about_us2.png';

export default class about_us extends Component {

  render() {
    return (
      <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC Immigration【About us】</title>
            <meta name="description" content=""
                
             />
          </Helmet>
          <span class="en-part">
            <h2>
                About us
            </h2>
            <p>
                Alexander Business Investment Consultants Inc. (ABIC Consulting) has been providing professional
                immigration and business consulting service since November 2000. Our in-house licensed immigration
                consultant Mei Yu and Business consultant Terry Jin have accumulated more than 20 years of specialized
                experiences in Canadian immigration field, business start-up and has assist over thousand immigration &amp; Visa
                and Business cases. ABIC Consulting is located in beautiful downtown Vancouver, BC, Canada. We are
                associated and co-operated with the other Law offices in different location in Canada, China and Japan.
            </p>
            <p>
                Our senior legal consul has more than 35 years expertise in Canadian immigration law. Also a special advisor to
                immigration policy matters. He is best known as the Editor-in-Chief of Lexbase, a publication providing current
                immigration policy and practice information, with monthly summaries of Federal Court decisions in
                immigration and citizenship rendered. Lexbase is received by IRCC; CBSA; IRB, DOJ.
            </p>
            <div className="content-bg-blue">
                Firm Profile
            </div>
            <p>
                <table>
                        <tr className="colored">
                            <td width={"120px"}>
                                <b>
                                    Company Name<br/>
                                    Business Name
                                </b>
                            </td>
                            <td  width="auto">
                                Alexander Business Investment Consultants Inc.<br/>
                                ABIC Consulting, ABIC Immigration
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>
                                    Address
                                </b>	
                            </td>
                            <td>
                                United Kingdom Building Suite 1617, 409 Granville Street <br/>
                                Vancouver, BC CANADA V6C 1T2 CANADA
                            </td>
                        </tr>
                        <tr className="colored">
                            <td>
                                <b>
                                    Established<br/>
                                    Incorporated
                                </b>
                            </td>
                            <td>
                                November 2000<br/>
                                October 2003
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Representative</b>
                            </td>
                            <td>
                                Terry Jin
                            </td>
                        </tr>
                        <tr className="colored">
                            <td>
                                <b>Services</b>
                            </td>
                            <td>
                                Canadian Immigration and Visa Services<br/>
                                Canadian Immigration and Visa Services<br/>
                                Other countries visa, immigration and passport program<br/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Phone</b>
                            </td>
                            <td>
                                604-688-1956
                            </td>
                        </tr>
                        <tr className="colored">
                            <td>
                                <b>Email:</b>
                            </td>
                            <td>
                                <a href="mailto:abic@abicanada.com">abic@abicanada.com</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>
                                    CICC<br/>
                                    Registered Licensed
                                </b>
                            </td>
                            <td>
                                MEI YU RCIC#R421895<br/>
                                CICC website www.college-ic.ca/
                            </td>
                        </tr>
                        <tr className="colored">
                            <td>
                                <b>
                                    Mission
                                </b>
                            </td>
                            <td>
                            Provide comprehensive immigration, visa, settlement and investment services for applicants who are seriously considering and obtaining a better life after immigrating to Canada.
                            </td>
                        </tr>
                    </table>
                </p>
            <div class="about_us_pics">
                <img src={about_us0} className="content-pic"/> 
                <img src={about_us1} className="content-pic"/> 
                <img src={about_us2} className="content-pic"/> 
            </div>
        </span>
    </>
    );
  }
}