import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import about_interview_clip_image002 from './img/interview/about_interview_clip_image002.jpg';
import about_interview_clip_image004 from './img/interview/about_interview_clip_image004.jpg';
import about_interview_clip_image006 from './img/interview/about_interview_clip_image006.jpg';



export default class company_info_interview extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【社長インタビュー】</title>
            <meta name="description" content="
            ◆ 移民サービスを創業 ◆ 移民コンサルタントを使うメリット ◆ エービックの魅力 ◆ 社長自身について
            " />
          </Helmet>
            <h2>社長インタビュー</h2>
            <div className="content-bg-blue">
                ◆ 移民サービスを創業
            </div>
            <div className="youtube">
                <iframe src="https://www.youtube.com/embed/hLyJu1hVxjg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> 
            <br/>
            <div className="youtube">
                <iframe src="https://www.youtube.com/embed/qFq0he8BjrM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> 
            <br/>
            <hr/>
            <p>
                <h4>Q: 移民事務所を設立した理由を教えてください。</h4>
                <div  class="float_right">
                    <img src={about_interview_clip_image002} className="content-pic"/> 
                </div>
                自分自身の昔の経験もそうでしたが、ビザ申請に関してよくわからず、安心して頼めるところは少ないと感じました。確かに電話帳を開けば弁護士のリストはいっぱい載っていますが、母国語を話せて親身に話を聴いてくれる人はなかなかいなかったですね。あと弁護士ですと、利用料金もちょっと心配なところです。 そこで皆さんが相談しやすく、安心して頼める移民専門事務所を始めようと決意したんです。創業当時は移民業のベテランの方にサポートしてもらい、自信と経験を積みましたね。<br/>
            </p>
            <p>
                <h4>Q: 移民コンサルタント業に就いた感想はいかがでしたか?</h4>
                本当に楽しい仕事ですよ。移民局からビザが下りたレターが届く度にすごくうれしいです。すぐにクライアントに電話をかけますね。そしてクライアントと一緒に喜び合います。
            </p>
            <p>
                <h4>Q: 移民事務所を設立した理由を教えてください。</h4>
                そうですね。皆様のビザが無事に下り、共に喜び合う瞬間が一番幸せです。あとは難しい案件、例えば一度却下された案件や他の移民弁護士もお手上げの案件、または取れるか取れないか微妙な案件等の依頼を受けると、より一層やりがいを感じます。
            </p>
            <div className="content-bg-blue">
                ◆ 移民コンサルタントを使うメリット
            </div>
            <p>
                <h4>Q: 移民とビザの申請は自分でもできますか?移民コンサルタントは必要?</h4>
                <div  class="float_left">
                    <img src={about_interview_clip_image004} className="content-pic"/> 
                </div>
                移民&ビザの種類によって、比較的に簡単なものとわかりにくいものはあります。また、申請者の英語力、書類準備に取り込む時間にもよります。一概には言えませんが、ほとんどのケース(特に移民申請)では経験が豊富なコンサルタントを利用したほうが良いでしょう。仮に簡単な申請でも、小さな記入漏れが大きな問題に繋がる場合もあります。コンサルタントを利用することにより、成功率や取得までの時間は全て平均より上回ります。
            </p>
            <p>
                <h4>Q: 移民事務所を設立した理由を教えてください。</h4>
                そうですね。皆様のビザが無事に下り、共に喜び合う瞬間が一番幸せです。あとは難しい案件、例えば一度却下された案件や他の移民弁護士もお手上げの案件、または取れるか取れないか微妙な案件等の依頼を受けると、より一層やりがいを感じます。
            </p>
            <p>
                <h4>Q: ズバリそのメリットを教えてください。</h4>
                具体的に言うと<br/>
                (1)正確さ - 専門家による指導で正確な書類、情報を集められる。
                (2)速さ - 専門家の経験でいかに早く書類を集める方法を教えてくれる。そして、完備した書類で申請がスムーズに進み、全体の審査が平均より早く終わるケースが多いです。<br/>
                (3)安心 - コンサルタントが移民局からの質問に対応致しますので、申請後も安心してお待ち頂けます。<br/>
            </p>
            <div className="content-bg-blue">
                ◆ エービックの魅力
            </div>
            <p>
                <h4>Q: どんなスタッフで働いていますか?</h4>
                基本的にスタッフは皆強い責任感をもって働いています。また人に対する思いやりがあり、この仕事に対する情熱を持っています。仕事は楽しく、とエービックの皆が思っています。
            </p>
            <p>
                <h4>Q: エービックではどんなサービスができますか?</h4>
                まず私たちは依頼や問い合わせを受けてからのレスポンスが早いです。ご相談に来られる方々の多くが一日も早くビザを取りたいと希望していらっしゃいますので、効率良く、早く対応することを最も重要視しています。<br/>
                プロとアマチュアの違いはテクニカル面は当然ですが、常に基本に忠実にミスの無い正確な処理をできるか、というところです。私たちの仕事はミスが許されないので、100%成功するために完備した書類の作成、ミスをカットする事が使命です。
            </p>


            <div className="content-bg-blue">
                ◆ 社長自身について
            </div>
            <p>
                <h4>Q: テリー社長は日本語を話せますか?</h4>
                <div  class="float_right">
                    <img src={about_interview_clip_image006} className="content-pic"/> 
                </div>
                生まれは中国（本土）です。日本に留学、大学を卒業、就職もしました。時々顔で日本人、韓国人と思われるときもあります。これまで２０年以上の海外の生活を経験しながら、様々な国の文化、ビジネスを常に勉強する気持ちでいた。なので、私と話せば、時々日本人のところも感じるでしょうが、中国人？カナダ？アメリカ？と多国籍に戸惑わせるかもしれません。こういった複数の国の文化は私自身の価値観、考え方に反映され、ある特定の国より、オープンマインドで地球上の公民という気持ちが強いでしょうね。
            </p>
            <p>
                <h4>Q: テリー社長はのカナダ移民代理およびビジネスの経験は?</h4>
                カナダ移民、ビザのサービスを２０００年に移住してから個人事業として始めたのです。２００３年法人化し、２００４年カナダ移民局協会（CSIC）の初代の移民コンサルタントとなりました。それ以来も、移民、ビザの案件は日々の業務として数多くこなして来ました。個人移民、ファミリークラス、ワークビザ、学生ビザは勿論、比較的に難しい投資移民、企業家移民も数多く経験してまりました。例えばクライアントの会社の決算報告書をレビュー、面接のトレーニング及び同行、ビジネスプランの作成、会社の立上げなどのことも行ってきました。PEI州、ノバスコシア州、マニトバ州、サスカチュワン州、ケベック州、BC州など、各州の移民局まで行き、プログラムの要点の確認、案件の質問などで移民オフィサーと直接会い、話合いをしてきました。連邦、各州のプログラムの成功例は数多く上げられます。ですので、カナダ移民、ビザの業界において、特に事業移民に関して、トップクラスの経験と実績に自信はあると思います。<br/>

                ビジネスの経験では、日本の大手製造メーカーで、生産管理の厳しいスケジュール、営業、そしてアメリカでとことんマーケティング、支店管理業務も経験したので、MBAで実戦してきたことを勉強してみたかったです。そして、今後カナダでコンサルタント業を起業するためでもありました。MBAを２００３年に取得しました。これまで１５年間の経験をぜひカナダで起業する方にもシェアーしたいと思いますね。
            </p>
        </>
      );
    }
  }