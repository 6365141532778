import React, { Component } from 'react';
import '../../App.css';

import im_mer from './img/merit/im_mer.jpg';
import {Helmet} from "react-helmet";

export default class emigrant_life_merit extends Component {
  render() {
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【移住メリット】</title>
            <meta name="description" content="
              カナダの永住権を取得するメリットは、カナダでのロングステイの滞在期間に制限がなくなることです。
              市民権・選挙権こそありませんが、カナダ人とほぼ同等の資格・権利を手に入れることができ、 社会福祉も受けられます。
              以下、カナダ永住権と共に得られる権利やメリットを紹介します。
            " />
          </Helmet>
            <h2>移住メリット</h2>
            <div className="content">
                カナダの永住権を取得するメリットは、カナダでのロングステイの滞在期間に制限がなくなることです。<br/>
                市民権・選挙権こそありませんが、カナダ人とほぼ同等の資格・権利を手に入れることができ、
                社会福祉も受けられます。<br/>
                以下、カナダ永住権と共に得られる権利やメリットを紹介します。
            </div>
            <div style={{textAlign:"center"}}>
                <img src={im_mer} className="content-pic"/> 
            </div>
            <p>
            ● 日本国籍を<b style={{color:"red"}}>保持したまま</b>、カナダで永住することができる<br/>
            カナダ永住権を取っても、日本国籍を喪失してしまう事はありません。<br/>
            （市民権＝カナダ国籍を得ると、日本の国籍を失います。）<br/>
            <br/>

            ● カナダの永住権を取得しても、<b style={{color:"red"}}>日本の年金受給</b>も可能。<br/>
            <br/>

            ● 政府が提供する<b style={{color:"red"}}>奨学金・教育ローン</b>を受けながらで就学できる。<br/>
            留学を経験した方ならおわかりでしょうが、留学生の学費はカナダ人学生のそれの何倍もかかります。<br/>
            カナダ永住権を取れば学費は条件はカナダ人と全く同じ、さらに国の奨学金や教育ローンを受けながら就学できるます。<br/>
            <br/>

            ● カナダの教育制度の恩恵を受けられる<br/>
            カナダ永住権を取得者はカナダの公立小学校から中高等学校は学費は無料です。<br/>
            永住権を取ると、カナダ市民同様に無料でこの教育制度の恩恵を受ける事ができます。<br/>
            また幼稚園(Day care, Pre-school)にも無料プログラム又は資金補助プログラムもあります。<br/>
            一定以下の収入家庭の場合、18歳までは 政府の<b style={{color:"red"}}>援助金</b>を受けることが出来ます。<br/>
            </p>
        </>
      );
    }
  }