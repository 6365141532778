import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_hoj from './img/for_corporate_customers/im_hoj.jpg';

export default class company_info_for_corporate_customers extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【法人のお客様へ】</title>
            <meta name="description" content="
                企業の皆様は優秀な外国人労働者を長期的に雇用したいが、複雑な申請方法、雇用責任、長い手続期間などの問題に直面し、結果的に雇用に至らないケースは少なくありません。エービックは企業の皆様が100%安心できる、以下のようなサービスを提供しております。
            " />
          </Helmet>
            <h2>法人のお客様へ</h2>
            <div className="content-bg-blue">
                留学エージェント、旅行会社、カナダ支社、各分野の専門家(投資・保険・不動産)
            </div>
            <div className="content">
                企業の皆様は優秀な外国人労働者を長期的に雇用したいが、複雑な申請方法、雇用責任、長い手続期間などの問題に直面し、結果的に雇用に至らないケースは少なくありません。エービックは企業の皆様が100%安心できる、以下のようなサービスを提供しております。
            </div>
            <p>
                <h4>Q1.どのように外国人労働者を合法的に雇えますか?</h4>
                <div  class="float_right">
                    <img src={im_hoj} className="content-pic"/> 
                </div>
                A2. 確かにカナダ労働省/移民局は様々な条件を設けているので、書類を揃えるには一定の時間を必要としますが、エービックに任せれば“確認”と“署名”するだけで、申請は容易です。提出書類も原則会社に既に保管されている資料ですので、新たに作成するものはありません。</p>
            <p>
                <h4>Q2.手続きは面倒でしょうか?</h4>
                A3. 外国人労働者とカナダ人の雇用にさほど変わりはありませんが、労働省の雇用規定や雇用契約書をよく確認してください。疑問・不安がございましたら、随時エービックにお問合せください。分かりやすく説明致します。雇用規定、労働契約書を確認したい方もお気軽にお問い合わせ下さい。</p>
            <p>
                <h4>Q3.外国人労働者を雇用するに当たって、雇用主の責任と注意事項はありますか?</h4>
                A1. 基本的に外国人の就労はカナダ労働省が発行する雇用承認(LMO)、そして移民局が発行するワークビザが必要です。カナダの国情の変動によっては、特別なプログラムや新しい政策が随時発表されます。例えば申請中でも合法的に働けるケース、LMOの承認が要らないワークビザ、優先度が高いとされる職種といった様々なものがあります。企業の皆様がこういった絶えず更新されるシステムを正確に把握することは非常に困難であるといえます。弊社はプロとして企業の現状、そして雇用したい方の背景をよく分析してから、申請方法について的確にアドバイスをさせていただきます。
            </p>
            <p>
                <h4>Q4. 日本の本社からカナダ支社へ社員を派遣する場合、ワークビザを取れますか？</h4>
                A4. 派遣された社員は一定の履歴、職務の条件に満たし、またカナダ支社の運営を書面で証明ができれば、ほとんどの場合、ワークビザの申請はできます。その場合、カナダ国内のHRSDC（厚生労働部門）の審査なしで、直接東京大使館で申込みとなります。</p>
            <p>
                <h4>Q5. エービックへの相談、カウンセリングは有料ですか？</h4>
                A5. 安心してご利用いただく為、企業の皆様には確認事項の説明、カウンセリングを全て無料でサービスをさせていただいております。勿論相談する回数の制限もありません。ご気軽にお問合せください。</p>
            <p>
                <h4>Q6.エービックを利用する場合、何か特典がありますか?</h4>
                A6. 複数の申請を弊社に依頼される場合、コーポレート割引を用意しておりますのでお問合せください。また、バンクーバーは他民族文化の街で、日系の企業でも客層はローカルのカナディアン以外にアジア系の方もかなり多いです。エリアによっては50%以上が中国系の客層をもつところもあります。エービックでは日系企業の製品、サービスをより現地の方々に知ってもらい、確実に売上をアップするため、法人のクライアントに特化したプロモーションを無料にてサービスさせていただいております。詳細はお問合せください。
            </p>


        </>
      );
    }
  }