import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_doc from './img/health_care_system/im_doc.jpg';

export default class emigrant_life_health_care_system extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【医療制度】</title>
            <meta name="description" content="
                カナダの医療技術・制度は世界でもトップクラスに入ります。
                カナダは診察費は全て国費でまかなわれているため、
                医療保険加入者は無料で診察を受けることができます。
                （月々の保険料は別途）各州によりそれぞれの医療保険の加入資格や条件が違います。
            " />
          </Helmet>
            <h2>医療制度</h2>
            <div  class="float_right">
                <img src={im_doc} className="content-pic"/> 
            </div>
            <div className="content">
                カナダの医療技術・制度は世界でもトップクラスに入ります。<br/>
                カナダは診察費は全て国費でまかなわれているため、<br/>
                医療保険加入者は無料で診察を受けることができます。<br/>
                （月々の保険料は別途）各州によりそれぞれの医療保険の加入資格や条件が違います。<br/>
            </div>
            <div className="content-bg-border">
                BC州医療保険（BC Medical Service Plan）
            </div>
            <p>
                カナダ市民、永住者、あるいは６ヶ月以上滞在予定のビザを保持している一時滞在者も加入できます。<br/>
                （ただし待機期間が３ヶ月ある） 申請は、電話(604-683-7151 or 1-800-663-7100)、<br/>
                または<a href="https://www2.gov.bc.ca/assets/gov/health/forms/102fil.pdf">ウェブサイ トで申請用紙</a>を取り寄せ、申請用紙と補足資料をMSPオフィスへ郵送します。<br/>
                <br/>
                申請用紙のダウンロードは<a href="https://www2.gov.bc.ca/assets/gov/health/forms/102fil.pdf">こちら</a><br/>
            </p>
            <div className="content-bg-border">
                診療所の探し方
            </div>
            <p>
                日本では自分で専門医を探し、病院に行って（予約無しでも）診てもらいますが、カナダでは緊急以外はファミリードクター（かかりつけ医）に診てもらう医療システムになります。
                専門医や総合病院へ行くには、ファミリードクターからの紹介状が必要です。
            </p>
            <div className="content-bg-border">
                ファミリードクター
            </div>
            <p>
                ファミリードクターはそれぞれの患者さんの診察ヒストリーを持っていますから、過去の病歴や処方した薬を見ながら診察をしてくれます。また医療のプロフェッショナルとして、その患者に合った専門医を紹介することができます。事前予約が必要なので、救急の場合は救急医療室もしくはウォークイン・クリニックへ行くことになります。<br/>
                <br/>
                カナダではファミリードクターはとても不足しています。それぞれ受け持つ事の出来る患者数が制限されている為、断られてしまうこともあります。時間がかかる事を想定し、カナダに移住したらすぐにファミリードクター探しを始めましょう。<br/>
            </p>
            <div className="content-bg-border">
                ウォークインクリニック
            </div>
            <p>
                ファミリドクターをまだ見つけていない、予約無しで見てもらいたい時に利用できるクリニックです。営業時間が長く、どの街にもあり、日本人医師がいるクリニックもあります。風邪やちょっとした怪我の場合は、大きな病院よりも時間もかからず、すぐに診てもらえます。<br/>
                <br/>
                持病などがある方は、あらかじめ日本のお医者様から、英文の診断書などを書いておいてもらいましょう。ご自身で自分の症状がうまく伝えられないかもしれない、と心配な方は医療通訳を頼むことをお勧めします。バンクーバーには日英の医療通訳サービスを提供しているクリニックがいくつかあります。<br/>
            </p>
            <div className="content-bg-border">
                日本語医療サービス （BC州 Lower Mainland Area）
            </div>
            <p>
                <h4>ファミリドクター</h4>
                ドクター：田中朝絵先生 ＆ 堀井昭先生　 電話：604-687-4858<br/>


                <h4>ファミリドクター</h4>
                ドクター：マン・リアン先生　 電話：604-873-2223<br/>


                <h4>メインランドクリニック（医療サービス＆通訳）</h4>
                電話：604-339-6777<br/>


                <h4>ステインメディカルクリニック（医療サービス＆通訳）</h4>
                電話： 778-836-7238<br/>


                <h4>バラードファーマセイブ（服薬指導、ドクターへの同行・医療通訳）</h4>
                日本人薬剤師 電話：604-669-7700<br/>
                火～金 9:30-18:00<br/>


                <h4>隣組（ドクターへの同行・医療通訳）</h4>
                受付電話：604-687-2172<br/>


                <h4>内分泌専門医</h4>
                電話：604-930-0100<br/>


                <h4>歯科</h4>
                武内歯科医院 電話：604-266-4353<br/>
                菊池歯科医院 電話：604-687-6480<br/>

                <br/>ss
                常備薬などを必要とする人は念のため日本のかかりつけのお医者様に英文の処方箋を書いてもらってきましょう。<br/>
                頭痛薬や風邪薬などは近くのスーパーなどで購入することができますが、こちらの薬が合わない場合もありますので、日本で使用中の薬を余分に持ってくることをお勧めします。<br/>
                <br/>
                救急車を呼ぶ場合、日本では「119」ですが、カナダはひっくり返して「911」とダイヤルします。警察も消防も同じナンバーですから「救急です（Ambulance Please）」と伝えましょう。なお、バンクーバーでは救急車サービスは有料（保険がない場合は約500ドル）です。<br/>
            </p>
            <div className="content-bg-border">
                プライベートの医療保険
            </div>
            <p>
                短期滞在者の場合はカナダの医療保険に加入できないので、保険を持たずに医療サービスを受けた場合は高額な費用を負担することになります。<br/>
                入院の場合、1泊1500～3000ドル、またはそれ以上かかります。救急車や緊急救助が必要になった場合も有料になるので、必ず日本を出発する前に海外旅行（医療）保険に加入しましょう。　<br/>
                またはカナダに来てからでも保険に加入することはできます。<br/>
            </p>
            <br/>
            <p>
                <h4>ブリッジス・インターナショナル保険事務所</h4>
                <a href="https://www.biis.ca/ja/">https://www.biis.ca/ja/</a><br/>
                日本語でサポート、安心と信頼の保険代理店<br/>
                500人近い従業員を抱えるカナダ、ブリティッシュ・コロンビア州最大の独立系保険代理店グループの一員。スタッフは全員日本語を話し、ワーキングホリデー、留学生向けのカナダで最も優良な保険を取り扱っています。<br/>
            </p>
        </>
      );
    }
  }