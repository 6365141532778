import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";
import { FacebookProvider, Page  } from 'react-facebook';
import { FacebookEmbed } from 'react-social-media-embed';

import title_home from './img/title_home.png';
import workingwoman from './img/workingwoman.jpg';
import facebook_banner from './img/facebook-logo.jpg';

export default class english_home extends Component {

  render() {

    return (
      <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC Immigration</title>
            <meta name="description" content="
              All type of immigration and visa issues. Please rest assured to ABIC Immigration Consulting! ABIC Consulting is a firm specializing in Canadian immigration and visa consulting and representation
            " />
          </Helmet>
          <span class="en-part">
            <img src={title_home} className="content-pic full-width" /> 
            <div className="content-bg-blue">
              ABIC Consulting is a Canadian immigration consulting firm. Mei Yu, one of our
              regulated consultants, is an authorized immigration and citizenship representative
              (MEI YU / RCIC R421895), who cooperates with Canadian lawyers and professionals
              in various industries like finance, real estate, and accounting to provide the
              professional services you need.
            </div>
            </span>
            <div class="width40 float_right">
                <img src={workingwoman} className="content-pic"/> 
              </div>
            <span class="en-part">
            <div className="content">
              <p>
                ABIC Consulting is a Canadian immigration consulting firm. Mei Yu, one of our
                regulated consultants, is an authorized immigration and citizenship representative
                (MEI YU / RCIC R421895), who cooperates with Canadian lawyers and professionals
                in various industries like finance, real estate, and accounting to provide the
                professional services you need.
              </p>
              <p>
                Immigration acts and policies are updated frequently, while processing methods and
                submitted documents vary from case to case. Information on the internet may be
                outdated, incorrect or misleading, and the Canadian immigration website can be
                complicated for many. This is where we come in.
              </p>
              <p>
                The experienced professionals from ABIC Consulting will respond to your inquiries
                in a timely and appropriate manner. On top of the aforementioned procedures, we
                can also help with issues like application refusals, entry rejections, and overstaying.
              </p>
            </div>
            </span>

              <div class="position_center">
                <p>
                  <a class="hover" href="https://www.facebook.com/AbicImmigration" target="_blank">
                    <h2>We post news of immigration in Canada at</h2>
                    <img src={facebook_banner} className="content-pic width_200px"/>
                  </a>
                </p>
                <a class="hover" href="https://www.facebook.com/AbicImmigration" target="_blank">
                  Visit to ABIC Immigration Facebook Page
                </a>
              </div>

    </>
    );
  }
}