import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import immigration_services0 from './img/immigration_services0.jpg';

export default class immigration_services extends Component {

  render() {
    return (
      <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>亚历山大移民事务所ABIC Immigration【移民服务】</title>
            <meta name="description" content=""
                
             />
          </Helmet>
          <span class="cn-part">
            <h2>
              移民服务
            </h2>
            <p>
              加拿大是西方八大工业国之一；地域广阔环境优美、社会秩序稳定，教育制度先进，社会福利完善，已连续数被联合国甄选为“全世界最适宜居住的国家” 。更重要的是，加拿大作为一个由移民建立起来的国家，无种族歧视，各民族在多元文化中融洽相处。移民的传统使加拿大成为名副其实的世界性国家。无论是在温哥华、多伦多或是蒙特利尔，都能在街道上见到各种面孔的加拿大人。
            </p>
            <p>
              ABIC移民及商业投资事务所以过去的20多年经验和移民局最新政策掌握，根据客户的需求，提供专业的加拿大签证，移民和商务咨询和代理服务。
            </p>
            <p>
                <b>● 如果您有比较好的学历背景，英文水平很高，有完整工作经历</b>
            </p>
            <p>
              可以寻找愿意协助和支持你移民申请的加拿大雇主，办理技术类移民。大约在一年内可获得移民。如果条件尚不够，可以考虑在加拿大工作一年获得工作经验后再申请。
            </p>
            <p>
                <b>● 如果您正在读大专或大学本科，尚不够移民条件</b>
            </p>
            <p>
              可以考虑留学加拿大，选择专业2年课程或硕士等课程，毕业后获得三年的工作签证，再申请移民。
            </p>
            <p>
                <b>● 如果您在高科技或创新领域有工作或创业经验并有好创业点子</b>
            </p>
            <p>
              可以考虑加拿大创业移民，无需资产和大量投资，商业计划和申请人背景是关键。大约在一年内可获得移民。
            </p>
            <p>
                <b>● 如果您是企业家具备多年管理经验并且有一定的资产</b>
            </p>
            <p>
              可考虑各省的企业家移民项目计划，虽然大约在三年左右可获得移民。但在申请后约6个月后可以工作签证的方式先登陆，定居和创业。
            </p>
            <p>
                <b>● 如果您在文化，体育领域取得过优异成绩或有自雇的经历</b>
            </p>
            <p>
              可以考虑自雇类移民，大约在三年左右可获得移民。
            </p>
            <p>
                <b>● 如果您和加拿大人或居民结婚或具备事实婚姻</b>
            </p>
            <p>  
              在配偶的支持下可办理家庭类移民，大约一年左右可获得移民。
            </p>
            <p>
                <b>● 如果您有保姆或家政的工作经验</b>
            </p>
            <p>
              目前的移民项目是，先寻找到加拿大的雇主，工作二年后可移民。
            </p>
            <p>
                <b>● 如果您可考虑食品加工厂，农场等工作，或在偏远地区工作和学习</b>
            </p>

            <p>
              也有相关的移民试点项目可考虑
            </p>
            <br/>
            <img src={immigration_services0} className="content-pic full-width"/> 
          </span>
    </>
    );
  }
}