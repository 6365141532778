import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_sem from './img/seminar_info/im_sem.jpg';
import im_sem2 from './img/seminar_info/im_sem2.jpg';
import im_sem3 from './img/seminar_info/im_sem3.jpg';

export default class chinese_seminer_info extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>亚历山大移民事务所ABIC Immigration【讲座信息】</title>
            <meta name="description" content="
            " />
          </Helmet>
          <span class="cn-part">
            <h2>讲座信息</h2>
            <div className="content-bg-blue">
                加拿大移民和签证讲座      
            </div>
            <p>
                <div class="float_right">
                    <img src={im_sem} className="content-pic"/> 
                </div>
                <b>内容：</b>
                <p style={{color: "#3333CC"}}>
                我想确保我获得工作签证，还没有找到雇主
                但是还有什么其他方式呢？
                特别是，虽然我没有教育背景或技能，
                从现在开始如何获得移民？
                将在这个签证讲座上解释。<br/>
                </p>
                <p>
                    <b>举办时间</b>：待定<br/>
                    <b>地点</b>: ABIC办公室会议室或ZOOM在线<br/>
                    <b>费用</b>: <span style={{color:"red"}}>免费参加</span><br/>
                    <b>主办单位</b>: ABIC加拿大移民事务所<br/>
                    <b>预订</b>: 604-688-1956 或至 <a href="mailto:abic@abicanada.com">abic@abicanada.com</a><br/>
                    <b>地点</b>: United Kingdom Building Suite 101 or 111<br/>
                                409 Granville Street, Vancouver, BC CANADA V6C 1T2<br/>
                                天车终点站Waterfront Station附近<br/>
                    <br/>
                </p>
            </p>

            <div className="content-bg-blue">
                ABIC讲座场景
            </div>
            <p>
                在ABIC移民事务所，我们为那些认真考虑在加拿大工作和移民，寻求更丰富生活的人。<br/>
                提供全面的支持，包括移民和签证程序。我们努力使您的就业，创业和永久居留成功。<br/>
                请随时与我们联系！<br/>
            </p>
            <div class="seminer_pics">
                    <img src={im_sem2} className="content-pic"/> 
                    <img src={im_sem3} className="content-pic"/> 
            </div>
          </span>
        </>
      );
    }
  }