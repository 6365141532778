import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import contact_us_pic from './img/contact_us.jpg';

export default class contact_us extends Component {

  render() {
    return (
      <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>亚历山大移民事务所ABIC Immigration【联系我们】</title>
            <meta name="description" content="
                
            " />
          </Helmet>
          <span class="cn-part">
          <h2>联系我们</h2>
          <div className="content-bg-blue">
                ABIC办公时间和地点
          </div>
          <p>
            Monday to Friday: 9:00am – 5:30pm<br/>
            ABIC Immigration Consulting<br/>
            409 Granville Street, Suite 1617<br/>
            Vancouver, B.C., Canada V6C 1T2<br/>
            Phone: 1-604-688-1956 <br/>
            E-mail: <a href="mailto:abic@ABICanada.com">abic@ABICanada.com</a><br/>
          </p>
          <div className="content-bg-blue">
                前往ABIC
          </div>
          <p>
            電車:	Waterfront站 徒步0 分<br/>
            公交车:	#135,#160,#190(W. Hastings) 徒步0分<br/>
            #4,#6,#7,#17,#50(Granville)徒步2分<br/>
            #5,#19,#22,N22(Pedner) 徒步3分<br/>
            #3,#4,#6,#7,#8,#17,#50,#98(Cordova) 徒步5分<br/>
            停车:	443 Seymour St. (交叉Hastings St 和Pender St.)
          </p>
          <div className="googleMap">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5204.976126544984!2d-123.113636!3d49.286097!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486717881504227%3A0x7f3d7b8a00251b70!2s409%20Granville%20St%2C%20Vancouver%2C%20BC%20V6C%201T2%2C%20Canada!5e0!3m2!1sen!2sus!4v1606472191146!5m2!1sen!2sus" width="600" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe><br/>
          </div>
          <br/>
          <img src={contact_us_pic} className="content-pic full-width"/>        
          </span>
    </>
    );
  }
}