import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

export default class emigrant_life_assets_and_tax extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【資産、年金と税金申告】</title>
            <meta name="description" content="
                金融商品を主に扱う機関で、主なものは銀行・証券会社・生命保険会社・投資信託会社です。

                日本と同様、銀行や銀行系列会社には高金利口座、定期預金、外貨預金、国債、投資信託、株式、為替取引など様々なものがあります。
            " />
          </Helmet>
            <h2>資産、年金と税金申告</h2>
            <div className="content-bg-border">
                資産運用
            </div>
            <p>
            金融商品を主に扱う機関で、主なものは銀行・証券会社・生命保険会社・投資信託会社です。<br/>
            <br/>
            日本と同様、銀行や銀行系列会社には高金利口座、定期預金、外貨預金、国債、投資信託、株式、為替取引など様々なものがあります。<br/>
            <br/>
            また、生命保険での投資には変動投資型のユニバーサル終身保険、保険会社資産運用型のパーティシペイティング終身保険、などがあります。<br/>
            <br/>
            不動産・ビジネスへの投資も資産運用の手段として一般的です|<br/>
            バンクーバーには日本語で対応できるこれらの専門家が多くいらっしゃいます。<br/>
            <br/>
            <div className="content-bg-border">カナダ大手の資産運用の会社(一部)</div>
            <b>TD Private Wealth Management</b><br/>
            <a href="https://www.td.com/ca/en/investing/wealth/private-wealth-management/">https://www.td.com/ca/en/investing/wealth/private-wealth-management/</a><br/>
            <br/>
            <b>RBC Wealth Management Dominion Securities</b><br/>
            <a href="https://www.rbcwealthmanagement.com/ca/en/">https://www.rbcwealthmanagement.com/ca/en/</a><br/>
            <br/>
            <b>BMO Wealth Management</b><br/>
            <a href="https://gateway.bmonesbittburns.com/gwclient/">https://gateway.bmonesbittburns.com/gwclient/</a><br/>
            <br/>
            <b>National bank Financial wealth Management</b><br/>
            <a href="https://nbfwm.ca/">https://nbfwm.ca/</a><br/>
            <br/>
            <b>Freedom 55 Financial</b><br/>
            <a href="https://freedom55.exblog.jp/">http://freedom55.exblog.jp/(日本語)</a><br/>
            4名の日本人アドバイザーが日本語・英語でファイナンシャルプラニングの基本から住宅購入やリタイアなどの「目標」に向けたプラニング、カナダの生命保険の正しい選び方、上手に節税しながら貯蓄する方法、退職後の引退資金の引出し方等の相談を受けている。<br/>
            </p>
            <div className="content-bg-border">
                カナダの年金
            </div>
            <p>
                カナダにも日本と同じように２階建ての公的年金があります。<br/>
                <br/>
                <h4>Old Age Security (OAS)／老齢保障制度</h4>
                カナダの公的年金の１階部分となるのがOASです。これは65才以上のカナダ市民または移民、そして一定期間以上カナダに居住していた人が受給資格を持ちます。（ただし、高額所得者は返金を請求される可能性有り）<br/>
                <br/>
                OASは、毎月保険料を納める日本の国民年金と違い、支払い保険料はありません。<br/>
                つまりOASは連邦政府の一般財源から支給されています。<br/>
                <br/>
                ただし、このOASは日本の国民年金の給付額と比べると少し低い水準になります。<br/>
                2009年3月の一人当たり平均OAS受給額は489.54ドル/月（満額で516.96ドル/月）です。<br/>
                <br/>
                ※日本の老齢基礎年金は2009年は月額66, 008円。（$1＝83円の為替レートで計算すると795.27ドル/月）<br/>
                <br/>
                <h4>Guaranteed Income Supplement (GIS)</h4>
                GISとは一定以下の所得のシニアへの補助金です。<br/>
                2009年3月の一人当たり平均受給額は452.61ドル/月(満額で652.51ドル/月)、OASとほぼ同じ水準です。<br/>
                OASとGISを合算すると平均受給額は942.15ドル/月になります。<br/>
                <br/>
                <h4>Canada Pension Plan (CPP)／カナダ年金制度</h4>
                カナダの公的年金制度の２階部分が<span style={{color: "#0066CC"}}>Canada Pension Plan(CPP)</span>です。<br/>
                これは日本の厚生年金制度に当たります。<br/>
                CPPは18歳以上70歳未満のほぼ全ての労働者が加入を義務付けられます。<br/>
                カナダ国外に住んでいても受給できます。<br/>
                <br/>
                支払い保険料は当人の収入に比例し、総所得の9.9％を企業と労働者が半分に分割して納めます。（2009年7月現在）<br/>
                例）月収が$3000の場合、労働者と企業がそれぞれ$148.5/月 納める。<br/>
                <br/>
                2008年3月より、日本とカナダ間の<span style={{color: "#0066CC"}}>国際社会保障協定</span>が発行されました。<br/>
                日本の年金加入期間をカナダの居住期間および年金加入期間に通算する仕組みです。<br/>
                詳細は社会保険庁のホームページまたは社会保険事務所にてご確認ください。<br/>
            </p>

            <div className="content-bg-border">
                税金申告
            </div>
            <p>
                税金申告は日本で行われている確定申告のことで、英語ではIncome Tax Returnといいます。カナダに居住している人であれば、１月１日から１２月３１日まで収入を得たのでれば、翌年の４月３０日までこの確定申告を行わなければいけません。<br/>
                申告対象外の方：１年のうち、カナダでの滞在は半年未満、そしてカナダで収入を得ていないこと。<br/>
                申請用紙：郵便局、税務局(800-959-8281)へ電話にて取寄せ、税務局のサイト、会計士事務所（電子ファイルにて申告）<br/>
                カナダ税務局(CRA)のサイト：<a href="http://www.cra-arc.gc.ca/menu-eng.html">Canada Revenue Agency</a> 各ガイド、フォーム、問合せ先などの情報。<br/>
                確定申告後、払うべきの税金が毎月給与から差し引いた税金より多い場合、その差額を税務署に支払わなければいけません。その逆、少ない場合、税務署に返金されます。
            </p>
        </>
      );
    }
  }