import React, { Component } from 'react';
import { FacebookProvider, Page, EmbeddedPost} from 'react-facebook';

import '../../App.css';

import title_home from './img/title_home.gif';
import workingwoman from './img/workingwoman.jpg';

export default class Home extends Component {
  render() {
      return (
        <>
          <img src={title_home} className="content-pic full-width" /> 
          <div className="content-bg-blue">
              ABIC（エービック）はカナダ政府公認のカナダ移民代理士（MEI YU/ICCRC R421895）・日本人ビザアドバイザーたちが、皆様の様々な状況に応じて親切・丁寧にサポートしております。
          </div>
          <div class="width40 float_right">
              <img src={workingwoman} className="content-pic"/> 
            </div>
          <div className="content">
            <p>ABICカナダ移民事務所ではカナダ政府公認のカナダ移民代理士（ICCRCメンバー）と日本人ビザアドバイザーが、皆様のカナダ移民（永住・移住）やビザに関するあらゆる手続きをサポートしております。</p>
            <p>カナダ移民法は頻繁に更新され、プロセス方法や提出書類もケースによって異なる上、インターネット上に溢れている情報は古いものか新しいものか、正しいものかもわかりません。カナダ移民局のサイトは英語・フランス語のみで、自分の解釈で間違っていないかどうか心配…という方も多いでしょう。</p>
            <p>ABICの経験豊富なプロフェッショナルなら、貴方のご相談に適格に回答できます。 カナダ移住（永住・移民）や各種ビザ取得に関する相談はもちろん、申請却下や入国拒否、滞在期限を切れての滞在…といった様々なトラブルについても、お話をうかがい解決方法を提案します。</p>
            <p>これからカナダ移住申請をされる貴方にとって、本当に信頼のおける代理人との出会いが最も大切です。ABICでは私達のサービスを知っていただくために、初回無料カウンセリング、Emailでの無料査定フォーム、毎月の無料セミナーなど、多くの無料サービスを案内しています。どこの誰に自分の大切な手続きを任せるのか、わかった上でABICを選んでいただきたいからです。</p>
            <p><a href="http://www.e-maple.net/">カナダ総合情報サイトe-Maple.net</a></p>
          </div>
          <hr/>
          <div className="content-bg-blue">カナダ移民・移住・永住イベント情報・ニュース</div>
          <div className="facebook_page">
            <FacebookProvider appId="148521673491558">
              <Page href="https://www.facebook.com/ABIC-Immigration-Consulting-111883600982808" tabs="timeline" width="1000" />              
            </FacebookProvider>  

              <div id="fb-root"></div>
              <div class="fb-page" data-href="https://www.facebook.com/AbicImmigration" data-tabs="timeline" data-width="1000" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"></div>
              <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=148521673491558&autoLogAppEvents=1" nonce="fWDg5uHs"></script>
          </div>
          <br/>
          <div className="content-bg-blue">カナダのビザについて</div>

          <a href="/immigration_and_visa_canada/visitor_visa">ビジタービザ</a>
          <p>ビジタービザで、年にカナダには何回まで出入りできる？</p>
          <p>ロングステイしたいが、カナダには最長半年まで可能？</p>
          <div className="dotline_ora"></div>

          <a href="/immigration_and_visa_canada/study_permit">学生ビザ</a>
          <p>ビジターから学生ビザに切り替えはカナダ国内でできるの？</p>
          <p>留学生が申請できるワークビザとは？</p>
          <div className="dotline_ora"></div>

          <a href="/immigration_and_visa_canada/working_holiday">ワーキングホリデービザ</a>
          <p>ワーホリビザが切れても、カナダ国内でビザ延長は可能？</p>
          <p>カナダ移民又は移住・永住を予定の方、ワーホリ期間中の注意点？</p>
          <div className="dotline_ora"></div>

          <a href="/immigration_and_visa_canada/work_permit">ワークビザ</a>
          <p>ワークビザの審査ってどんな内容なの？</p>
          <p>ワークビザの申請が却下されても、再申請できる？</p>
          <div className="dotline_ora"></div>

          <a href="/immigration_and_visa_canada/family_class">家族移民</a>
          <p>結婚の前の同居中でもカナダ移民・永住権の申請は可能？</p>
          <p>スポンサーは学生で無収入・・・それでも移民申請できる？</p>
          <div className="dotline_ora"></div>

          <a href="/immigration_and_visa_canada/skilled_worker">スキルドワーカー移民</a>
          <p>プロセスが変わったそうで、どこに提出すれば良い？</p>
          <p>合格ポイント以外に、どんな条件を満たせばいい？</p>
          <div className="dotline_ora"></div>

          <a href="/starting_business_and_investing/info">投資・企業家移民</a>
          <p>どうやって確実にカナダに移民・移住・永住できるか？</p>
          <p>移住後の永住者の生活サポートはありますか？</p>
          <div className="dotline_ora"></div>

          <p>
            上記のような様々なカナダビザ、移民・移住・永住に疑問や不安を抱えていらっしゃる方、 ビザの情報は欲しい…
            でもインターネットの情報では中途半端で不明点が多い、
          </p>
          <p>
            友達に聞いても不安、カナダ移民局のサイトを見ても今ひとつわからない… あなたは、どうされていますか？<br/>
            そんな皆様のご相談を、ABICはいつでも承ります。移民・移住・永住はもちろん、カナダでのビジタービザ、学生ビザ、就労ビザ、ロングステイ、移住・永住など、ABICの経験豊富なプロフェッショナルにご相談下さい。
            ABICでは皆様のご希望されるビザ取得の可能性カナダ移民・移住・永住について、無料査定サービスを行なっております。
          </p>
        </>
      );
    }
  }