import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import k_01 from './img/k_01.jpg';
import k_02 from './img/k_02.jpg';
import k_03 from './img/k_03.jpg';

export default class starting_business_and_investing extends Component {
  render() {
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【起業＆投資】</title>
            <meta name="description" content="
            エービックの経営コンサルティングサービスはカナダで起業したい方のため、 特化したトータルサポートを行っています。単なる戦略をプランニングするだけでなく、実際に効果を明確にもたらすように起業家ととも一緒に仕事します。
            各企業のニーズとカナダ現地のマーケットの特徴に合わせ、企画、戦略、財務・経営管理、CRM、人材、SCMなどに面において、各プロフェッショナルが仕事を担当させていただきます。
            " />
          </Helmet>
            <h2>起業＆投資</h2>
            <div class="float_right">
            </div>
            <div className="content-bg-blue">
                カナダ起業へ目指す
            </div>
            <div className="content">
                <p>
                  エービックの経営コンサルティングサービスはカナダで起業したい方のため、
                  特化したトータルサポートを行っています。単なる戦略をプランニングするだけでなく、実際に効果を明確にもたらすように起業家ととも一緒に仕事します。<br/>
                  各企業のニーズとカナダ現地のマーケットの特徴に合わせ、企画、戦略、財務・経営管理、CRM、人材、SCMなどに面において、各プロフェッショナルが仕事を担当させていただきます。
                </p>
            </div>
            <div className="content-bg-border">
                <p>
                  <h4>エービックのワンストップソリューション（経営コンサルティング）</h4>
                </p>
            </div>
            <h4>対象業務</h4>
            <div style={{textAlign:"center"}}>
              <div class="inline-block-2">
                <p>
                <div class="float_right">
                    <img src={k_01} className="content-pic"/> 
                  </div>
                  <b style={{color:"red"}}>事業企画</b><br/>
                  ● 下見調査のアレンジ<br/>
                  ● 企画の実行可能性分析<br/>
                  ● 戦略の立案<br/>
                  ● 企画書の作成<br/>
                  <hr/>

                </p>
              </div>
              <div class="inline-block-2">
                <p>
                <div class="float_right">
                    <img src={k_02} className="content-pic"/> 
                  </div>
                  <b style={{color:"red"}}>立ち上げ</b><br/>
                  ● 会社登記、各種タックス登録<br/>
                  ● 商業地リース契約、商業保険<br/>
                  ● ビジネスラインセンス<br/>
                  ● 法人の銀行口座<br/>
                  ● 人材募集・組織管理<br/>
                  <hr/>
                </p>
              </div>
              <div class="inline-block-2">
                <p>
                  <div class="float_right">
                    <img src={k_03} className="content-pic"/> 
                  </div>
                  <b style={{color:"red"}}>運営&発展</b><br/>
                  ● CRM<br/>
                （マーケティング、営業、顧客サービス等）<br/>
                  ● 財務・経営管理<br/>
                  ● 税務相談<br/>
                  ● SCM<br/>
                （グローバル化、M&A、アウトソーシング等）<br/>
                  ● 会社税金申告<br/>
              </p>
            </div>
          </div>
          <br/>
          <h4>サービス対象者</h4>
          <p>
            <b>
              開業する資金を所持、日本でもビジネス経営の経験はあるが、カナダではどうはじめるか分からない方！<br/>
              よいアイデア、特殊な専門技術を持っているが、資本金も、人脈もなく、会社運営の経験もない方！<br/>
            </b>
            <br/>
            これまであなたの履歴とこれからの事業企画を下さい。日本語で構いません。ラフなものでも結構です。エービックは検討した上、具体的なアドバイスを無料にて提案します！<br/>
            留学している方、ワーキングホリデーの方、日本の会社を辞めて脱サラーの方、女性起業家の方は大歓迎！
          </p>
          <br/>
          <p>
            <b>
              日本の事業を拡大し、カナダで支店を作りたい方！<br/>
            </b>
            <br/>
            最初からマーケット調査、現地の人事、資本金、ビザなどの問題に戸惑うが、全てエービックにお任せ下さい。
            <br/>
          </p>
          <br/>
          <p>
            <b>
              日々の運営に参加しないが、既存のビジネス、不動産に投資したい方！<br/>
            </b>
            <br/>
            エービックのネットワークで堅実のビジネスを紹介します。その一部は下記のビジネス物件をご覧下さい。金融又は不動産投資に興味がある方はその分野の日本人専門家を紹介します！<br/>
          </p>
          <br/>
          <p>
            <b>
              フランチャイズを創業したい方！<br/>
            </b>
            <br/>
            多分野に渡るカナダフランチャイズを紹介します。また、日本のFCのアイデアを生かし、カナダで展開する可能性もあります。<br/>
          </p>
          <br/>
          <p>
            <b>
              日本人だけでなく、ローカルのカナダ人、中国、韓国のマーケットを開拓したい方！<br/>
            </b>
            <br/>
            バンクーバーではカナディアン以外、中国・韓国の移住の方も多い。エービックでは中国、韓国のスタッフも在籍していますので、ニーズにマッチした様々なマーケティング戦略をアドバイスできます。<br/>
          </p>
          <br/>
          <p>
            <b>
              カナダのビジネスを個人的の理由で売りたい事業主の方！<br/>
            </b>
            <br/>
              カナダ起業、移住・永住という目的で弊社を尋ねる方もいるので、潜在性があるビジネスを売りたい方に紹介できますので、ぜひご連絡してください。<br/>
          </p>

          <div className="content-bg-border">
            <h4>ビジネス投資物件の一部</h4>
          </div>
          <p>
          ● 会社業種：建築材の貿易会社<br/>
          希望融資額：47万5千ドル / 製品：壁、柵、窓、ドアー、工具などの建築材 / 現在のマーケット：北米<br/>
          <br/>
          ● 会社業種：水産製造メーカー<br/>
          希望融資額：50万ドル / 製品：魚貝類の製造、加工 / 現在のマーケット：北米、日本<br/>
          <br/>

          ● 会社業種：警備会社<br/>
          希望融資額：52万5千ドル / 製品：セキュリティーサービス（アラームシステム） / 現在のマーケット：北米<br/>
          <br/>

          ● 会社業種：木材開発<br/>
          希望融資額：98万ドル / 製品：木材開発、原木加工 / 現在のマーケット：北米<br/>
          <br/>

          ● 会社業種：レストラン<br/>
          希望融資額：60万ドル / 製品：海辺のシーフードレストラン / 現在のマーケット：現地、世界中からの観光客<br/>
          </p>

          <div className="content-bg-border">
            <h4>起業の実績（エービックによるサポートの一部）</h4>
          </div>
          <p>
          ● 会社業種：スーパーマーケット<br/>
          サービス内容：市場調査、場所選定、事業企画、会社の設立 / 場所：Abbotsford<br/>
          <br/>
          ● 会社業種：教育（学校経営）<br/>
          サービス内容：事業企画、株の取得、商業契約 / 場所：Toronto/Vancouver<br/>
          <br/>
          ● 会社業種：建築材メーカー<br/>
          サービス内容：事業企画、人材募集、面接、採用、会社の設立、特許申請 / 場所：Richmond(バンクーバーエリアー)<br/>
          <br/>
          ● 会社業種：IT<br/>
          サービス内容：会社の設立、事業企画 / 場所：Vancouver<br/>
          <br/>
          ● 会社業種：不動産開発<br/>
          サービス内容：事業企画、土地調査、契約の交渉 / 場所：Saskatoon (サスカチュウワン州)<br/>
          </p>
        </>
      );
    }
  }