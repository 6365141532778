import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_wh from './img/working_holiday/im_wh.jpg';

export default class immigration_and_visa_canada_working_holiday extends Component {
  render() {
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【ワーキングホリデー】</title>
            <meta name="description" content="
            カナダと日本政府の協定に基づき、
            両国の若者がお互いの理解をより深めるプログラムです。
            申請時18～30歳未満の人を対象しています。
            本プログラムの目的はあくまでも「カナダを理解するため、カナダで休暇を過ごす」としていますので、就労を目的に参加することはできません。
            " />
          </Helmet>
            <h2>ワーキングホリデー</h2>
            <div class="float_right">
              <img src={im_wh} className="content-pic"/> 
            </div>
            <div className="content">
                <p>
                カナダと日本政府の協定に基づき、<br/>
                両国の若者がお互いの理解をより深めるプログラムです。<br/>
                申請時18～30歳未満の人を対象しています。<br/>
                本プログラムの目的はあくまでも「カナダを理解するため、カナダで休暇を過ごす」としていますので、就労を目的に参加することはできません。<br/>
                </p>
            </div>
            <p>
                参加資格：<br/>
                ◆ 今現在、日本に住んでいる日本人<br/>
                ◆ 一定期間（最長１年）カナダで休暇を過ごすことを本来の目的とする人<br/>
                ◆ 以前にこのプログラムに参加していない人<br/>
                ◆ 申請書受理時点で18才以上30才以下の人（出発日の時点での年齢ではありません。）<br/>
                ◆ 有効なパスポートを持ち、かつ往復切符を所持、または購入できる資金を有する人<br/>
                ◆ 滞在を希望する期間、医療費を含めて生活に必要な資金を有する人（入国の際、約50万円）<br/>
                ◆ 常識があり、健康で性格善良な人<br/>
                ◆ カナダで仕事が内定していない人<br/>
                <br/>
                詳細に関しましてはカナダ移民局のサイトをご覧下さい。<br/>
                <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/iec.html">Work and travel in Canada with International Experience Canada</a><br/>
                <br/>
                <b>注意点：</b><br/>
                渡航中、6ヶ月以上のコースを勉強する場合、就学許可(Study Permit)を取得する必要があります。
            </p>
            <br/>
            <h2>オープンワークビザ<span> (Open Work permit)</span></h2>
            <div className="content-bg-blue">
                Foreign Worker、Foreign Studentの配偶者、<br/>
                及びパートナーOpen Work Permit（オープンワークビザ）
            </div>
            <div className="content">
                <p>
                    カナダで一時就労する高等技能者、<br/>
                    もしくはPost Secondary Schoolで就学するフルタイムの学生の配偶者、<br/>
                    及びパートナー（内縁の配偶者）は雇用者が特定されない<br/>
                    Open Work Permit（オープンワークビザ）を申請する事が可能です。<br/>
                </p>
            </div>
            <p>
                ＊） <b>高等技能者（Skilled Worker）</b>とは、全国職業別分類体系（The National Occupational Classification System）にリストされている管理職、高等専門職、高等技能職等の就労者を指します。<br/>
                <br/>
                この申請には以下の条件に適合する事が必要です。
            </p>
            <div className="content-bg-border">
                配偶者がカナダで一時就労する場合
            </div>
            <p>
            ・ 配偶者、もしくはパートナーが6ヶ月以上の就労を許可されていること。<br/>
            ・ その配偶者、もしくはパートナーの職務内容がカナダ全国職業別分類体系<br/>
            　（The National Occupational Classification System）のSkill Type 0,またはSkill Level A、Bにリストされていること。
            </p>
            <div className="content-bg-border">
                配偶者がカナダで一時就学する場合
            </div>
            <p>
            ・ 配偶者、もしくはパートナーが学士を与えるPost Secondary School<br/>
            　（公立、一部指定の私立の大学、カレッジ、専門学校) のフルタイムの学生として学生ビザを保持していること。）<br/>
            ・ 配偶者、もしくはパートナーがPost-Graduation Work Permitを取得し就労していること。
            </p>
        </>
      );
    }
  }