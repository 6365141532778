import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import '../../App.css';
import {Helmet} from "react-helmet";

import im_stu from './img/study_permit/im_stu.jpg';


export default class immigration_and_visa_canada_study_permit extends Component {
  render() {
      return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>ABIC エービック カナダ移民＆ビザ【学生ビザ】</title>
            <meta name="description" content="
           カナダ学生ビザを東京で申請する場合

           申請方法：
           オンライン若しくは東京にあるカナダビザ申請センターへ郵送
           
           カナダビザ申請センター：
           〒105-0014
           東京都港区芝1-4-3
           芝金杉橋ビル4F
           SANKI
           合同会社VFSサービシズ・ジャパン
           
           申請に必要な書類：
           ◆書類チェックリスト
           ◆申請書(IMM1294)...
            " />
          </Helmet>
            <h2>学生ビザ<span> (就学許可証)</span></h2>
            <div className="content-bg-blue">
                カナダ学生ビザを東京で申請する場合
            </div>
            <p>
                <div class="float_right">
                    <img src={im_stu} className="content-pic"/> 
                </div>
                <br/>
                <b style={{color: "Red"}}>申請方法：</b><br/>
                オンライン若しくは東京にあるカナダビザ申請センターへ郵送<br/>
                <br/>
                カナダビザ申請センター：<br/>
                〒105-0014<br/>
                東京都港区芝1-4-3<br/>
                芝金杉橋ビル4F<br/>
                SANKI<br/>
                合同会社VFSサービシズ・ジャパン<br/>
                <br/>
                <b style={{color: "Red"}}>申請に必要な書類：</b><br/>
                ◆書類チェックリスト<br/>
                ◆申請書(IMM1294)<br/>
                ◆申請料金(150カナダドル )<br/>
                ◆証明写真<br/>
                ◆入学許可書のコピー<br/>
                ◆残高証明と学費の領収書<br/>
                ◆パスポート<br/>
                <br/>
                さらに詳しい情報、申請書は下記のサイトからダウンロードできます。<br/>
                <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit.html">https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit.html</a>
                <br/>
                <br/>
                <b style={{color: "Red"}}>注意点：</b><br/>
                ◆残高証明は基本的に生活費・雑費プラス学費の総額になります。1年又はそれ以上留学を予定する場合、$10,000 + 学費となります。<br/>
                ◆留学中、一定の条件の下で就労することも可能です。詳細は「ワークビザにある留学生が取得できるワークビザ」をご覧下さい。<br/>
                <br/>
            </p>

        </>
      );
    }
  }