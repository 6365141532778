import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";

import passport_program_airport from './img/passport_program_airport.jpg';
import passport_program_Grenada from './img/passport_program_Grenada.jpg';
import passport_program_Passport_Grenada from './img/passport_program_Passport_Grenada.jpg';


export default class passport_program extends Component {

  render() {
    return (
      <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>亚历山大移民事务所ABIC Immigration【护照项目】</title>
            <meta name="description" content=""
                
             />
          </Helmet>
          <span class="cn-part">
            <h2>
              护照项目
            </h2>
            <p>
                <b>海外信托</b> 成立海外家族信托,优化资产配置和传承
            </p>
            <p>
                <b>税务规划</b> 无需为其海外收入征税，可以避免全球征税和解决CRS面对的不确定
            </p>
            <p>
                <b>自由通行</b> 免签130-170多个国家，实现全球自由旅行
            </p>
            <p>
                <b>子女教育</b> 国际学校就读需求或接受当地教育
            </p>
            <p>
                <b>手续简单</b> 无需解释资产积累和资金来源,无面试和学历,管理经验要求
            </p>
            <div className="content-bg-blue">
              Grenada Citizenship 格林纳达
            </div>
            <div class="float_right">
                <img src={passport_program_Passport_Grenada} className="content-pic width_200px"/> 
            </div>
            <p>
                如果您有资产配置及税务减免的考虑，或者到美国创业的想法，那么格林纳达移民项目或许是最好的选择。
            </p>
            <p>
                格林纳达位于东加勒比海，面积344平方公里。其移民政策于2013年启动，一人投资可为一家三代申请永居及入籍并申领护照。
            </p>
            <div className="content">
              项目优势：
            </div>
            <p>
              ● 审理时间短。快速获得外籍身份，子女获得就读中国顶尖国际学校的资格。<br/>
              ● 申请条件简单。无学历要求，无语言要求，无资产来源要求，无需登陆，无面试没有移民监。<br/>
              ● 进入美国更便捷。格林纳达公民可持美国E-2签证，在美工作和生活，方便子女就读藤校。<br/>
              ● 资产全球配置。无个人所得税，资产利得税，净资产税，遗产税及赠予税，无全球征税<br/>
              ● 全球同行。免签137个国家，英国免签6个月，申根国免签90天，免签中国，香港澳门90天<br/>
            </p>
            <div className="content">
            申请条件：
            </div>
            <p>
              ● 主申请人须年满18岁，身体健康，无犯罪记录，投资15万美元到政府指定基金。
              ● 可随行家庭成员包括配偶、子女、双方父母、祖父母、及18岁以上未婚未育的兄弟姐妹
            </p>
            <div className="content-bg-blue">
              Turkey Citizenship 土耳其国籍
            </div>
            <div class="float_right">
                <img src={passport_program_Grenada} className="content-pic width_200px"/> 
            </div>
            <div className="content">
              项目优势：
            </div>
            <p>
              ● 审理时间短。快速获得外籍身份，子女获得就读中国顶尖国际学校的资格。<br/>
              ● 申请条件简单。无学历要求，无语言要求，无资产来源要求，没有移民监。<br/>
              ● 房产3年以后可以出售。<br/>
              ● 可申请英国永居。土耳其公民可申请企业家签证在英国设立公司，进入英国生活5年可转英国永居。<br/>
              ● 进入美国更便捷,可申请美国E-2签证<br/>
              ● 免签117个国家和地区,包括新加坡，日本，中国香港等
            </p>
            <div className="content">
            申请条件：
            </div>
            <p>
              ● 主申请人须年满18岁，身体健康，无犯罪记录，投资25万美元购买房产或50万美元存3年。<br/>
              ● 可随性家庭成员包括配偶，和未满18周岁子女。
            </p>
            <div className="content-bg-blue">
              其他国家的国籍/护照项目
            </div>
            <div class="float_right">
                <img src={passport_program_airport} className="content-pic width_200px"/> 
            </div>
            <p>
              爱尔兰，葡萄牙，塞浦路斯，马耳他，希腊，安第瓜，多米尼加，圣基思，圣卢西亚, 瓦努阿图, 摩尔多瓦等。
            </p>
          </span>
    </>
    );
  }
}